/* eslint-disable typescript-sort-keys/interface */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  IsoNaiveDatetime: { input: string; output: string };
  Map: { input: unknown; output: unknown };
  NaiveDateTime: { input: string; output: string };
  Upload: { input: File; output: File };
};

export type Attachment = {
  __typename?: 'Attachment';
  description?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderId: Scalars['Int']['output'];
  subheading?: Maybe<Scalars['String']['output']>;
};

export type Attendee = {
  __typename?: 'Attendee';
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  questions?: Maybe<Array<Maybe<WebinarQuestion>>>;
  viewedRecordingAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type Block = {
  __typename?: 'Block';
  content?: Maybe<Scalars['Map']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  websitePage?: Maybe<Page>;
  websitePageId?: Maybe<Scalars['ID']['output']>;
};

export type BrandColor = {
  __typename?: 'BrandColor';
  hsl: Scalars['String']['output'];
  shades: ShadeMap;
  tints: TintMap;
};

export type Certificate = {
  __typename?: 'Certificate';
  certificateUrl: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  statDecAcceptedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  status: CertificateStatus;
  type?: Maybe<CertificateType>;
};

export enum CertificateStatus {
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export enum CertificateType {
  Afsl = 'AFSL',
  S708 = 'S708',
  Uk = 'UK',
}

export enum CommentSource {
  Email = 'EMAIL',
  Forum = 'FORUM',
  Linkedin = 'LINKEDIN',
  Other = 'OTHER',
  Twitter = 'TWITTER',
}

export enum CommsCustomEmailType {
  Marketing = 'MARKETING',
  Transactional = 'TRANSACTIONAL',
}

export type Company = {
  __typename?: 'Company';
  aiSummariesEnabled: Scalars['Boolean']['output'];
  colorPalette?: Maybe<CompanyColorPalette>;
  currentCustomCorporatePageBySlug?: Maybe<CorporatePage>;
  customDomain?: Maybe<CustomDomain>;
  defaultPublicInteractionsEnabled: Scalars['Boolean']['output'];
  featuresEnabled: Array<Maybe<Scalars['String']['output']>>;
  hasCompanyShareholderOfferPermission: Scalars['Boolean']['output'];
  hasLiveGovernancePage: Scalars['Boolean']['output'];
  hub: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  investorCentreEnabled: Scalars['Boolean']['output'];
  investorHub: InvestorHub;
  isDemo: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  isUnlisted: Scalars['Boolean']['output'];
  liveInvestmentOfferId?: Maybe<Scalars['ID']['output']>;
  liveWebinar?: Maybe<LiveWebinar>;
  logoUrl: Scalars['String']['output'];
  marketingEmail?: Maybe<CustomEmail>;
  name: Scalars['String']['output'];
  publishedWebsite?: Maybe<Website>;
  recentScheduledWebinar?: Maybe<RecentScheduledWebinar>;
  secondaryTickers: Array<SecondaryTicker>;
  sppBannerButtonText?: Maybe<Scalars['String']['output']>;
  sppBannerDescription?: Maybe<Scalars['String']['output']>;
  sppBannerMobileButtonText?: Maybe<Scalars['String']['output']>;
  sppNavText?: Maybe<Scalars['String']['output']>;
  sppUrl?: Maybe<Scalars['String']['output']>;
  ticker: Ticker;
  tradingName: Scalars['String']['output'];
  unpublishedWebsite?: Maybe<Website>;
};

export type CompanyCurrentCustomCorporatePageBySlugArgs = {
  currentPageSlug?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyColorPalette = {
  __typename?: 'CompanyColorPalette';
  primary: BrandColor;
};

export type CompanyOverview = {
  __typename?: 'CompanyOverview';
  announcements: Array<MediaAnnouncement>;
  /** Could be null if no data for this date */
  close?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Could be null if no data for this date */
  volume?: Maybe<Scalars['Float']['output']>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  commsUnsubscribes: Array<ContactUnsubscribe>;
  globalUnsubscribe?: Maybe<ContactGlobalUnsubscribe>;
  hnwStatus?: Maybe<ContactHnwStatuses>;
  id: Scalars['ID']['output'];
  investor?: Maybe<InvestorUserSimple>;
};

export type ContactGlobalUnsubscribe = {
  __typename?: 'ContactGlobalUnsubscribe';
  id: Scalars['ID']['output'];
};

export enum ContactHnwStatuses {
  IdentifiedViaBehaviour = 'IDENTIFIED_VIA_BEHAVIOUR',
  NominatedCertPending = 'NOMINATED_CERT_PENDING',
  NominatedCertVerified = 'NOMINATED_CERT_VERIFIED',
  NominatedWithoutCert = 'NOMINATED_WITHOUT_CERT',
}

export type ContactUnsubscribe = {
  __typename?: 'ContactUnsubscribe';
  id: Scalars['ID']['output'];
  scope: ContactUnsubscribeScopeType;
};

export enum ContactUnsubscribeScopeType {
  ActivityFollow = 'ACTIVITY_FOLLOW',
  ActivityUpdate = 'ACTIVITY_UPDATE',
  Announcement = 'ANNOUNCEMENT',
  General = 'GENERAL',
  NewFollower = 'NEW_FOLLOWER',
  Qa = 'QA',
  Raises = 'RAISES',
  SmsRaises = 'SMS_RAISES',
}

export type CorporatePage = {
  __typename?: 'CorporatePage';
  id: Scalars['String']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isLive: Scalars['Boolean']['output'];
  sections: Array<Section>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CorporatePageType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export enum CorporatePageType {
  Governance = 'GOVERNANCE',
  Team = 'TEAM',
}

export type CurrentCompanyMarketDataSummary = {
  __typename?: 'CurrentCompanyMarketDataSummary';
  ask?: Maybe<Scalars['Float']['output']>;
  bid?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  dayHigh?: Maybe<Scalars['Float']['output']>;
  dayLow?: Maybe<Scalars['Float']['output']>;
  earningsPerShare?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isMarketOpen: Scalars['Boolean']['output'];
  lastClose?: Maybe<Scalars['Float']['output']>;
  lastTradedPrice?: Maybe<Scalars['Float']['output']>;
  listingKey: Scalars['String']['output'];
  marketCap?: Maybe<Scalars['Float']['output']>;
  marketKey: Scalars['String']['output'];
  open?: Maybe<Scalars['Float']['output']>;
  /** Already in percentage format. i.e. 21.75 means 21.75% */
  percentageChange?: Maybe<Scalars['Float']['output']>;
  priceChange?: Maybe<Scalars['Float']['output']>;
  priceEarningsRatio?: Maybe<Scalars['Float']['output']>;
  sharesOutstanding?: Maybe<Scalars['Float']['output']>;
  turnover?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  vwap?: Maybe<Scalars['Float']['output']>;
  yearHigh?: Maybe<Scalars['Float']['output']>;
  yearHighDate?: Maybe<Scalars['Date']['output']>;
  yearLow?: Maybe<Scalars['Float']['output']>;
  yearLowDate?: Maybe<Scalars['Date']['output']>;
};

export type CustomDomain = {
  __typename?: 'CustomDomain';
  configuration?: Maybe<CustomDomainConfiguration>;
  customDomain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rootDomain: Scalars['String']['output'];
};

export type CustomDomainConfiguration = {
  __typename?: 'CustomDomainConfiguration';
  configured?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomEmail = {
  __typename?: 'CustomEmail';
  replyToEmail: Scalars['String']['output'];
  sendFromEmail: Scalars['String']['output'];
  sendFromName: Scalars['String']['output'];
  type: CommsCustomEmailType;
};

export type DiscoverBlockAnnouncementsAndUpdates = {
  __typename?: 'DiscoverBlockAnnouncementsAndUpdates';
  id: Scalars['ID']['output'];
  latestAnnualReport?: Maybe<MediaAnnouncement>;
  latestInvestorPresentation?: Maybe<MediaAnnouncement>;
  latestQuarterlyCashflowReport?: Maybe<MediaAnnouncement>;
  latestUpdate?: Maybe<MediaUpdate>;
};

export type EventMetadata = {
  __typename?: 'EventMetadata';
  id?: Maybe<Scalars['String']['output']>;
};

export type EventMetadataInput = {
  announcementId?: InputMaybe<Scalars['String']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  pathname?: InputMaybe<Scalars['String']['input']>;
  preparedAnnouncementHashId?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  shareholderHashId?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String']['output'];
  orderId: Scalars['Int']['output'];
  question: Scalars['String']['output'];
};

export type Feature = {
  __typename?: 'Feature';
  buttonLink?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Filter = {
  __typename?: 'Filter';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Follow = {
  __typename?: 'Follow';
  followingInvestorUser?: Maybe<InvestorUserSimple>;
  followingMedia?: Maybe<Media>;
  followingMediaComment?: Maybe<MediaComment>;
  id: Scalars['ID']['output'];
  invalidated: Scalars['Boolean']['output'];
  investorUser?: Maybe<InvestorUserSimple>;
  isProfileFollowBack: Scalars['Boolean']['output'];
};

export type FollowInput = {
  followingInvestorUserId?: InputMaybe<Scalars['ID']['input']>;
  followingMediaCommentId?: InputMaybe<Scalars['ID']['input']>;
  followingMediaId?: InputMaybe<Scalars['ID']['input']>;
  invalidated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HoldingInputAu = {
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  partialHin: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type HoldingInputUk = {
  accountName: Scalars['String']['input'];
  broker: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  sharesOwned?: InputMaybe<Scalars['Int']['input']>;
};

export type HomepageAnnouncementsAndUpdates = {
  __typename?: 'HomepageAnnouncementsAndUpdates';
  id: Scalars['ID']['output'];
  latestAnnualReport?: Maybe<MediaAnnouncement>;
  latestInvestorPresentation?: Maybe<MediaAnnouncement>;
  latestQuarterlyCashflowReport?: Maybe<MediaAnnouncement>;
  latestTwoAnnouncements: Array<MediaAnnouncement>;
  latestUpdate?: Maybe<MediaUpdate>;
};

export enum HubNotificationType {
  Feature = 'FEATURE',
  FollowedQuestion = 'FOLLOWED_QUESTION',
  FollowedReply = 'FOLLOWED_REPLY',
  Like = 'LIKE',
  ProfileFollow = 'PROFILE_FOLLOW',
  QuestionFollow = 'QUESTION_FOLLOW',
  Reply = 'REPLY',
  Video = 'VIDEO',
}

export type Instruction = {
  __typename?: 'Instruction';
  buttonText?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  orderId: Scalars['Int']['output'];
  subheading: Scalars['String']['output'];
};

export enum InvestorHnwStatuses {
  NominatedCertPending = 'NOMINATED_CERT_PENDING',
  NominatedCertVerified = 'NOMINATED_CERT_VERIFIED',
  NominatedWithoutCert = 'NOMINATED_WITHOUT_CERT',
  NotHnw = 'NOT_HNW',
}

export type InvestorHub = {
  __typename?: 'InvestorHub';
  colourAccent: Scalars['String']['output'];
  colourAccentText: Scalars['String']['output'];
  colourPrimary: Scalars['String']['output'];
  colourPrimaryText: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registry?: Maybe<InvestorHubRegisters>;
  twitter?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export enum InvestorHubRegisters {
  Automic = 'AUTOMIC',
  Avenir = 'AVENIR',
  Boardroom = 'BOARDROOM',
  Computershare = 'COMPUTERSHARE',
  Equiniti = 'EQUINITI',
  Link = 'LINK',
  Neville = 'NEVILLE',
  ShareRegistrars = 'SHARE_REGISTRARS',
  Slc = 'SLC',
  Xcend = 'XCEND',
}

export type InvestorShareholderInformation = {
  __typename?: 'InvestorShareholderInformation';
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  partialHin: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
};

export type InvestorShareholderInformationUk = {
  __typename?: 'InvestorShareholderInformationUk';
  accountName: Scalars['String']['output'];
  broker: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  sharesOwned?: Maybe<Scalars['Int']['output']>;
};

export type InvestorUser = {
  __typename?: 'InvestorUser';
  certificate?: Maybe<Certificate>;
  company: Company;
  confirmedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  contact?: Maybe<Contact>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hnwIdentifiedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  hnwStatus?: Maybe<InvestorHnwStatuses>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isCurrentShareholder: Scalars['Boolean']['output'];
  isHoldingVerified: Scalars['Boolean']['output'];
  isSelfNominatedShareholder?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notificationPreferences: Array<NotificationPreference>;
  provider?: Maybe<Scalars['String']['output']>;
  providerUid?: Maybe<Scalars['String']['output']>;
  selfNominatedShareholderIdentifiedAt?: Maybe<
    Scalars['IsoNaiveDatetime']['output']
  >;
  shareholderInformation?: Maybe<InvestorShareholderInformation>;
  shareholderInformationUk?: Maybe<InvestorShareholderInformationUk>;
  shareholderInformations: Array<InvestorShareholderInformation>;
  shareholderInformationsUk: Array<InvestorShareholderInformationUk>;
  shareholding?: Maybe<Shareholding>;
  shareholdings: Array<Shareholding>;
  showVerifiedBadge?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum InvestorUserNotificationChannel {
  Email = 'EMAIL',
}

export enum InvestorUserNotificationScope {
  SystemInteractiveAnnouncements = 'SYSTEM_INTERACTIVE_ANNOUNCEMENTS',
  SystemNewPlacements = 'SYSTEM_NEW_PLACEMENTS',
  SystemNewShareholderOffers = 'SYSTEM_NEW_SHAREHOLDER_OFFERS',
}

export type InvestorUserSimple = {
  __typename?: 'InvestorUserSimple';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHoldingVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  showShareholderBadge: Scalars['Boolean']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type IsoNaiveDatetimeRange = {
  __typename?: 'IsoNaiveDatetimeRange';
  newest?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  oldest?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
};

export type LiveWebinar = {
  __typename?: 'LiveWebinar';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Media = {
  __typename?: 'Media';
  companyProfile?: Maybe<Company>;
  id: Scalars['ID']['output'];
  liked: Scalars['Boolean']['output'];
  likesCount: Scalars['Int']['output'];
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  mediaUpdate?: Maybe<MediaUpdate>;
};

export type MediaAnnouncement = {
  __typename?: 'MediaAnnouncement';
  featuredOnHub: Scalars['Boolean']['output'];
  germanTranslatedHeader?: Maybe<Scalars['String']['output']>;
  germanTranslatedSummary?: Maybe<Scalars['String']['output']>;
  germanTranslatedUrl?: Maybe<Scalars['String']['output']>;
  germanTranslatedVideoUrl?: Maybe<Scalars['String']['output']>;
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  likes: Scalars['Int']['output'];
  listingKey: Scalars['String']['output'];
  marketKey: Scalars['String']['output'];
  marketSensitive?: Maybe<Scalars['Boolean']['output']>;
  mediaId: Scalars['ID']['output'];
  newsPublisher?: Maybe<Scalars['String']['output']>;
  postedAt: Scalars['IsoNaiveDatetime']['output'];
  publicCommentCount: Scalars['Int']['output'];
  rectype: Scalars['String']['output'];
  socialVideoUrl?: Maybe<Scalars['String']['output']>;
  subtypes: Array<Maybe<Scalars['String']['output']>>;
  summary?: Maybe<Scalars['String']['output']>;
  summaryAi?: Maybe<Scalars['String']['output']>;
  thumbnailIsPortrait?: Maybe<Scalars['Boolean']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  totalParentComments: Scalars['Int']['output'];
  totalViewCount: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type MediaAnnouncementPublicCommentCountArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
};

export type MediaAnnouncementTotalParentCommentsArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
};

export type MediaAnnouncementConnection = {
  __typename?: 'MediaAnnouncementConnection';
  edges?: Maybe<Array<Maybe<MediaAnnouncementEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MediaAnnouncementConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaAnnouncementEdge = {
  __typename?: 'MediaAnnouncementEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaAnnouncement: MediaAnnouncement;
  node?: Maybe<MediaAnnouncement>;
};

export type MediaComment = {
  __typename?: 'MediaComment';
  annotationMetadata?: Maybe<MediaCommentAnnotationMetadata>;
  children?: Maybe<Array<MediaComment>>;
  commentSource?: Maybe<CommentSource>;
  commentSourceUrl?: Maybe<Scalars['String']['output']>;
  companyAuthor?: Maybe<CompanyUser>;
  content: Scalars['String']['output'];
  followed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  investorUser?: Maybe<InvestorUserSimple>;
  liked: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  media?: Maybe<Media>;
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  parent?: Maybe<MediaComment>;
  private: Scalars['Boolean']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  useCompanyAsUsername: Scalars['Boolean']['output'];
};

export type MediaCommentAnnotationMetadata = {
  __typename?: 'MediaCommentAnnotationMetadata';
  left: Scalars['Float']['output'];
  pageIndex: Scalars['Int']['output'];
  top: Scalars['Float']['output'];
};

export type MediaCommentAnnotationMetadataInput = {
  left: Scalars['Float']['input'];
  pageIndex: Scalars['Int']['input'];
  top: Scalars['Float']['input'];
};

export type MediaCommentConnection = {
  __typename?: 'MediaCommentConnection';
  edges?: Maybe<Array<Maybe<MediaCommentEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MediaCommentConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentEdge = {
  __typename?: 'MediaCommentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaComment: MediaComment;
  node?: Maybe<MediaComment>;
};

export type MediaCommentLike = {
  __typename?: 'MediaCommentLike';
  comment?: Maybe<MediaComment>;
  id: Scalars['ID']['output'];
  investorUser?: Maybe<InvestorUserSimple>;
  like: Scalars['Boolean']['output'];
};

export type MediaLike = {
  __typename?: 'MediaLike';
  id: Scalars['ID']['output'];
  like: Scalars['Boolean']['output'];
};

export type MediaSurveyAnswer = {
  __typename?: 'MediaSurveyAnswer';
  answer: MediaSurveyAnswerType;
  id: Scalars['ID']['output'];
  question: MediaSurveyQuestionType;
};

export type MediaSurveyAnswerCount = {
  __typename?: 'MediaSurveyAnswerCount';
  answer: MediaSurveyAnswerType;
  count: Scalars['Int']['output'];
};

export enum MediaSurveyAnswerType {
  Buy = 'BUY',
  Equal = 'EQUAL',
  Higher = 'HIGHER',
  Hold = 'HOLD',
  Likely = 'LIKELY',
  Lower = 'LOWER',
  Neutral = 'NEUTRAL',
  Sell = 'SELL',
  Unlikely = 'UNLIKELY',
}

export enum MediaSurveyQuestionType {
  Action = 'ACTION',
  Recommend = 'RECOMMEND',
  Understanding = 'UNDERSTANDING',
}

export type MediaSurveyResult = {
  __typename?: 'MediaSurveyResult';
  individualAnswers?: Maybe<Array<Maybe<MediaSurveyAnswerCount>>>;
  investorAnswer?: Maybe<MediaSurveyAnswerType>;
  question: MediaSurveyQuestionType;
  totalResponses: Scalars['Int']['output'];
};

export type MediaUpdate = {
  __typename?: 'MediaUpdate';
  answeredQuestionCount: Scalars['Int']['output'];
  attachments: Array<MediaUpdateAttachment>;
  content?: Maybe<MediaUpdateContent>;
  id: Scalars['ID']['output'];
  includedTypes?: Maybe<Array<MediaUpdateType>>;
  isDraft: Scalars['Boolean']['output'];
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  likes: Scalars['Int']['output'];
  mediaId: Scalars['ID']['output'];
  postedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  questionCount: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  thumbnailAttachment?: Maybe<MediaUpdateAttachment>;
  title: Scalars['String']['output'];
  totalViewCount: Scalars['Int']['output'];
};

export type MediaUpdateAttachment = {
  __typename?: 'MediaUpdateAttachment';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderId: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnailIsPortrait?: Maybe<Scalars['Boolean']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MediaUpdateAttachmentType>;
  url: Scalars['String']['output'];
};

export enum MediaUpdateAttachmentType {
  Image = 'IMAGE',
  None = 'NONE',
  Pdf = 'PDF',
  Url = 'URL',
  Video = 'VIDEO',
}

export type MediaUpdateConnection = {
  __typename?: 'MediaUpdateConnection';
  edges?: Maybe<Array<Maybe<MediaUpdateEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MediaUpdateConnectionTotalArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type MediaUpdateContent = {
  __typename?: 'MediaUpdateContent';
  content?: Maybe<Scalars['String']['output']>;
};

export type MediaUpdateEdge = {
  __typename?: 'MediaUpdateEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaUpdate: MediaUpdate;
  node?: Maybe<MediaUpdate>;
};

export enum MediaUpdateType {
  Image = 'IMAGE',
  None = 'NONE',
  Pdf = 'PDF',
  Url = 'URL',
  Video = 'VIDEO',
}

export type NavMenuItem = {
  __typename?: 'NavMenuItem';
  children?: Maybe<Array<Maybe<NavMenuItem>>>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  label: Scalars['String']['output'];
  openInNewTab: Scalars['Boolean']['output'];
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['ID']['output']>;
  parent?: Maybe<NavMenuItem>;
  parentId?: Maybe<Scalars['ID']['output']>;
  position: Scalars['Int']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Website>;
  websiteId?: Maybe<Scalars['ID']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  hubFeature?: Maybe<Feature>;
  hubFollow?: Maybe<Follow>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  investorUser?: Maybe<InvestorUserSimple>;
  isRead: Scalars['Boolean']['output'];
  media?: Maybe<Media>;
  mediaComment?: Maybe<MediaComment>;
  mediaCommentLike?: Maybe<MediaCommentLike>;
  mediaCommentReply?: Maybe<MediaComment>;
  type: HubNotificationType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  channel: InvestorUserNotificationChannel;
  hasEoi: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isOn: Scalars['Boolean']['output'];
  scope: InvestorUserNotificationScope;
};

export type NotificationPreferenceInput = {
  channel: InvestorUserNotificationChannel;
  isOn: Scalars['Boolean']['input'];
  scope: InvestorUserNotificationScope;
};

export type Options = {
  __typename?: 'Options';
  filters?: Maybe<Array<Maybe<Filter>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type OptionsInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
};

export type Order = {
  __typename?: 'Order';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OrderInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Page = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<Block>>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  invalidated?: Maybe<Scalars['Boolean']['output']>;
  isDraft: Scalars['Boolean']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  slug: Scalars['String']['output'];
  socialImageCloudinaryPublicId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  website?: Maybe<Website>;
  websiteId?: Maybe<Scalars['ID']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PreparedAnnouncement = {
  __typename?: 'PreparedAnnouncement';
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
};

export type RecentScheduledWebinar = {
  __typename?: 'RecentScheduledWebinar';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RecordingView = {
  __typename?: 'RecordingView';
  id: Scalars['ID']['output'];
  visitorCookieId: Scalars['String']['output'];
  watchTimeSeconds?: Maybe<Scalars['Int']['output']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  answerMediaSurvey?: Maybe<MediaSurveyAnswer>;
  autoUnblockCloudIp?: Maybe<Scalars['Int']['output']>;
  /** Contact us */
  contactUs?: Maybe<Scalars['Boolean']['output']>;
  createMediaComment?: Maybe<MediaComment>;
  /** Create or update a webinar recording view */
  createOrUpdateWebinarView?: Maybe<RecordingView>;
  /** Create tracking event */
  createTrackingEvent?: Maybe<TrackingEvent>;
  /** Deletes current investor's certificate */
  deleteCertificate?: Maybe<Scalars['Boolean']['output']>;
  downloadWebinarDocument?: Maybe<WebinarDocument>;
  invalidateMediaComment?: Maybe<MediaComment>;
  /** Mark notification as read */
  markNotificationAsRead?: Maybe<Scalars['Boolean']['output']>;
  reactToMedia?: Maybe<MediaLike>;
  reactToMediaComment?: Maybe<MediaCommentLike>;
  /** Register for a webinar */
  registerForWebinar?: Maybe<Attendee>;
  /** Resend email confirmation */
  resendConfirmUser?: Maybe<Scalars['Boolean']['output']>;
  /** Reset password */
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Declare that the user is a sophisticated / professional investor.  Also subscribes user to HNW notifications. */
  selfNominateAsHnw?: Maybe<Scalars['Boolean']['output']>;
  /** Declare that the user is a shareholder or sophisticated / professional investor. Also subscribes user to HNW notifications. */
  selfNominateAsShareholderOrHnw?: Maybe<Scalars['Boolean']['output']>;
  /** Send reset password instructions to email */
  sendResetPassword?: Maybe<Scalars['Boolean']['output']>;
  setUsername?: Maybe<InvestorUser>;
  /** Create a contact */
  subscribe: Scalars['Boolean']['output'];
  /** Subscribe contact */
  subscribeContact: Contact;
  /** Subscribe contact globally */
  subscribeContactGlobally: Contact;
  /** Unsubscribe contact */
  unsubscribeContact: Contact;
  /** Unsubscribe contact globally */
  unsubscribeContactGlobally: Contact;
  updateMediaComment?: Maybe<MediaComment>;
  /** Update user information */
  updateUser?: Maybe<InvestorUser>;
  /** Creates current investor's certificate, and invalidates existing one if any */
  uploadCertificate?: Maybe<Certificate>;
  /** Upsert follow */
  upsertFollow?: Maybe<Follow>;
  /** Upsert notification preference for current investor user */
  upsertNotificationPreference?: Maybe<NotificationPreference>;
  /** Upsert notification preference EOI for current investor user */
  upsertNotificationPreferenceEoi?: Maybe<NotificationPreference>;
  /** Deprecated 09/12/2024 - use verify_multiple_holdings_au instead */
  verifyHoldingDetails?: Maybe<Scalars['Boolean']['output']>;
  /** Deprecated 09/12/2024 - use verify_multiple_holdings_uk instead */
  verifyHoldingUk?: Maybe<InvestorShareholderInformationUk>;
  /** Verify multiple shareholding from the profile (AU) */
  verifyMultipleHoldingsAu: Array<Scalars['Boolean']['output']>;
  /** Verify multiple shareholding from the profile (UK) */
  verifyMultipleHoldingsUk: Array<InvestorShareholderInformationUk>;
  /** Verify user's email */
  verifyUserEmailByToken?: Maybe<InvestorUser>;
  /** View webinar recording */
  viewWebinarRecording?: Maybe<Attendee>;
  /** Ask a question for an upcoming webinar */
  webinarAskQuestion?: Maybe<WebinarQuestion>;
  /** Delete a question for an upcoming webinar */
  webinarDeleteQuestion?: Maybe<WebinarQuestion>;
  /** Edit a question for an upcoming webinar */
  webinarEditQuestion?: Maybe<WebinarQuestion>;
};

export type RootMutationTypeAnswerMediaSurveyArgs = {
  answer: MediaSurveyAnswerType;
  mediaId: Scalars['ID']['input'];
  question: MediaSurveyQuestionType;
};

export type RootMutationTypeContactUsArgs = {
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type RootMutationTypeCreateMediaCommentArgs = {
  annotationMetadata?: InputMaybe<MediaCommentAnnotationMetadataInput>;
  content: Scalars['String']['input'];
  hub?: InputMaybe<Scalars['String']['input']>;
  mediaId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateOrUpdateWebinarViewArgs = {
  attendeeId?: InputMaybe<Scalars['ID']['input']>;
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  visitorCookieId: Scalars['String']['input'];
  watchTimeSeconds?: InputMaybe<Scalars['Int']['input']>;
  webinarId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateTrackingEventArgs = {
  trackingEvent: TrackingEventInput;
};

export type RootMutationTypeDownloadWebinarDocumentArgs = {
  documentId: Scalars['ID']['input'];
};

export type RootMutationTypeInvalidateMediaCommentArgs = {
  mediaCommentId: Scalars['ID']['input'];
};

export type RootMutationTypeMarkNotificationAsReadArgs = {
  notificationId: Scalars['ID']['input'];
};

export type RootMutationTypeReactToMediaArgs = {
  like: Scalars['Boolean']['input'];
  mediaId: Scalars['ID']['input'];
};

export type RootMutationTypeReactToMediaCommentArgs = {
  like: Scalars['Boolean']['input'];
  mediaCommentId: Scalars['ID']['input'];
};

export type RootMutationTypeRegisterForWebinarArgs = {
  webinarId: Scalars['ID']['input'];
};

export type RootMutationTypeResendConfirmUserArgs = {
  captchaToken: Scalars['String']['input'];
  confirmationLink?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSelfNominateAsHnwArgs = {
  isHnw: Scalars['Boolean']['input'];
};

export type RootMutationTypeSelfNominateAsShareholderOrHnwArgs = {
  isHnw?: InputMaybe<Scalars['Boolean']['input']>;
  isShareholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeSendResetPasswordArgs = {
  companyProfileId: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type RootMutationTypeSetUsernameArgs = {
  username: Scalars['String']['input'];
};

export type RootMutationTypeSubscribeArgs = {
  input: SubscribeInput;
};

export type RootMutationTypeSubscribeContactArgs = {
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  scope: ContactUnsubscribeScopeType;
};

export type RootMutationTypeSubscribeContactGloballyArgs = {
  contactHashId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUnsubscribeContactArgs = {
  campaignHashId?: InputMaybe<Scalars['String']['input']>;
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  distributedEmailHashId?: InputMaybe<Scalars['String']['input']>;
  emailHashId?: InputMaybe<Scalars['String']['input']>;
  scope: ContactUnsubscribeScopeType;
};

export type RootMutationTypeUnsubscribeContactGloballyArgs = {
  campaignHashId?: InputMaybe<Scalars['String']['input']>;
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  distributedEmailHashId?: InputMaybe<Scalars['String']['input']>;
  emailHashId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateMediaCommentArgs = {
  content: Scalars['String']['input'];
  mediaCommentId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};

export type RootMutationTypeUploadCertificateArgs = {
  certificate: Scalars['Upload']['input'];
};

export type RootMutationTypeUpsertFollowArgs = {
  follow: FollowInput;
};

export type RootMutationTypeUpsertNotificationPreferenceArgs = {
  notificationPreference: NotificationPreferenceInput;
};

export type RootMutationTypeUpsertNotificationPreferenceEoiArgs = {
  notificationPreference: UpsertNotificationPreferenceEoiInput;
};

export type RootMutationTypeVerifyHoldingDetailsArgs = {
  country: Scalars['String']['input'];
  partialHin: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeVerifyHoldingUkArgs = {
  accountName: Scalars['String']['input'];
  broker: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  sharesOwned?: InputMaybe<Scalars['Int']['input']>;
};

export type RootMutationTypeVerifyMultipleHoldingsAuArgs = {
  holdings: Array<HoldingInputAu>;
};

export type RootMutationTypeVerifyMultipleHoldingsUkArgs = {
  holdings: Array<HoldingInputUk>;
};

export type RootMutationTypeVerifyUserEmailByTokenArgs = {
  token: Scalars['String']['input'];
};

export type RootMutationTypeViewWebinarRecordingArgs = {
  webinarId: Scalars['ID']['input'];
};

export type RootMutationTypeWebinarAskQuestionArgs = {
  content: Scalars['String']['input'];
  webinarId: Scalars['ID']['input'];
};

export type RootMutationTypeWebinarDeleteQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type RootMutationTypeWebinarEditQuestionArgs = {
  content: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Get all companies for getStaticPaths */
  allCompanies: Array<Company>;
  /** Get company by market listing. Used by cloud function ogImage. */
  companyByMarketListing?: Maybe<Company>;
  contactByHashId?: Maybe<Contact>;
  /** Get current company */
  currentCompany?: Maybe<Company>;
  /** Get current company market data */
  currentCompanyMarketDataSummary?: Maybe<CurrentCompanyMarketDataSummary>;
  /** Get the company overview */
  currentCompanyOverview?: Maybe<Array<Maybe<CompanyOverview>>>;
  /** Get current company secondary market data summary */
  currentCompanySecondaryMarketDataSummary: Array<CurrentCompanyMarketDataSummary>;
  /** Get current custom corporate page by slug */
  currentCustomCorporatePageBySlug?: Maybe<CorporatePage>;
  /** Get current governance page */
  currentGovernancePage?: Maybe<CorporatePage>;
  /** Get current investor user */
  currentInvestorUser?: Maybe<InvestorUser>;
  /** Get current welcome page */
  currentWelcomePage?: Maybe<WelcomePage>;
  discoverBlockAnnouncementsAndUpdates?: Maybe<DiscoverBlockAnnouncementsAndUpdates>;
  homepageAnnouncementsAndUpdates?: Maybe<HomepageAnnouncementsAndUpdates>;
  /** Hub notifications */
  hubNotifications: Array<Notification>;
  /** Gets an investment offer by ID */
  investmentOffer: ShareholderOffer;
  /** Get paginated investor media comments */
  investorMediaComments?: Maybe<MediaCommentConnection>;
  /** By passing flag name and company profile id to see if given feature flag is enabled. */
  isFlagEnabled: Scalars['Boolean']['output'];
  isUsernameAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Get live custom corporate pages */
  liveCustomCorporatePages: Array<CorporatePage>;
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  mediaAnnouncementDateRange?: Maybe<IsoNaiveDatetimeRange>;
  mediaAnnouncements?: Maybe<MediaAnnouncementConnection>;
  mediaComments: Array<MediaComment>;
  /** Get is media followed */
  mediaFollow: Scalars['Boolean']['output'];
  /** Get is media liked */
  mediaLike: Scalars['Boolean']['output'];
  mediaSurveyResults: Array<MediaSurveyResult>;
  /** Get media update for company */
  mediaUpdateBySlug?: Maybe<MediaUpdate>;
  /** Gets the range of media update post dates */
  mediaUpdateDateRange?: Maybe<IsoNaiveDatetimeRange>;
  /** Get paginated media posts */
  mediaUpdates?: Maybe<MediaUpdateConnection>;
  /** Gets the oldest media post date */
  oldestMediaUpdateDate?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  /** Get a single page by slug */
  pageBySlug?: Maybe<Page>;
  preparedAnnouncement?: Maybe<PreparedAnnouncement>;
  suggestedUsername?: Maybe<Scalars['String']['output']>;
  /** Get API token */
  token?: Maybe<Token>;
  /** Get featured media announcements */
  totalFeaturedAnnouncements: Scalars['Int']['output'];
  /** Get total media likes */
  totalMediaLikes: Scalars['Int']['output'];
  userComments: Array<MediaComment>;
  userLikes: Array<UserLike>;
  /** Get is user profile followed */
  userProfileFollow: Scalars['Boolean']['output'];
  userProfileStats?: Maybe<UserProfileStats>;
  userSurveys: Array<UserSurveyResult>;
  /** Get utm link */
  utmLinkFetchByHash?: Maybe<UtmLink>;
  /** Get everything related to a webinar from an investors perspective */
  webinarData?: Maybe<WebinarData>;
  /** Get a paginated list of webinars for the current hub */
  webinars?: Maybe<WebinarConnection>;
};

export type RootQueryTypeAllCompaniesArgs = {
  secret: Scalars['String']['input'];
};

export type RootQueryTypeCompanyByMarketListingArgs = {
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
};

export type RootQueryTypeContactByHashIdArgs = {
  hashId: Scalars['String']['input'];
};

export type RootQueryTypeCurrentCompanyArgs = {
  currentPageSlug?: InputMaybe<Scalars['String']['input']>;
  host: Scalars['String']['input'];
};

export type RootQueryTypeCurrentCompanyOverviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeCurrentCustomCorporatePageBySlugArgs = {
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type RootQueryTypeCurrentGovernancePageArgs = {
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
};

export type RootQueryTypeCurrentWelcomePageArgs = {
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
};

export type RootQueryTypeDiscoverBlockAnnouncementsAndUpdatesArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeHomepageAnnouncementsAndUpdatesArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeInvestmentOfferArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeInvestorMediaCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hub: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeIsFlagEnabledArgs = {
  companyProfileId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type RootQueryTypeIsUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};

export type RootQueryTypeLiveCustomCorporatePagesArgs = {
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
};

export type RootQueryTypeMediaAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeMediaAnnouncementDateRangeArgs = {
  ticker: Scalars['String']['input'];
};

export type RootQueryTypeMediaAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hub?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeMediaCommentsArgs = {
  hub: Scalars['String']['input'];
  isAnnotation?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaFollowArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaLikeArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaSurveyResultsArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaUpdateBySlugArgs = {
  hub: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type RootQueryTypeMediaUpdateDateRangeArgs = {
  hub: Scalars['String']['input'];
};

export type RootQueryTypeMediaUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hub: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeOldestMediaUpdateDateArgs = {
  hub: Scalars['String']['input'];
};

export type RootQueryTypePageBySlugArgs = {
  hub: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type RootQueryTypePreparedAnnouncementArgs = {
  hashid: Scalars['String']['input'];
  host: Scalars['String']['input'];
};

export type RootQueryTypeTotalFeaturedAnnouncementsArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeTotalMediaLikesArgs = {
  hub?: InputMaybe<Scalars['String']['input']>;
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeUserCommentsArgs = {
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RootQueryTypeUserLikesArgs = {
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RootQueryTypeUserProfileFollowArgs = {
  investorUserId: Scalars['ID']['input'];
};

export type RootQueryTypeUserProfileStatsArgs = {
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RootQueryTypeUserSurveysArgs = {
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RootQueryTypeUtmLinkFetchByHashArgs = {
  hash: Scalars['String']['input'];
};

export type RootQueryTypeWebinarDataArgs = {
  slug: Scalars['String']['input'];
};

export type RootQueryTypeWebinarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  notificationUpserted?: Maybe<Notification>;
};

export type SecondaryTicker = {
  __typename?: 'SecondaryTicker';
  id: Scalars['ID']['output'];
  listingKey: Scalars['String']['output'];
  marketKey: Scalars['String']['output'];
};

export type Section = {
  __typename?: 'Section';
  attachments: Array<Attachment>;
  description?: Maybe<Scalars['String']['output']>;
  heading: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ShadeMap = {
  __typename?: 'ShadeMap';
  s10: Scalars['String']['output'];
  s20: Scalars['String']['output'];
  s30: Scalars['String']['output'];
  s40: Scalars['String']['output'];
  s50: Scalars['String']['output'];
  s60: Scalars['String']['output'];
  s70: Scalars['String']['output'];
  s80: Scalars['String']['output'];
  s90: Scalars['String']['output'];
};

export type ShareholderOffer = {
  __typename?: 'ShareholderOffer';
  companyShareholderOfferPage?: Maybe<ShareholderOfferPage>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isLive: Scalars['Boolean']['output'];
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  scheduledAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  title: Scalars['String']['output'];
  type: ShareholderOfferType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type ShareholderOfferPage = {
  __typename?: 'ShareholderOfferPage';
  bannerUrl?: Maybe<Scalars['String']['output']>;
  closingDate?: Maybe<Scalars['Date']['output']>;
  faqs: Array<Faq>;
  id: Scalars['String']['output'];
  instructions: Array<Instruction>;
  interactiveAnnouncementLink?: Maybe<Scalars['String']['output']>;
  introductionHeading?: Maybe<Scalars['String']['output']>;
  introductionMessage?: Maybe<Scalars['String']['output']>;
  investorPresentationName?: Maybe<Scalars['String']['output']>;
  investorPresentationUrl?: Maybe<Scalars['String']['output']>;
  offerBookletName?: Maybe<Scalars['String']['output']>;
  offerBookletUrl?: Maybe<Scalars['String']['output']>;
  offerPrice?: Maybe<Scalars['String']['output']>;
  raiseApplyInstructionMessage?: Maybe<Scalars['String']['output']>;
  raiseDefinitionMessage?: Maybe<Scalars['String']['output']>;
  raiseDefinitionTimelineDiagramUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaImageUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaSocialVideoUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaVideoUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonMessage?: Maybe<Scalars['String']['output']>;
  raisingTarget?: Maybe<Scalars['Int']['output']>;
  usCitizenPageEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export enum ShareholderOfferType {
  Entitlement = 'ENTITLEMENT',
  Nreo = 'NREO',
  Spp = 'SPP',
}

export type Shareholding = {
  __typename?: 'Shareholding';
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostcode?: Maybe<Scalars['String']['output']>;
  holderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type SubscribeInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type Ticker = {
  __typename?: 'Ticker';
  id: Scalars['ID']['output'];
  listingKey: Scalars['String']['output'];
  marketKey: Scalars['String']['output'];
  marketListingKey: Scalars['String']['output'];
  refinitivIdentificationCode?: Maybe<Scalars['String']['output']>;
};

export type TintMap = {
  __typename?: 'TintMap';
  t10: Scalars['String']['output'];
  t20: Scalars['String']['output'];
  t30: Scalars['String']['output'];
  t40: Scalars['String']['output'];
  t50: Scalars['String']['output'];
  t60: Scalars['String']['output'];
  t70: Scalars['String']['output'];
  t80: Scalars['String']['output'];
  t90: Scalars['String']['output'];
};

export type Token = {
  __typename?: 'Token';
  value: Scalars['String']['output'];
};

export type TrackingEvent = {
  __typename?: 'TrackingEvent';
  createdAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  eventMetadata?: Maybe<EventMetadata>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TrackingEventInput = {
  event?: InputMaybe<Scalars['String']['input']>;
  eventMetadata?: InputMaybe<EventMetadataInput>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  showVerifiedBadge?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertNotificationPreferenceEoiInput = {
  channel: InvestorUserNotificationChannel;
  hasEoi: Scalars['Boolean']['input'];
  scope: InvestorUserNotificationScope;
};

export type UserCommentLike = {
  __typename?: 'UserCommentLike';
  comment?: Maybe<MediaComment>;
  id: Scalars['ID']['output'];
  like: Scalars['Boolean']['output'];
};

export type UserLike = {
  __typename?: 'UserLike';
  commentLike?: Maybe<UserCommentLike>;
  id: Scalars['ID']['output'];
  mediaLike?: Maybe<UserMediaLike>;
};

export type UserMediaLike = {
  __typename?: 'UserMediaLike';
  id: Scalars['ID']['output'];
  like: Scalars['Boolean']['output'];
  media?: Maybe<Media>;
};

export type UserProfileStats = {
  __typename?: 'UserProfileStats';
  id: Scalars['ID']['output'];
  isShareholder?: Maybe<Scalars['Boolean']['output']>;
  joined: Scalars['IsoNaiveDatetime']['output'];
  likes: Scalars['Int']['output'];
  questionsAsked: Scalars['Int']['output'];
  replies: Scalars['Int']['output'];
  showShareholderBadge?: Maybe<Scalars['Boolean']['output']>;
  surveyResponses: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type UserSurveyResult = {
  __typename?: 'UserSurveyResult';
  media: Media;
  mediaId: Scalars['ID']['output'];
  userResponses: Array<UserSurveyResultResponses>;
};

export type UserSurveyResultResponses = {
  __typename?: 'UserSurveyResultResponses';
  answer: MediaSurveyAnswerType;
  question: MediaSurveyQuestionType;
  totalRespondents: Scalars['Int']['output'];
};

export type UtmLink = {
  __typename?: 'UtmLink';
  id: Scalars['ID']['output'];
  utmUrl: Scalars['String']['output'];
};

export type Webinar = {
  __typename?: 'Webinar';
  allowPreWebinarComments?: Maybe<Scalars['Boolean']['output']>;
  attendanceNeedsLogin?: Maybe<Scalars['Boolean']['output']>;
  companyWebinarId: Scalars['Int']['output'];
  discoverableOnHub?: Maybe<Scalars['Boolean']['output']>;
  documents?: Maybe<Array<Maybe<WebinarDocument>>>;
  endTime?: Maybe<Scalars['NaiveDateTime']['output']>;
  hmsRecordingState?: Maybe<Scalars['String']['output']>;
  hmsRoomId?: Maybe<Scalars['String']['output']>;
  hmsViewerRoomCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageCloudinaryId?: Maybe<Scalars['String']['output']>;
  imageOverlayEnabled?: Maybe<Scalars['Boolean']['output']>;
  mediaUpdate?: Maybe<MediaUpdate>;
  posterImageUrl?: Maybe<Scalars['String']['output']>;
  publishedRecordingUrl?: Maybe<Scalars['String']['output']>;
  recordingNeedsLogin?: Maybe<Scalars['Boolean']['output']>;
  showTranscriptOnHub?: Maybe<Scalars['Boolean']['output']>;
  showTranscriptSummaryOnHub?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  startTime?: Maybe<Scalars['NaiveDateTime']['output']>;
  startedBroadcastingAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stoppedBroadcastingAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  summary?: Maybe<Scalars['Map']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  transcript?: Maybe<Scalars['Map']['output']>;
  transcriptSummary?: Maybe<Scalars['Map']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type WebinarConnection = {
  __typename?: 'WebinarConnection';
  edges?: Maybe<Array<Maybe<WebinarEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type WebinarConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type WebinarData = {
  __typename?: 'WebinarData';
  attendee?: Maybe<Attendee>;
  webinar?: Maybe<Webinar>;
};

export type WebinarDocument = {
  __typename?: 'WebinarDocument';
  cloudinaryId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  position?: Maybe<Scalars['Int']['output']>;
};

export type WebinarEdge = {
  __typename?: 'WebinarEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Webinar>;
  webinar: Webinar;
};

export type WebinarQuestion = {
  __typename?: 'WebinarQuestion';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type Website = {
  __typename?: 'Website';
  address?: Maybe<Scalars['String']['output']>;
  badges?: Maybe<Array<Scalars['Map']['output']>>;
  companyProfileId?: Maybe<Scalars['ID']['output']>;
  cookieBanner?: Maybe<Scalars['String']['output']>;
  currentPage?: Maybe<Page>;
  description?: Maybe<Scalars['Map']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailPopupModalCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  featuresOnSignUpPage?: Maybe<Scalars['Map']['output']>;
  footerBackgroundColor?: Maybe<Scalars['String']['output']>;
  footerButtonBackgroundColor?: Maybe<Scalars['String']['output']>;
  footerButtonTextColor?: Maybe<Scalars['String']['output']>;
  footerLogoCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  footerTextColor?: Maybe<Scalars['String']['output']>;
  googleAnalyticsId?: Maybe<Scalars['String']['output']>;
  headTagContent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  isCustomFooterEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDarkMode?: Maybe<Scalars['Boolean']['output']>;
  isEmailPopupModalEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFullWebsiteReplacement?: Maybe<Scalars['Boolean']['output']>;
  isNewsletterSubscriptionEnabled?: Maybe<Scalars['Boolean']['output']>;
  isSitemapEnabled?: Maybe<Scalars['Boolean']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  logoCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  logoSquareCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  newsletterSubscription?: Maybe<Scalars['Map']['output']>;
  pages?: Maybe<Array<Maybe<Page>>>;
  parentNavMenuItems?: Maybe<Array<Maybe<NavMenuItem>>>;
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  showWebinarBanner?: Maybe<Scalars['Boolean']['output']>;
  signUpPageTermsAndConditions?: Maybe<Scalars['String']['output']>;
  sitemapLinkGroups?: Maybe<Array<Maybe<Scalars['Map']['output']>>>;
  telephone?: Maybe<Scalars['String']['output']>;
  themeColourAccent?: Maybe<Scalars['String']['output']>;
  themeColourAccentText?: Maybe<Scalars['String']['output']>;
  themeColourPrimary?: Maybe<Scalars['String']['output']>;
  themeColourPrimaryText?: Maybe<Scalars['String']['output']>;
  themeFontBody?: Maybe<Scalars['String']['output']>;
  themeFontTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type WebsiteCurrentPageArgs = {
  currentPageSlug?: InputMaybe<Scalars['String']['input']>;
};

export type WelcomePage = {
  __typename?: 'WelcomePage';
  author: Scalars['String']['output'];
  bannerUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  linkedinProfileUrl?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  welcomeMessage: Scalars['String']['output'];
};

export type AnswerMediaSurveyMutationVariables = Exact<{
  answer: MediaSurveyAnswerType;
  mediaId: Scalars['ID']['input'];
  question: MediaSurveyQuestionType;
}>;

export type AnswerMediaSurveyMutation = {
  __typename?: 'RootMutationType';
  answerMediaSurvey?: {
    __typename?: 'MediaSurveyAnswer';
    id: string;
    answer: MediaSurveyAnswerType;
    question: MediaSurveyQuestionType;
  } | null;
};

export type AutoUnblockCloudIpMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AutoUnblockCloudIpMutation = {
  __typename?: 'RootMutationType';
  autoUnblockCloudIp?: number | null;
};

export type ContactUsMutationVariables = Exact<{
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;

export type ContactUsMutation = {
  __typename?: 'RootMutationType';
  contactUs?: boolean | null;
};

export type CreateMediaCommentMutationVariables = Exact<{
  annotationMetadata?: InputMaybe<MediaCommentAnnotationMetadataInput>;
  content: Scalars['String']['input'];
  mediaId: Scalars['ID']['input'];
  hub: Scalars['String']['input'];
}>;

export type CreateMediaCommentMutation = {
  __typename?: 'RootMutationType';
  createMediaComment?: {
    __typename?: 'MediaComment';
    id: string;
    insertedAt: string;
    updatedAt: string;
    useCompanyAsUsername: boolean;
    commentSourceUrl?: string | null;
    content: string;
    followed: boolean;
    liked: boolean;
    likes: number;
    private: boolean;
    annotationMetadata?: {
      __typename?: 'MediaCommentAnnotationMetadata';
      left: number;
      pageIndex: number;
      top: number;
    } | null;
    children?: Array<{
      __typename?: 'MediaComment';
      id: string;
      insertedAt: string;
      updatedAt: string;
      commentSourceUrl?: string | null;
      content: string;
      followed: boolean;
      liked: boolean;
      likes: number;
      private: boolean;
      useCompanyAsUsername: boolean;
      annotationMetadata?: {
        __typename?: 'MediaCommentAnnotationMetadata';
        left: number;
        pageIndex: number;
        top: number;
      } | null;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      investorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
        showShareholderBadge: boolean;
        username?: string | null;
      } | null;
    }> | null;
    companyAuthor?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    investorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      showShareholderBadge: boolean;
      username?: string | null;
    } | null;
  } | null;
};

export type CreateOrUpdateWebinarViewMutationVariables = Exact<{
  webinarId: Scalars['ID']['input'];
  visitorCookieId: Scalars['String']['input'];
  watchTimeSeconds?: InputMaybe<Scalars['Int']['input']>;
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  attendeeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateOrUpdateWebinarViewMutation = {
  __typename?: 'RootMutationType';
  createOrUpdateWebinarView?: {
    __typename?: 'RecordingView';
    id: string;
    watchTimeSeconds?: number | null;
    visitorCookieId: string;
  } | null;
};

export type CreateTrackingEventMutationVariables = Exact<{
  trackingEvent: TrackingEventInput;
}>;

export type CreateTrackingEventMutation = {
  __typename?: 'RootMutationType';
  createTrackingEvent?: {
    __typename?: 'TrackingEvent';
    id?: string | null;
  } | null;
};

export type DeleteCertificateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteCertificateMutation = {
  __typename?: 'RootMutationType';
  deleteCertificate?: boolean | null;
};

export type DownloadWebinarDocumentMutationVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;

export type DownloadWebinarDocumentMutation = {
  __typename?: 'RootMutationType';
  downloadWebinarDocument?: {
    __typename?: 'WebinarDocument';
    id: string;
    fileName?: string | null;
    fileType?: string | null;
    fileSize?: number | null;
    cloudinaryId?: string | null;
  } | null;
};

export type InvalidateMediaCommentMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
}>;

export type InvalidateMediaCommentMutation = {
  __typename?: 'RootMutationType';
  invalidateMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['ID']['input'];
}>;

export type MarkNotificationAsReadMutation = {
  __typename?: 'RootMutationType';
  markNotificationAsRead?: boolean | null;
};

export type ReactToMediaCommentMutationVariables = Exact<{
  like: Scalars['Boolean']['input'];
  mediaCommentId: Scalars['ID']['input'];
}>;

export type ReactToMediaCommentMutation = {
  __typename?: 'RootMutationType';
  reactToMediaComment?: {
    __typename?: 'MediaCommentLike';
    id: string;
    like: boolean;
  } | null;
};

export type ReactToMediaMutationVariables = Exact<{
  mediaId: Scalars['ID']['input'];
  like: Scalars['Boolean']['input'];
}>;

export type ReactToMediaMutation = {
  __typename?: 'RootMutationType';
  reactToMedia?: { __typename?: 'MediaLike'; id: string; like: boolean } | null;
};

export type RegisterForWebinarMutationVariables = Exact<{
  webinarId: Scalars['ID']['input'];
}>;

export type RegisterForWebinarMutation = {
  __typename?: 'RootMutationType';
  registerForWebinar?: {
    __typename?: 'Attendee';
    id: string;
    insertedAt: string;
  } | null;
};

export type ResendConfirmUserMutationVariables = Exact<{
  captchaToken: Scalars['String']['input'];
  confirmationLink?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ResendConfirmUserMutation = {
  __typename?: 'RootMutationType';
  resendConfirmUser?: boolean | null;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordMutation = {
  __typename?: 'RootMutationType';
  resetPassword?: boolean | null;
};

export type SelfNominateAsHnwMutationVariables = Exact<{
  isHnw: Scalars['Boolean']['input'];
}>;

export type SelfNominateAsHnwMutation = {
  __typename?: 'RootMutationType';
  selfNominateAsHnw?: boolean | null;
};

export type SelfNominateAsShareholderOrHnwMutationVariables = Exact<{
  isHnw?: InputMaybe<Scalars['Boolean']['input']>;
  isShareholder?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SelfNominateAsShareholderOrHnwMutation = {
  __typename?: 'RootMutationType';
  selfNominateAsShareholderOrHnw?: boolean | null;
};

export type SendResetPasswordMutationVariables = Exact<{
  companyProfileId: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordMutation = {
  __typename?: 'RootMutationType';
  sendResetPassword?: boolean | null;
};

export type SetUsernameMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type SetUsernameMutation = {
  __typename?: 'RootMutationType';
  setUsername?: {
    __typename?: 'InvestorUser';
    id: string;
    username?: string | null;
  } | null;
};

export type SubscribeContactGloballyMutationVariables = Exact<{
  contactHashId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscribeContactGloballyMutation = {
  __typename?: 'RootMutationType';
  subscribeContactGlobally: {
    __typename?: 'Contact';
    id: string;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
  };
};

export type SubscribeContactMutationVariables = Exact<{
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  scope: ContactUnsubscribeScopeType;
}>;

export type SubscribeContactMutation = {
  __typename?: 'RootMutationType';
  subscribeContact: {
    __typename?: 'Contact';
    id: string;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
  };
};

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribeMutation = {
  __typename?: 'RootMutationType';
  subscribe: boolean;
};

export type UnsubscribeContactGloballyMutationVariables = Exact<{
  campaignHashId?: InputMaybe<Scalars['String']['input']>;
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  distributedEmailHashId?: InputMaybe<Scalars['String']['input']>;
  emailHashId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UnsubscribeContactGloballyMutation = {
  __typename?: 'RootMutationType';
  unsubscribeContactGlobally: {
    __typename?: 'Contact';
    id: string;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
  };
};

export type UnsubscribeContactMutationVariables = Exact<{
  campaignHashId?: InputMaybe<Scalars['String']['input']>;
  contactHashId?: InputMaybe<Scalars['String']['input']>;
  distributedEmailHashId?: InputMaybe<Scalars['String']['input']>;
  emailHashId?: InputMaybe<Scalars['String']['input']>;
  scope: ContactUnsubscribeScopeType;
}>;

export type UnsubscribeContactMutation = {
  __typename?: 'RootMutationType';
  unsubscribeContact: {
    __typename?: 'Contact';
    id: string;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
  };
};

export type UpdateMediaCommentMutationVariables = Exact<{
  content: Scalars['String']['input'];
  mediaCommentId: Scalars['ID']['input'];
}>;

export type UpdateMediaCommentMutation = {
  __typename?: 'RootMutationType';
  updateMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'RootMutationType';
  updateUser?: {
    __typename?: 'InvestorUser';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    showVerifiedBadge?: boolean | null;
  } | null;
};

export type UploadCertificateMutationVariables = Exact<{
  certificate: Scalars['Upload']['input'];
}>;

export type UploadCertificateMutation = {
  __typename?: 'RootMutationType';
  uploadCertificate?: {
    __typename?: 'Certificate';
    id: string;
    certificateUrl: string;
    expiresAt?: string | null;
    insertedAt: string;
    statDecAcceptedAt?: string | null;
    status: CertificateStatus;
  } | null;
};

export type UpsertFollowMutationVariables = Exact<{
  follow: FollowInput;
}>;

export type UpsertFollowMutation = {
  __typename?: 'RootMutationType';
  upsertFollow?: {
    __typename?: 'Follow';
    id: string;
    invalidated: boolean;
    followingInvestorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
    } | null;
    followingMedia?: {
      __typename?: 'Media';
      id: string;
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        header: string;
      } | null;
      mediaUpdate?: {
        __typename?: 'MediaUpdate';
        id: string;
        title: string;
      } | null;
    } | null;
    followingMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
    investorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
    } | null;
  } | null;
};

export type UpsertNotificationPreferenceEoiMutationVariables = Exact<{
  notificationPreference: UpsertNotificationPreferenceEoiInput;
}>;

export type UpsertNotificationPreferenceEoiMutation = {
  __typename?: 'RootMutationType';
  upsertNotificationPreferenceEoi?: {
    __typename?: 'NotificationPreference';
    id: string;
  } | null;
};

export type VerifyHoldingDetailsMutationVariables = Exact<{
  postcode?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  partialHin: Scalars['String']['input'];
}>;

export type VerifyHoldingDetailsMutation = {
  __typename?: 'RootMutationType';
  verifyHoldingDetails?: boolean | null;
};

export type VerifyHoldingUkMutationVariables = Exact<{
  accountName: Scalars['String']['input'];
  broker: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  sharesOwned?: InputMaybe<Scalars['Int']['input']>;
}>;

export type VerifyHoldingUkMutation = {
  __typename?: 'RootMutationType';
  verifyHoldingUk?: {
    __typename?: 'InvestorShareholderInformationUk';
    id: string;
    accountName: string;
    broker: string;
    postcode?: string | null;
    sharesOwned?: number | null;
  } | null;
};

export type VerifyMultipleHoldingsAuMutationVariables = Exact<{
  holdings: Array<HoldingInputAu> | HoldingInputAu;
}>;

export type VerifyMultipleHoldingsAuMutation = {
  __typename?: 'RootMutationType';
  verifyMultipleHoldingsAu: Array<boolean>;
};

export type VerifyMultipleHoldingsUkMutationVariables = Exact<{
  holdings: Array<HoldingInputUk> | HoldingInputUk;
}>;

export type VerifyMultipleHoldingsUkMutation = {
  __typename?: 'RootMutationType';
  verifyMultipleHoldingsUk: Array<{
    __typename?: 'InvestorShareholderInformationUk';
    id: string;
    accountName: string;
    broker: string;
    postcode?: string | null;
    sharesOwned?: number | null;
  }>;
};

export type VerifyUserEmailByTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type VerifyUserEmailByTokenMutation = {
  __typename?: 'RootMutationType';
  verifyUserEmailByToken?: {
    __typename?: 'InvestorUser';
    id: string;
    confirmedAt?: string | null;
  } | null;
};

export type ViewWebinarRecordingMutationVariables = Exact<{
  webinarId: Scalars['ID']['input'];
}>;

export type ViewWebinarRecordingMutation = {
  __typename?: 'RootMutationType';
  viewWebinarRecording?: {
    __typename?: 'Attendee';
    id: string;
    insertedAt: string;
  } | null;
};

export type WebinarAskQuestionMutationVariables = Exact<{
  webinarId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;

export type WebinarAskQuestionMutation = {
  __typename?: 'RootMutationType';
  webinarAskQuestion?: {
    __typename?: 'WebinarQuestion';
    id: string;
    content: string;
  } | null;
};

export type WebinarDeleteQuestionMutationVariables = Exact<{
  questionId: Scalars['ID']['input'];
}>;

export type WebinarDeleteQuestionMutation = {
  __typename?: 'RootMutationType';
  webinarDeleteQuestion?: {
    __typename?: 'WebinarQuestion';
    id: string;
    content: string;
  } | null;
};

export type WebinarEditQuestionMutationVariables = Exact<{
  questionId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;

export type WebinarEditQuestionMutation = {
  __typename?: 'RootMutationType';
  webinarEditQuestion?: {
    __typename?: 'WebinarQuestion';
    id: string;
    content: string;
  } | null;
};

export type AllCompaniesQueryVariables = Exact<{
  secret: Scalars['String']['input'];
}>;

export type AllCompaniesQuery = {
  __typename?: 'RootQueryType';
  allCompanies: Array<{
    __typename?: 'Company';
    id: string;
    investorCentreEnabled: boolean;
    customDomain?: {
      __typename?: 'CustomDomain';
      id: string;
      customDomain: string;
    } | null;
    ticker: {
      __typename?: 'Ticker';
      id: string;
      listingKey: string;
      marketListingKey: string;
    };
  }>;
};

export type ContactByHashIdQueryVariables = Exact<{
  hashId: Scalars['String']['input'];
}>;

export type ContactByHashIdQuery = {
  __typename?: 'RootQueryType';
  contactByHashId?: {
    __typename?: 'Contact';
    id: string;
    hnwStatus?: ContactHnwStatuses | null;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
    investor?: { __typename?: 'InvestorUserSimple'; id: string } | null;
  } | null;
};

export type CurrentCompanyMarketDataSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanyMarketDataSummaryQuery = {
  __typename?: 'RootQueryType';
  currentCompanyMarketDataSummary?: {
    __typename?: 'CurrentCompanyMarketDataSummary';
    id: string;
    currency?: string | null;
    isMarketOpen: boolean;
    lastTradedPrice?: number | null;
    open?: number | null;
    dayLow?: number | null;
    dayHigh?: number | null;
    priceChange?: number | null;
    percentageChange?: number | null;
    lastClose?: number | null;
    volume?: number | null;
    turnover?: number | null;
    bid?: number | null;
    ask?: number | null;
    marketCap?: number | null;
    sharesOutstanding?: number | null;
    vwap?: number | null;
    yearLow?: number | null;
    yearLowDate?: string | null;
    yearHigh?: number | null;
    yearHighDate?: string | null;
    earningsPerShare?: number | null;
    priceEarningsRatio?: number | null;
    updatedAt?: string | null;
  } | null;
};

export type CurrentCompanyOverviewQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type CurrentCompanyOverviewQuery = {
  __typename?: 'RootQueryType';
  currentCompanyOverview?: Array<{
    __typename?: 'CompanyOverview';
    id: string;
    date: string;
    close?: number | null;
    volume?: number | null;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
    }>;
  } | null> | null;
};

export type CurrentCompanySecondaryMarketDataSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanySecondaryMarketDataSummaryQuery = {
  __typename?: 'RootQueryType';
  currentCompanySecondaryMarketDataSummary: Array<{
    __typename?: 'CurrentCompanyMarketDataSummary';
    id: string;
    listingKey: string;
    marketKey: string;
    currency?: string | null;
    isMarketOpen: boolean;
    lastTradedPrice?: number | null;
    open?: number | null;
    dayLow?: number | null;
    dayHigh?: number | null;
    priceChange?: number | null;
    percentageChange?: number | null;
    lastClose?: number | null;
    volume?: number | null;
    turnover?: number | null;
    bid?: number | null;
    ask?: number | null;
    marketCap?: number | null;
    sharesOutstanding?: number | null;
    vwap?: number | null;
    yearLow?: number | null;
    yearLowDate?: string | null;
    yearHigh?: number | null;
    yearHighDate?: string | null;
    earningsPerShare?: number | null;
    priceEarningsRatio?: number | null;
    updatedAt?: string | null;
  }>;
};

export type CurrentCompanyQueryVariables = Exact<{
  host: Scalars['String']['input'];
  currentPageSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type CurrentCompanyQuery = {
  __typename?: 'RootQueryType';
  currentCompany?: {
    __typename?: 'Company';
    id: string;
    defaultPublicInteractionsEnabled: boolean;
    hub: string;
    investorCentreEnabled: boolean;
    logoUrl: string;
    name: string;
    featuresEnabled: Array<string | null>;
    hasCompanyShareholderOfferPermission: boolean;
    hasLiveGovernancePage: boolean;
    isDemo: boolean;
    isPremium: boolean;
    isTrial: boolean;
    isUnlisted: boolean;
    liveInvestmentOfferId?: string | null;
    sppUrl?: string | null;
    sppNavText?: string | null;
    sppBannerDescription?: string | null;
    sppBannerButtonText?: string | null;
    sppBannerMobileButtonText?: string | null;
    colorPalette?: {
      __typename?: 'CompanyColorPalette';
      primary: { __typename?: 'BrandColor'; hsl: string };
    } | null;
    investorHub: {
      __typename?: 'InvestorHub';
      id: string;
      email?: string | null;
      facebook?: string | null;
      instagram?: string | null;
      linkedin?: string | null;
      phone?: string | null;
      registry?: InvestorHubRegisters | null;
      twitter?: string | null;
      website?: string | null;
      colourPrimary: string;
      colourPrimaryText: string;
      colourAccent: string;
      colourAccentText: string;
    };
    ticker: {
      __typename?: 'Ticker';
      id: string;
      listingKey: string;
      marketKey: string;
      marketListingKey: string;
      refinitivIdentificationCode?: string | null;
    };
    recentScheduledWebinar?: {
      __typename?: 'RecentScheduledWebinar';
      id: string;
      title: string;
      slug: string;
    } | null;
    liveWebinar?: {
      __typename?: 'LiveWebinar';
      id: string;
      title: string;
      slug: string;
    } | null;
    secondaryTickers: Array<{
      __typename?: 'SecondaryTicker';
      id: string;
      listingKey: string;
      marketKey: string;
    }>;
    currentCustomCorporatePageBySlug?: {
      __typename?: 'CorporatePage';
      id: string;
      title: string;
      slug: string;
      isLive: boolean;
    } | null;
    marketingEmail?: {
      __typename?: 'CustomEmail';
      sendFromName: string;
      sendFromEmail: string;
      replyToEmail: string;
    } | null;
    publishedWebsite?: {
      __typename?: 'Website';
      id: string;
      themeColourPrimary?: string | null;
      themeColourPrimaryText?: string | null;
      themeColourAccent?: string | null;
      themeColourAccentText?: string | null;
      themeFontTitle?: string | null;
      themeFontBody?: string | null;
      logoCloudinaryUrl?: string | null;
      logoSquareCloudinaryUrl?: string | null;
      isFullWebsiteReplacement?: boolean | null;
      isDarkMode?: boolean | null;
      isEmailPopupModalEnabled?: boolean | null;
      featuresOnSignUpPage?: unknown | null;
      emailPopupModalCloudinaryUrl?: string | null;
      googleAnalyticsId?: string | null;
      title?: string | null;
      headTagContent?: string | null;
      signUpPageTermsAndConditions?: string | null;
      cookieBanner?: string | null;
      publishedAt?: string | null;
      showWebinarBanner?: boolean | null;
      isCustomFooterEnabled?: boolean | null;
      footerLogoCloudinaryUrl?: string | null;
      description?: unknown | null;
      address?: string | null;
      telephone?: string | null;
      email?: string | null;
      facebookUrl?: string | null;
      linkedinUrl?: string | null;
      twitterUrl?: string | null;
      instagramUrl?: string | null;
      isSitemapEnabled?: boolean | null;
      sitemapLinkGroups?: Array<unknown | null> | null;
      badges?: Array<unknown> | null;
      isNewsletterSubscriptionEnabled?: boolean | null;
      newsletterSubscription?: unknown | null;
      footerBackgroundColor?: string | null;
      footerTextColor?: string | null;
      footerButtonBackgroundColor?: string | null;
      footerButtonTextColor?: string | null;
      parentNavMenuItems?: Array<{
        __typename?: 'NavMenuItem';
        id: string;
        label: string;
        url?: string | null;
        position: number;
        openInNewTab: boolean;
        page?: {
          __typename?: 'Page';
          id: string;
          title: string;
          slug: string;
        } | null;
        children?: Array<{
          __typename?: 'NavMenuItem';
          id: string;
          label: string;
          url?: string | null;
          position: number;
          openInNewTab: boolean;
          page?: {
            __typename?: 'Page';
            id: string;
            title: string;
            slug: string;
          } | null;
        } | null> | null;
      } | null> | null;
      currentPage?: {
        __typename?: 'Page';
        id: string;
        title: string;
        slug: string;
        description?: string | null;
        isDraft: boolean;
        metaDescription?: string | null;
        socialImageCloudinaryPublicId?: string | null;
        blocks?: Array<{
          __typename?: 'Block';
          id: string;
          type: string;
          position: number;
          content?: unknown | null;
          insertedAt: string;
          updatedAt: string;
        } | null> | null;
      } | null;
    } | null;
    unpublishedWebsite?: {
      __typename?: 'Website';
      id: string;
      themeColourPrimary?: string | null;
      themeColourPrimaryText?: string | null;
      themeColourAccent?: string | null;
      themeColourAccentText?: string | null;
      themeFontTitle?: string | null;
      themeFontBody?: string | null;
      logoCloudinaryUrl?: string | null;
      logoSquareCloudinaryUrl?: string | null;
      isFullWebsiteReplacement?: boolean | null;
      isDarkMode?: boolean | null;
      isEmailPopupModalEnabled?: boolean | null;
      featuresOnSignUpPage?: unknown | null;
      emailPopupModalCloudinaryUrl?: string | null;
      googleAnalyticsId?: string | null;
      title?: string | null;
      headTagContent?: string | null;
      signUpPageTermsAndConditions?: string | null;
      cookieBanner?: string | null;
      publishedAt?: string | null;
      isCustomFooterEnabled?: boolean | null;
      footerLogoCloudinaryUrl?: string | null;
      description?: unknown | null;
      address?: string | null;
      telephone?: string | null;
      email?: string | null;
      facebookUrl?: string | null;
      linkedinUrl?: string | null;
      twitterUrl?: string | null;
      instagramUrl?: string | null;
      isSitemapEnabled?: boolean | null;
      sitemapLinkGroups?: Array<unknown | null> | null;
      badges?: Array<unknown> | null;
      isNewsletterSubscriptionEnabled?: boolean | null;
      footerBackgroundColor?: string | null;
      footerTextColor?: string | null;
      footerButtonBackgroundColor?: string | null;
      footerButtonTextColor?: string | null;
      parentNavMenuItems?: Array<{
        __typename?: 'NavMenuItem';
        id: string;
        label: string;
        url?: string | null;
        position: number;
        openInNewTab: boolean;
        page?: {
          __typename?: 'Page';
          id: string;
          title: string;
          slug: string;
        } | null;
        children?: Array<{
          __typename?: 'NavMenuItem';
          id: string;
          label: string;
          url?: string | null;
          position: number;
          openInNewTab: boolean;
          page?: {
            __typename?: 'Page';
            id: string;
            title: string;
            slug: string;
          } | null;
        } | null> | null;
      } | null> | null;
      currentPage?: {
        __typename?: 'Page';
        id: string;
        title: string;
        slug: string;
        description?: string | null;
        isDraft: boolean;
        metaDescription?: string | null;
        socialImageCloudinaryPublicId?: string | null;
        blocks?: Array<{
          __typename?: 'Block';
          id: string;
          type: string;
          position: number;
          content?: unknown | null;
          insertedAt: string;
          updatedAt: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CurrentCustomCorporatePageBySlugQueryVariables = Exact<{
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;

export type CurrentCustomCorporatePageBySlugQuery = {
  __typename?: 'RootQueryType';
  currentCustomCorporatePageBySlug?: {
    __typename?: 'CorporatePage';
    id: string;
    title: string;
    type: CorporatePageType;
    isLive: boolean;
    insertedAt: string;
    updatedAt: string;
    slug: string;
    sections: Array<{
      __typename?: 'Section';
      id: string;
      heading: string;
      description?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        orderId: number;
        heading?: string | null;
        subheading?: string | null;
        description?: string | null;
        fileUrl?: string | null;
      }>;
    }>;
  } | null;
};

export type CurrentInvestorUserUsernameQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentInvestorUserUsernameQuery = {
  __typename?: 'RootQueryType';
  currentInvestorUser?: {
    __typename?: 'InvestorUser';
    id: string;
    email: string;
    firstName?: string | null;
    username?: string | null;
  } | null;
};

export type CurrentInvestorUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentInvestorUserQuery = {
  __typename?: 'RootQueryType';
  currentInvestorUser?: {
    __typename?: 'InvestorUser';
    id: string;
    confirmedAt?: string | null;
    email: string;
    firstName?: string | null;
    hnwIdentifiedAt?: string | null;
    hnwStatus?: InvestorHnwStatuses | null;
    insertedAt: string;
    isSelfNominatedShareholder?: boolean | null;
    selfNominatedShareholderIdentifiedAt?: string | null;
    isHoldingVerified: boolean;
    isCurrentShareholder: boolean;
    showVerifiedBadge?: boolean | null;
    lastName?: string | null;
    username?: string | null;
    certificate?: {
      __typename?: 'Certificate';
      id: string;
      certificateUrl: string;
      expiresAt?: string | null;
      insertedAt: string;
      statDecAcceptedAt?: string | null;
      status: CertificateStatus;
      type?: CertificateType | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      hnwStatus?: ContactHnwStatuses | null;
      commsUnsubscribes: Array<{
        __typename?: 'ContactUnsubscribe';
        id: string;
        scope: ContactUnsubscribeScopeType;
      }>;
      globalUnsubscribe?: {
        __typename?: 'ContactGlobalUnsubscribe';
        id: string;
      } | null;
      investor?: { __typename?: 'InvestorUserSimple'; id: string } | null;
    } | null;
    notificationPreferences: Array<{
      __typename?: 'NotificationPreference';
      id: string;
      channel: InvestorUserNotificationChannel;
      hasEoi: boolean;
      isOn: boolean;
      scope: InvestorUserNotificationScope;
    }>;
    shareholderInformations: Array<{
      __typename?: 'InvestorShareholderInformation';
      id: string;
      country: string;
      partialHin: string;
      postcode?: string | null;
    }>;
    shareholderInformationsUk: Array<{
      __typename?: 'InvestorShareholderInformationUk';
      id: string;
      accountName: string;
      broker: string;
      postcode?: string | null;
      sharesOwned?: number | null;
    }>;
    shareholding?: {
      __typename?: 'Shareholding';
      id: string;
      addressCountry?: string | null;
      addressPostcode?: string | null;
      holderId?: string | null;
    } | null;
    shareholdings: Array<{
      __typename?: 'Shareholding';
      id: string;
      addressCountry?: string | null;
      addressPostcode?: string | null;
      holderId?: string | null;
    }>;
  } | null;
};

export type CurrentWelcomePageQueryVariables = Exact<{
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
}>;

export type CurrentWelcomePageQuery = {
  __typename?: 'RootQueryType';
  currentWelcomePage?: {
    __typename?: 'WelcomePage';
    id: string;
    author: string;
    linkedinProfileUrl?: string | null;
    title: string;
    welcomeMessage: string;
    bannerUrl?: string | null;
    profilePictureUrl?: string | null;
    signatureUrl?: string | null;
  } | null;
};

export type DiscoverBlockAnnouncementsAndUpdatesQueryVariables = Exact<{
  hub: Scalars['String']['input'];
}>;

export type DiscoverBlockAnnouncementsAndUpdatesQuery = {
  __typename?: 'RootQueryType';
  discoverBlockAnnouncementsAndUpdates?: {
    __typename?: 'DiscoverBlockAnnouncementsAndUpdates';
    id: string;
    latestUpdate?: {
      __typename?: 'MediaUpdate';
      id: string;
      postedAt?: string | null;
      title: string;
      includedTypes?: Array<MediaUpdateType> | null;
      slug: string;
      mediaId: string;
      attachments: Array<{
        __typename?: 'MediaUpdateAttachment';
        thumbnailUrl?: string | null;
        url: string;
        title?: string | null;
      }>;
      content?: {
        __typename?: 'MediaUpdateContent';
        content?: string | null;
      } | null;
    } | null;
    latestAnnualReport?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
    latestInvestorPresentation?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
    latestQuarterlyCashflowReport?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
  } | null;
};

export type GetWebinarDataQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetWebinarDataQuery = {
  __typename?: 'RootQueryType';
  webinarData?: {
    __typename?: 'WebinarData';
    webinar?: {
      __typename?: 'Webinar';
      id: string;
      type?: string | null;
      hmsRoomId?: string | null;
      publishedRecordingUrl?: string | null;
      posterImageUrl?: string | null;
      title?: string | null;
      slug: string;
      summary?: unknown | null;
      startTime?: string | null;
      state?: string | null;
      endTime?: string | null;
      startedBroadcastingAt?: string | null;
      stoppedBroadcastingAt?: string | null;
      timezone?: string | null;
      hmsViewerRoomCode?: string | null;
      imageCloudinaryId?: string | null;
      imageOverlayEnabled?: boolean | null;
      allowPreWebinarComments?: boolean | null;
      discoverableOnHub?: boolean | null;
      transcript?: unknown | null;
      transcriptSummary?: unknown | null;
      showTranscriptOnHub?: boolean | null;
      showTranscriptSummaryOnHub?: boolean | null;
      recordingNeedsLogin?: boolean | null;
      attendanceNeedsLogin?: boolean | null;
      documents?: Array<{
        __typename?: 'WebinarDocument';
        id: string;
        cloudinaryId?: string | null;
        fileName?: string | null;
        fileType?: string | null;
        fileSize?: number | null;
        position?: number | null;
      } | null> | null;
    } | null;
    attendee?: {
      __typename?: 'Attendee';
      id: string;
      insertedAt: string;
      viewedRecordingAt?: string | null;
      questions?: Array<{
        __typename?: 'WebinarQuestion';
        id: string;
        content: string;
        updatedAt: string;
      } | null> | null;
    } | null;
  } | null;
};

export type HomepageAnnouncementsAndUpdatesQueryVariables = Exact<{
  hub: Scalars['String']['input'];
}>;

export type HomepageAnnouncementsAndUpdatesQuery = {
  __typename?: 'RootQueryType';
  homepageAnnouncementsAndUpdates?: {
    __typename?: 'HomepageAnnouncementsAndUpdates';
    id: string;
    latestTwoAnnouncements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    }>;
    latestUpdate?: {
      __typename?: 'MediaUpdate';
      id: string;
      postedAt?: string | null;
      title: string;
      includedTypes?: Array<MediaUpdateType> | null;
      slug: string;
      mediaId: string;
      attachments: Array<{
        __typename?: 'MediaUpdateAttachment';
        thumbnailUrl?: string | null;
        url: string;
        title?: string | null;
      }>;
      content?: {
        __typename?: 'MediaUpdateContent';
        content?: string | null;
      } | null;
    } | null;
    latestAnnualReport?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
    latestInvestorPresentation?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
    latestQuarterlyCashflowReport?: {
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      likes: number;
      listingKey: string;
      marketKey: string;
      marketSensitive?: boolean | null;
      mediaId: string;
      postedAt: string;
      publicCommentCount: number;
      rectype: string;
      socialVideoUrl?: string | null;
      subtypes: Array<string | null>;
      summary?: string | null;
      thumbnailIsPortrait?: boolean | null;
      thumbnailUrl?: string | null;
      url: string;
      videoUrl?: string | null;
    } | null;
  } | null;
};

export type HubNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type HubNotificationsQuery = {
  __typename?: 'RootQueryType';
  hubNotifications: Array<{
    __typename?: 'Notification';
    id: string;
    isRead: boolean;
    type: HubNotificationType;
    insertedAt: string;
    updatedAt: string;
    investorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
      isHoldingVerified: boolean;
    } | null;
    hubFollow?: {
      __typename?: 'Follow';
      id: string;
      invalidated: boolean;
      isProfileFollowBack: boolean;
      investorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        username?: string | null;
        isHoldingVerified: boolean;
      } | null;
      followingMedia?: {
        __typename?: 'Media';
        id: string;
        mediaAnnouncement?: {
          __typename?: 'MediaAnnouncement';
          id: string;
          header: string;
        } | null;
        mediaUpdate?: {
          __typename?: 'MediaUpdate';
          id: string;
          title: string;
          slug: string;
        } | null;
      } | null;
      followingInvestorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
      } | null;
      followingMediaComment?: {
        __typename?: 'MediaComment';
        id: string;
        media?: {
          __typename?: 'Media';
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
            header: string;
          } | null;
          mediaUpdate?: {
            __typename?: 'MediaUpdate';
            id: string;
            title: string;
            slug: string;
          } | null;
        } | null;
      } | null;
    } | null;
    hubFeature?: {
      __typename?: 'Feature';
      id: string;
      isActive: boolean;
      buttonLink?: string | null;
      description?: string | null;
    } | null;
    media?: {
      __typename?: 'Media';
      id: string;
      mediaUpdate?: {
        __typename?: 'MediaUpdate';
        id: string;
        title: string;
        slug: string;
      } | null;
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        header: string;
      } | null;
    } | null;
    mediaComment?: {
      __typename?: 'MediaComment';
      id: string;
      media?: {
        __typename?: 'Media';
        mediaAnnouncement?: {
          __typename?: 'MediaAnnouncement';
          id: string;
          header: string;
        } | null;
        mediaUpdate?: {
          __typename?: 'MediaUpdate';
          id: string;
          title: string;
          slug: string;
        } | null;
      } | null;
    } | null;
    mediaCommentLike?: {
      __typename?: 'MediaCommentLike';
      id: string;
      investorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        username?: string | null;
        isHoldingVerified: boolean;
      } | null;
      comment?: {
        __typename?: 'MediaComment';
        id: string;
        media?: {
          __typename?: 'Media';
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
            header: string;
          } | null;
          mediaUpdate?: {
            __typename?: 'MediaUpdate';
            id: string;
            title: string;
            slug: string;
          } | null;
        } | null;
      } | null;
    } | null;
    mediaCommentReply?: {
      __typename?: 'MediaComment';
      id: string;
      useCompanyAsUsername: boolean;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
  }>;
};

export type InvestmentOfferQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvestmentOfferQuery = {
  __typename?: 'RootQueryType';
  investmentOffer: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    publishedAt?: string | null;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    companyShareholderOfferPage?: {
      __typename?: 'ShareholderOfferPage';
      id: string;
      bannerUrl?: string | null;
      closingDate?: string | null;
      interactiveAnnouncementLink?: string | null;
      introductionHeading?: string | null;
      introductionMessage?: string | null;
      investorPresentationName?: string | null;
      investorPresentationUrl?: string | null;
      offerBookletName?: string | null;
      offerBookletUrl?: string | null;
      offerPrice?: string | null;
      raiseApplyInstructionMessage?: string | null;
      raiseDefinitionMessage?: string | null;
      raiseDefinitionTimelineDiagramUrl?: string | null;
      raiseReasonHeroMediaImageUrl?: string | null;
      raiseReasonHeroMediaVideoUrl?: string | null;
      raiseReasonHeroMediaSocialVideoUrl?: string | null;
      raiseReasonMessage?: string | null;
      raisingTarget?: number | null;
      usCitizenPageEnabled?: boolean | null;
      faqs: Array<{
        __typename?: 'Faq';
        answer: string;
        question: string;
        orderId: number;
      }>;
      instructions: Array<{
        __typename?: 'Instruction';
        subheading: string;
        message: string;
        buttonUrl?: string | null;
        buttonText?: string | null;
        orderId: number;
      }>;
    } | null;
  };
};

export type InvestorMediaCommentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
  hub: Scalars['String']['input'];
}>;

export type InvestorMediaCommentsQuery = {
  __typename?: 'RootQueryType';
  investorMediaComments?: {
    __typename?: 'MediaCommentConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaCommentEdge';
      node?: {
        __typename?: 'MediaComment';
        id: string;
        commentSource?: CommentSource | null;
        commentSourceUrl?: string | null;
        content: string;
        followed: boolean;
        useCompanyAsUsername: boolean;
        likes: number;
        liked: boolean;
        insertedAt: string;
        annotationMetadata?: {
          __typename?: 'MediaCommentAnnotationMetadata';
          left: number;
          pageIndex: number;
          top: number;
        } | null;
        investorUser?: {
          __typename?: 'InvestorUserSimple';
          id: string;
          showShareholderBadge: boolean;
          username?: string | null;
        } | null;
        companyAuthor?: {
          __typename?: 'CompanyUser';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        media?: {
          __typename?: 'Media';
          id: string;
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
            header: string;
            totalViewCount: number;
            url: string;
            thumbnailUrl?: string | null;
          } | null;
          mediaUpdate?: {
            __typename?: 'MediaUpdate';
            id: string;
            postedAt?: string | null;
            title: string;
            includedTypes?: Array<MediaUpdateType> | null;
            isPinned?: boolean | null;
            slug: string;
            mediaId: string;
            answeredQuestionCount: number;
            questionCount: number;
            likes: number;
            totalViewCount: number;
            thumbnailAttachment?: {
              __typename?: 'MediaUpdateAttachment';
              thumbnailUrl?: string | null;
              type?: MediaUpdateAttachmentType | null;
              url: string;
              orderId: number;
              title?: string | null;
              thumbnail?: string | null;
              thumbnailIsPortrait?: boolean | null;
            } | null;
            attachments: Array<{
              __typename?: 'MediaUpdateAttachment';
              thumbnailUrl?: string | null;
              url: string;
              title?: string | null;
              orderId: number;
              type?: MediaUpdateAttachmentType | null;
              thumbnail?: string | null;
              thumbnailIsPortrait?: boolean | null;
            }>;
            content?: {
              __typename?: 'MediaUpdateContent';
              content?: string | null;
            } | null;
          } | null;
        } | null;
        children?: Array<{
          __typename?: 'MediaComment';
          id: string;
          likes: number;
          liked: boolean;
          insertedAt: string;
          useCompanyAsUsername: boolean;
          companyAuthor?: {
            __typename?: 'CompanyUser';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          media?: {
            __typename?: 'Media';
            id: string;
            mediaAnnouncement?: {
              __typename?: 'MediaAnnouncement';
              id: string;
              header: string;
              totalViewCount: number;
              url: string;
            } | null;
            mediaUpdate?: {
              __typename?: 'MediaUpdate';
              id: string;
              title: string;
              slug: string;
              totalViewCount: number;
              attachments: Array<{
                __typename?: 'MediaUpdateAttachment';
                url: string;
                orderId: number;
                type?: MediaUpdateAttachmentType | null;
              }>;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type IsUsernameAvailableQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type IsUsernameAvailableQuery = {
  __typename?: 'RootQueryType';
  isUsernameAvailable?: boolean | null;
};

export type LiveCustomCorporatePagesQueryVariables = Exact<{
  listingKey: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
}>;

export type LiveCustomCorporatePagesQuery = {
  __typename?: 'RootQueryType';
  liveCustomCorporatePages: Array<{
    __typename?: 'CorporatePage';
    id: string;
    title: string;
    type: CorporatePageType;
    isLive: boolean;
    insertedAt: string;
    updatedAt: string;
    slug: string;
    sections: Array<{
      __typename?: 'Section';
      id: string;
      heading: string;
      description?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        orderId: number;
        heading?: string | null;
        subheading?: string | null;
        description?: string | null;
        fileUrl?: string | null;
      }>;
    }>;
  }>;
};

export type MediaAnnouncementDateRangeQueryVariables = Exact<{
  ticker: Scalars['String']['input'];
}>;

export type MediaAnnouncementDateRangeQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncementDateRange?: {
    __typename?: 'IsoNaiveDatetimeRange';
    oldest?: string | null;
    newest?: string | null;
  } | null;
};

export type MediaAnnouncementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MediaAnnouncementQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
    featuredOnHub: boolean;
    header: string;
    likes: number;
    listingKey: string;
    marketKey: string;
    marketSensitive?: boolean | null;
    mediaId: string;
    newsPublisher?: string | null;
    postedAt: string;
    publicCommentCount: number;
    rectype: string;
    socialVideoUrl?: string | null;
    subtypes: Array<string | null>;
    summary?: string | null;
    summaryAi?: string | null;
    thumbnailIsPortrait?: boolean | null;
    thumbnailUrl?: string | null;
    url: string;
    videoUrl?: string | null;
    germanTranslatedUrl?: string | null;
    germanTranslatedHeader?: string | null;
    totalViewCount: number;
    germanTranslatedVideoUrl?: string | null;
    germanTranslatedSummary?: string | null;
  } | null;
};

export type MediaAnnouncementsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
  hub?: InputMaybe<Scalars['String']['input']>;
}>;

export type MediaAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncements?: {
    __typename?: 'MediaAnnouncementConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaAnnouncementEdge';
      node?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        featuredOnHub: boolean;
        header: string;
        likes: number;
        listingKey: string;
        marketKey: string;
        marketSensitive?: boolean | null;
        mediaId: string;
        newsPublisher?: string | null;
        postedAt: string;
        publicCommentCount: number;
        totalParentComments: number;
        totalViewCount: number;
        rectype: string;
        socialVideoUrl?: string | null;
        subtypes: Array<string | null>;
        summary?: string | null;
        thumbnailIsPortrait?: boolean | null;
        thumbnailUrl?: string | null;
        url: string;
        videoUrl?: string | null;
        germanTranslatedUrl?: string | null;
        germanTranslatedHeader?: string | null;
        germanTranslatedVideoUrl?: string | null;
        germanTranslatedSummary?: string | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type MediaCommentsQueryVariables = Exact<{
  isAnnotation?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId: Scalars['ID']['input'];
  hub: Scalars['String']['input'];
}>;

export type MediaCommentsQuery = {
  __typename?: 'RootQueryType';
  mediaComments: Array<{
    __typename?: 'MediaComment';
    id: string;
    insertedAt: string;
    updatedAt: string;
    commentSource?: CommentSource | null;
    commentSourceUrl?: string | null;
    content: string;
    followed: boolean;
    liked: boolean;
    likes: number;
    private: boolean;
    useCompanyAsUsername: boolean;
    annotationMetadata?: {
      __typename?: 'MediaCommentAnnotationMetadata';
      left: number;
      pageIndex: number;
      top: number;
    } | null;
    children?: Array<{
      __typename?: 'MediaComment';
      id: string;
      insertedAt: string;
      updatedAt: string;
      content: string;
      followed: boolean;
      liked: boolean;
      likes: number;
      private: boolean;
      useCompanyAsUsername: boolean;
      annotationMetadata?: {
        __typename?: 'MediaCommentAnnotationMetadata';
        left: number;
        pageIndex: number;
        top: number;
      } | null;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      investorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
        showShareholderBadge: boolean;
        username?: string | null;
      } | null;
    }> | null;
    companyAuthor?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    investorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      showShareholderBadge: boolean;
      username?: string | null;
    } | null;
  }>;
};

export type MediaFollowQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaFollowQuery = {
  __typename?: 'RootQueryType';
  mediaFollow: boolean;
};

export type MediaLikeQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaLikeQuery = {
  __typename?: 'RootQueryType';
  mediaLike: boolean;
};

export type MediaSurveyResultsQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaSurveyResultsQuery = {
  __typename?: 'RootQueryType';
  mediaSurveyResults: Array<{
    __typename?: 'MediaSurveyResult';
    investorAnswer?: MediaSurveyAnswerType | null;
    question: MediaSurveyQuestionType;
    totalResponses: number;
    individualAnswers?: Array<{
      __typename?: 'MediaSurveyAnswerCount';
      answer: MediaSurveyAnswerType;
      count: number;
    } | null> | null;
  }>;
};

export type MediaUpdateBySlugQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;

export type MediaUpdateBySlugQuery = {
  __typename?: 'RootQueryType';
  mediaUpdateBySlug?: {
    __typename?: 'MediaUpdate';
    id: string;
    postedAt?: string | null;
    title: string;
    includedTypes?: Array<MediaUpdateType> | null;
    slug: string;
    mediaId: string;
    likes: number;
    answeredQuestionCount: number;
    questionCount: number;
    totalViewCount: number;
    isDraft: boolean;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      thumbnailUrl?: string | null;
      url: string;
      title?: string | null;
      description?: string | null;
      orderId: number;
      type?: MediaUpdateAttachmentType | null;
    }>;
    content?: {
      __typename?: 'MediaUpdateContent';
      content?: string | null;
    } | null;
  } | null;
};

export type MediaUpdateDateRangeQueryVariables = Exact<{
  hub: Scalars['String']['input'];
}>;

export type MediaUpdateDateRangeQuery = {
  __typename?: 'RootQueryType';
  mediaUpdateDateRange?: {
    __typename?: 'IsoNaiveDatetimeRange';
    oldest?: string | null;
    newest?: string | null;
  } | null;
};

export type MediaUpdatesQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type MediaUpdatesQuery = {
  __typename?: 'RootQueryType';
  mediaUpdates?: {
    __typename?: 'MediaUpdateConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaUpdateEdge';
      node?: {
        __typename?: 'MediaUpdate';
        id: string;
        postedAt?: string | null;
        title: string;
        includedTypes?: Array<MediaUpdateType> | null;
        isPinned?: boolean | null;
        slug: string;
        mediaId: string;
        answeredQuestionCount: number;
        questionCount: number;
        likes: number;
        totalViewCount: number;
        thumbnailAttachment?: {
          __typename?: 'MediaUpdateAttachment';
          thumbnailUrl?: string | null;
          type?: MediaUpdateAttachmentType | null;
          url: string;
          orderId: number;
          title?: string | null;
          thumbnail?: string | null;
          thumbnailIsPortrait?: boolean | null;
        } | null;
        attachments: Array<{
          __typename?: 'MediaUpdateAttachment';
          thumbnailUrl?: string | null;
          url: string;
          title?: string | null;
          orderId: number;
          type?: MediaUpdateAttachmentType | null;
          thumbnail?: string | null;
          thumbnailIsPortrait?: boolean | null;
        }>;
        content?: {
          __typename?: 'MediaUpdateContent';
          content?: string | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type PageBySlugQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;

export type PageBySlugQuery = {
  __typename?: 'RootQueryType';
  pageBySlug?: {
    __typename?: 'Page';
    id: string;
    title: string;
    slug: string;
    description?: string | null;
    isDraft: boolean;
    metaDescription?: string | null;
    socialImageCloudinaryPublicId?: string | null;
    blocks?: Array<{
      __typename?: 'Block';
      id: string;
      type: string;
      position: number;
      content?: unknown | null;
      insertedAt: string;
      updatedAt: string;
    } | null> | null;
  } | null;
};

export type PreparedAnnouncementQueryVariables = Exact<{
  host: Scalars['String']['input'];
  hashid: Scalars['String']['input'];
}>;

export type PreparedAnnouncementQuery = {
  __typename?: 'RootQueryType';
  preparedAnnouncement?: {
    __typename?: 'PreparedAnnouncement';
    mediaAnnouncement?: { __typename?: 'MediaAnnouncement'; id: string } | null;
  } | null;
};

export type RefinitivTokenQueryVariables = Exact<{ [key: string]: never }>;

export type RefinitivTokenQuery = {
  __typename?: 'RootQueryType';
  token?: { __typename?: 'Token'; value: string } | null;
};

export type SuggestedUsernameQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestedUsernameQuery = {
  __typename?: 'RootQueryType';
  suggestedUsername?: string | null;
};

export type TotalFeaturedAnnouncementsQueryVariables = Exact<{
  hub?: InputMaybe<Scalars['String']['input']>;
}>;

export type TotalFeaturedAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  totalFeaturedAnnouncements: number;
};

export type TotalMediaLikesQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
  hub?: InputMaybe<Scalars['String']['input']>;
}>;

export type TotalMediaLikesQuery = {
  __typename?: 'RootQueryType';
  totalMediaLikes: number;
};

export type UserCommentsQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type UserCommentsQuery = {
  __typename?: 'RootQueryType';
  userComments: Array<{
    __typename?: 'MediaComment';
    id: string;
    insertedAt: string;
    updatedAt: string;
    commentSource?: CommentSource | null;
    commentSourceUrl?: string | null;
    content: string;
    followed: boolean;
    liked: boolean;
    likes: number;
    private: boolean;
    useCompanyAsUsername: boolean;
    media?: {
      __typename?: 'Media';
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        header: string;
        summary?: string | null;
        socialVideoUrl?: string | null;
        videoUrl?: string | null;
        marketSensitive?: boolean | null;
      } | null;
      mediaUpdate?: {
        __typename?: 'MediaUpdate';
        id: string;
        title: string;
        slug: string;
        includedTypes?: Array<MediaUpdateType> | null;
      } | null;
    } | null;
    annotationMetadata?: {
      __typename?: 'MediaCommentAnnotationMetadata';
      left: number;
      pageIndex: number;
      top: number;
    } | null;
    children?: Array<{
      __typename?: 'MediaComment';
      id: string;
      insertedAt: string;
      updatedAt: string;
      commentSource?: CommentSource | null;
      commentSourceUrl?: string | null;
      content: string;
      followed: boolean;
      liked: boolean;
      likes: number;
      private: boolean;
      useCompanyAsUsername: boolean;
      annotationMetadata?: {
        __typename?: 'MediaCommentAnnotationMetadata';
        left: number;
        pageIndex: number;
        top: number;
      } | null;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      investorUser?: {
        __typename?: 'InvestorUserSimple';
        id: string;
        showShareholderBadge: boolean;
        username?: string | null;
      } | null;
    }> | null;
    companyAuthor?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    investorUser?: {
      __typename?: 'InvestorUserSimple';
      id: string;
      showShareholderBadge: boolean;
      username?: string | null;
    } | null;
    parent?: { __typename?: 'MediaComment'; id: string } | null;
  }>;
};

export type UserLikesQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type UserLikesQuery = {
  __typename?: 'RootQueryType';
  userLikes: Array<{
    __typename?: 'UserLike';
    id: string;
    commentLike?: {
      __typename?: 'UserCommentLike';
      id: string;
      comment?: {
        __typename?: 'MediaComment';
        id: string;
        insertedAt: string;
        updatedAt: string;
        commentSource?: CommentSource | null;
        commentSourceUrl?: string | null;
        content: string;
        liked: boolean;
        likes: number;
        private: boolean;
        annotationMetadata?: {
          __typename?: 'MediaCommentAnnotationMetadata';
          left: number;
          pageIndex: number;
          top: number;
        } | null;
        media?: {
          __typename?: 'Media';
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
            header: string;
            summary?: string | null;
            socialVideoUrl?: string | null;
            videoUrl?: string | null;
            marketSensitive?: boolean | null;
          } | null;
          mediaUpdate?: {
            __typename?: 'MediaUpdate';
            id: string;
            title: string;
            slug: string;
            includedTypes?: Array<MediaUpdateType> | null;
          } | null;
        } | null;
        companyAuthor?: {
          __typename?: 'CompanyUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        investorUser?: {
          __typename?: 'InvestorUserSimple';
          id: string;
          showShareholderBadge: boolean;
          username?: string | null;
        } | null;
        parent?: { __typename?: 'MediaComment'; id: string } | null;
      } | null;
    } | null;
    mediaLike?: {
      __typename?: 'UserMediaLike';
      id: string;
      media?: {
        __typename?: 'Media';
        id: string;
        liked: boolean;
        likesCount: number;
        mediaAnnouncement?: {
          __typename?: 'MediaAnnouncement';
          id: string;
          header: string;
          summary?: string | null;
          videoUrl?: string | null;
          socialVideoUrl?: string | null;
          postedAt: string;
        } | null;
        mediaUpdate?: {
          __typename?: 'MediaUpdate';
          id: string;
          slug: string;
          title: string;
          includedTypes?: Array<MediaUpdateType> | null;
          postedAt?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type UserProfileFollowQueryVariables = Exact<{
  investorUserId: Scalars['ID']['input'];
}>;

export type UserProfileFollowQuery = {
  __typename?: 'RootQueryType';
  userProfileFollow: boolean;
};

export type UserProfileStatsQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type UserProfileStatsQuery = {
  __typename?: 'RootQueryType';
  userProfileStats?: {
    __typename?: 'UserProfileStats';
    id: string;
    replies: number;
    showShareholderBadge?: boolean | null;
    joined: string;
    likes: number;
    questionsAsked: number;
    surveyResponses: number;
    username: string;
  } | null;
};

export type UserSurveysQueryVariables = Exact<{
  hub: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type UserSurveysQuery = {
  __typename?: 'RootQueryType';
  userSurveys: Array<{
    __typename?: 'UserSurveyResult';
    mediaId: string;
    media: {
      __typename?: 'Media';
      id: string;
      liked: boolean;
      likesCount: number;
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        header: string;
        summary?: string | null;
        videoUrl?: string | null;
        socialVideoUrl?: string | null;
        postedAt: string;
      } | null;
      mediaUpdate?: {
        __typename?: 'MediaUpdate';
        id: string;
        slug: string;
        title: string;
        includedTypes?: Array<MediaUpdateType> | null;
        postedAt?: string | null;
      } | null;
    };
    userResponses: Array<{
      __typename?: 'UserSurveyResultResponses';
      answer: MediaSurveyAnswerType;
      question: MediaSurveyQuestionType;
      totalRespondents: number;
    }>;
  }>;
};

export type UtmLinkFetchByHashQueryVariables = Exact<{
  hash: Scalars['String']['input'];
}>;

export type UtmLinkFetchByHashQuery = {
  __typename?: 'RootQueryType';
  utmLinkFetchByHash?: { __typename?: 'UtmLink'; utmUrl: string } | null;
};

export type WebinarsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type WebinarsQuery = {
  __typename?: 'RootQueryType';
  webinars?: {
    __typename?: 'WebinarConnection';
    total: number;
    edges?: Array<{
      __typename?: 'WebinarEdge';
      cursor?: string | null;
      node?: {
        __typename?: 'Webinar';
        id: string;
        hmsRoomId?: string | null;
        title?: string | null;
        slug: string;
        summary?: unknown | null;
        startTime?: string | null;
        endTime?: string | null;
        startedBroadcastingAt?: string | null;
        stoppedBroadcastingAt?: string | null;
        timezone?: string | null;
        imageCloudinaryId?: string | null;
        imageOverlayEnabled?: boolean | null;
        posterImageUrl?: string | null;
        publishedRecordingUrl?: string | null;
        mediaUpdate?: {
          __typename?: 'MediaUpdate';
          id: string;
          title: string;
        } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export const AnswerMediaSurveyDocument = gql`
  mutation AnswerMediaSurvey(
    $answer: MediaSurveyAnswerType!
    $mediaId: ID!
    $question: MediaSurveyQuestionType!
  ) {
    answerMediaSurvey(answer: $answer, mediaId: $mediaId, question: $question) {
      id
      answer
      question
    }
  }
`;
export type AnswerMediaSurveyMutationFn = Apollo.MutationFunction<
  AnswerMediaSurveyMutation,
  AnswerMediaSurveyMutationVariables
>;

/**
 * __useAnswerMediaSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerMediaSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerMediaSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerMediaSurveyMutation, { data, loading, error }] = useAnswerMediaSurveyMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      mediaId: // value for 'mediaId'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAnswerMediaSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerMediaSurveyMutation,
    AnswerMediaSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerMediaSurveyMutation,
    AnswerMediaSurveyMutationVariables
  >(AnswerMediaSurveyDocument, options);
}
export type AnswerMediaSurveyMutationHookResult = ReturnType<
  typeof useAnswerMediaSurveyMutation
>;
export type AnswerMediaSurveyMutationResult =
  Apollo.MutationResult<AnswerMediaSurveyMutation>;
export type AnswerMediaSurveyMutationOptions = Apollo.BaseMutationOptions<
  AnswerMediaSurveyMutation,
  AnswerMediaSurveyMutationVariables
>;
export const AutoUnblockCloudIpDocument = gql`
  mutation AutoUnblockCloudIp {
    autoUnblockCloudIp
  }
`;
export type AutoUnblockCloudIpMutationFn = Apollo.MutationFunction<
  AutoUnblockCloudIpMutation,
  AutoUnblockCloudIpMutationVariables
>;

/**
 * __useAutoUnblockCloudIpMutation__
 *
 * To run a mutation, you first call `useAutoUnblockCloudIpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoUnblockCloudIpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoUnblockCloudIpMutation, { data, loading, error }] = useAutoUnblockCloudIpMutation({
 *   variables: {
 *   },
 * });
 */
export function useAutoUnblockCloudIpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutoUnblockCloudIpMutation,
    AutoUnblockCloudIpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AutoUnblockCloudIpMutation,
    AutoUnblockCloudIpMutationVariables
  >(AutoUnblockCloudIpDocument, options);
}
export type AutoUnblockCloudIpMutationHookResult = ReturnType<
  typeof useAutoUnblockCloudIpMutation
>;
export type AutoUnblockCloudIpMutationResult =
  Apollo.MutationResult<AutoUnblockCloudIpMutation>;
export type AutoUnblockCloudIpMutationOptions = Apollo.BaseMutationOptions<
  AutoUnblockCloudIpMutation,
  AutoUnblockCloudIpMutationVariables
>;
export const ContactUsDocument = gql`
  mutation ContactUs($message: String!, $type: String!) {
    contactUs(message: $message, type: $type)
  }
`;
export type ContactUsMutationFn = Apollo.MutationFunction<
  ContactUsMutation,
  ContactUsMutationVariables
>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      message: // value for 'message'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useContactUsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactUsMutation,
    ContactUsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(
    ContactUsDocument,
    options
  );
}
export type ContactUsMutationHookResult = ReturnType<
  typeof useContactUsMutation
>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<
  ContactUsMutation,
  ContactUsMutationVariables
>;
export const CreateMediaCommentDocument = gql`
  mutation CreateMediaComment(
    $annotationMetadata: MediaCommentAnnotationMetadataInput
    $content: String!
    $mediaId: ID!
    $hub: String!
  ) {
    createMediaComment(
      annotationMetadata: $annotationMetadata
      content: $content
      mediaId: $mediaId
      hub: $hub
    ) {
      id
      insertedAt
      updatedAt
      annotationMetadata {
        left
        pageIndex
        top
      }
      useCompanyAsUsername
      commentSourceUrl
      children {
        id
        insertedAt
        updatedAt
        annotationMetadata {
          left
          pageIndex
          top
        }
        commentSourceUrl
        companyAuthor {
          id
          firstName
          lastName
        }
        content
        investorUser {
          id
          showShareholderBadge
          username
        }
        followed
        liked
        likes
        private
        useCompanyAsUsername
      }
      companyAuthor {
        id
        firstName
        lastName
      }
      content
      investorUser {
        id
        showShareholderBadge
        username
      }
      followed
      liked
      likes
      private
    }
  }
`;
export type CreateMediaCommentMutationFn = Apollo.MutationFunction<
  CreateMediaCommentMutation,
  CreateMediaCommentMutationVariables
>;

/**
 * __useCreateMediaCommentMutation__
 *
 * To run a mutation, you first call `useCreateMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaCommentMutation, { data, loading, error }] = useCreateMediaCommentMutation({
 *   variables: {
 *      annotationMetadata: // value for 'annotationMetadata'
 *      content: // value for 'content'
 *      mediaId: // value for 'mediaId'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useCreateMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMediaCommentMutation,
    CreateMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMediaCommentMutation,
    CreateMediaCommentMutationVariables
  >(CreateMediaCommentDocument, options);
}
export type CreateMediaCommentMutationHookResult = ReturnType<
  typeof useCreateMediaCommentMutation
>;
export type CreateMediaCommentMutationResult =
  Apollo.MutationResult<CreateMediaCommentMutation>;
export type CreateMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaCommentMutation,
  CreateMediaCommentMutationVariables
>;
export const CreateOrUpdateWebinarViewDocument = gql`
  mutation CreateOrUpdateWebinarView(
    $webinarId: ID!
    $visitorCookieId: String!
    $watchTimeSeconds: Int
    $isMobile: Boolean
    $userAgent: String
    $attendeeId: ID
  ) {
    createOrUpdateWebinarView(
      webinarId: $webinarId
      watchTimeSeconds: $watchTimeSeconds
      visitorCookieId: $visitorCookieId
      isMobile: $isMobile
      userAgent: $userAgent
      attendeeId: $attendeeId
    ) {
      id
      watchTimeSeconds
      visitorCookieId
    }
  }
`;
export type CreateOrUpdateWebinarViewMutationFn = Apollo.MutationFunction<
  CreateOrUpdateWebinarViewMutation,
  CreateOrUpdateWebinarViewMutationVariables
>;

/**
 * __useCreateOrUpdateWebinarViewMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateWebinarViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateWebinarViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateWebinarViewMutation, { data, loading, error }] = useCreateOrUpdateWebinarViewMutation({
 *   variables: {
 *      webinarId: // value for 'webinarId'
 *      visitorCookieId: // value for 'visitorCookieId'
 *      watchTimeSeconds: // value for 'watchTimeSeconds'
 *      isMobile: // value for 'isMobile'
 *      userAgent: // value for 'userAgent'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useCreateOrUpdateWebinarViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateWebinarViewMutation,
    CreateOrUpdateWebinarViewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateWebinarViewMutation,
    CreateOrUpdateWebinarViewMutationVariables
  >(CreateOrUpdateWebinarViewDocument, options);
}
export type CreateOrUpdateWebinarViewMutationHookResult = ReturnType<
  typeof useCreateOrUpdateWebinarViewMutation
>;
export type CreateOrUpdateWebinarViewMutationResult =
  Apollo.MutationResult<CreateOrUpdateWebinarViewMutation>;
export type CreateOrUpdateWebinarViewMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateWebinarViewMutation,
    CreateOrUpdateWebinarViewMutationVariables
  >;
export const CreateTrackingEventDocument = gql`
  mutation CreateTrackingEvent($trackingEvent: TrackingEventInput!) {
    createTrackingEvent(trackingEvent: $trackingEvent) {
      id
    }
  }
`;
export type CreateTrackingEventMutationFn = Apollo.MutationFunction<
  CreateTrackingEventMutation,
  CreateTrackingEventMutationVariables
>;

/**
 * __useCreateTrackingEventMutation__
 *
 * To run a mutation, you first call `useCreateTrackingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrackingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrackingEventMutation, { data, loading, error }] = useCreateTrackingEventMutation({
 *   variables: {
 *      trackingEvent: // value for 'trackingEvent'
 *   },
 * });
 */
export function useCreateTrackingEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrackingEventMutation,
    CreateTrackingEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrackingEventMutation,
    CreateTrackingEventMutationVariables
  >(CreateTrackingEventDocument, options);
}
export type CreateTrackingEventMutationHookResult = ReturnType<
  typeof useCreateTrackingEventMutation
>;
export type CreateTrackingEventMutationResult =
  Apollo.MutationResult<CreateTrackingEventMutation>;
export type CreateTrackingEventMutationOptions = Apollo.BaseMutationOptions<
  CreateTrackingEventMutation,
  CreateTrackingEventMutationVariables
>;
export const DeleteCertificateDocument = gql`
  mutation DeleteCertificate {
    deleteCertificate
  }
`;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;

/**
 * __useDeleteCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateMutation, { data, loading, error }] = useDeleteCertificateMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateMutation,
    DeleteCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCertificateMutation,
    DeleteCertificateMutationVariables
  >(DeleteCertificateDocument, options);
}
export type DeleteCertificateMutationHookResult = ReturnType<
  typeof useDeleteCertificateMutation
>;
export type DeleteCertificateMutationResult =
  Apollo.MutationResult<DeleteCertificateMutation>;
export type DeleteCertificateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;
export const DownloadWebinarDocumentDocument = gql`
  mutation DownloadWebinarDocument($documentId: ID!) {
    downloadWebinarDocument(documentId: $documentId) {
      id
      fileName
      fileType
      fileSize
      cloudinaryId
    }
  }
`;
export type DownloadWebinarDocumentMutationFn = Apollo.MutationFunction<
  DownloadWebinarDocumentMutation,
  DownloadWebinarDocumentMutationVariables
>;

/**
 * __useDownloadWebinarDocumentMutation__
 *
 * To run a mutation, you first call `useDownloadWebinarDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadWebinarDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadWebinarDocumentMutation, { data, loading, error }] = useDownloadWebinarDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDownloadWebinarDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadWebinarDocumentMutation,
    DownloadWebinarDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DownloadWebinarDocumentMutation,
    DownloadWebinarDocumentMutationVariables
  >(DownloadWebinarDocumentDocument, options);
}
export type DownloadWebinarDocumentMutationHookResult = ReturnType<
  typeof useDownloadWebinarDocumentMutation
>;
export type DownloadWebinarDocumentMutationResult =
  Apollo.MutationResult<DownloadWebinarDocumentMutation>;
export type DownloadWebinarDocumentMutationOptions = Apollo.BaseMutationOptions<
  DownloadWebinarDocumentMutation,
  DownloadWebinarDocumentMutationVariables
>;
export const InvalidateMediaCommentDocument = gql`
  mutation InvalidateMediaComment($mediaCommentId: ID!) {
    invalidateMediaComment(mediaCommentId: $mediaCommentId) {
      id
    }
  }
`;
export type InvalidateMediaCommentMutationFn = Apollo.MutationFunction<
  InvalidateMediaCommentMutation,
  InvalidateMediaCommentMutationVariables
>;

/**
 * __useInvalidateMediaCommentMutation__
 *
 * To run a mutation, you first call `useInvalidateMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateMediaCommentMutation, { data, loading, error }] = useInvalidateMediaCommentMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *   },
 * });
 */
export function useInvalidateMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvalidateMediaCommentMutation,
    InvalidateMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvalidateMediaCommentMutation,
    InvalidateMediaCommentMutationVariables
  >(InvalidateMediaCommentDocument, options);
}
export type InvalidateMediaCommentMutationHookResult = ReturnType<
  typeof useInvalidateMediaCommentMutation
>;
export type InvalidateMediaCommentMutationResult =
  Apollo.MutationResult<InvalidateMediaCommentMutation>;
export type InvalidateMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  InvalidateMediaCommentMutation,
  InvalidateMediaCommentMutationVariables
>;
export const MarkNotificationAsReadDocument = gql`
  mutation MarkNotificationAsRead($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId)
  }
`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MarkNotificationAsReadDocument, options);
}
export type MarkNotificationAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationAsReadMutation
>;
export type MarkNotificationAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;
export const ReactToMediaCommentDocument = gql`
  mutation ReactToMediaComment($like: Boolean!, $mediaCommentId: ID!) {
    reactToMediaComment(like: $like, mediaCommentId: $mediaCommentId) {
      id
      like
    }
  }
`;
export type ReactToMediaCommentMutationFn = Apollo.MutationFunction<
  ReactToMediaCommentMutation,
  ReactToMediaCommentMutationVariables
>;

/**
 * __useReactToMediaCommentMutation__
 *
 * To run a mutation, you first call `useReactToMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactToMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactToMediaCommentMutation, { data, loading, error }] = useReactToMediaCommentMutation({
 *   variables: {
 *      like: // value for 'like'
 *      mediaCommentId: // value for 'mediaCommentId'
 *   },
 * });
 */
export function useReactToMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactToMediaCommentMutation,
    ReactToMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactToMediaCommentMutation,
    ReactToMediaCommentMutationVariables
  >(ReactToMediaCommentDocument, options);
}
export type ReactToMediaCommentMutationHookResult = ReturnType<
  typeof useReactToMediaCommentMutation
>;
export type ReactToMediaCommentMutationResult =
  Apollo.MutationResult<ReactToMediaCommentMutation>;
export type ReactToMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  ReactToMediaCommentMutation,
  ReactToMediaCommentMutationVariables
>;
export const ReactToMediaDocument = gql`
  mutation ReactToMedia($mediaId: ID!, $like: Boolean!) {
    reactToMedia(mediaId: $mediaId, like: $like) {
      id
      like
    }
  }
`;
export type ReactToMediaMutationFn = Apollo.MutationFunction<
  ReactToMediaMutation,
  ReactToMediaMutationVariables
>;

/**
 * __useReactToMediaMutation__
 *
 * To run a mutation, you first call `useReactToMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactToMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactToMediaMutation, { data, loading, error }] = useReactToMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      like: // value for 'like'
 *   },
 * });
 */
export function useReactToMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactToMediaMutation,
    ReactToMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactToMediaMutation,
    ReactToMediaMutationVariables
  >(ReactToMediaDocument, options);
}
export type ReactToMediaMutationHookResult = ReturnType<
  typeof useReactToMediaMutation
>;
export type ReactToMediaMutationResult =
  Apollo.MutationResult<ReactToMediaMutation>;
export type ReactToMediaMutationOptions = Apollo.BaseMutationOptions<
  ReactToMediaMutation,
  ReactToMediaMutationVariables
>;
export const RegisterForWebinarDocument = gql`
  mutation RegisterForWebinar($webinarId: ID!) {
    registerForWebinar(webinarId: $webinarId) {
      id
      insertedAt
    }
  }
`;
export type RegisterForWebinarMutationFn = Apollo.MutationFunction<
  RegisterForWebinarMutation,
  RegisterForWebinarMutationVariables
>;

/**
 * __useRegisterForWebinarMutation__
 *
 * To run a mutation, you first call `useRegisterForWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForWebinarMutation, { data, loading, error }] = useRegisterForWebinarMutation({
 *   variables: {
 *      webinarId: // value for 'webinarId'
 *   },
 * });
 */
export function useRegisterForWebinarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterForWebinarMutation,
    RegisterForWebinarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterForWebinarMutation,
    RegisterForWebinarMutationVariables
  >(RegisterForWebinarDocument, options);
}
export type RegisterForWebinarMutationHookResult = ReturnType<
  typeof useRegisterForWebinarMutation
>;
export type RegisterForWebinarMutationResult =
  Apollo.MutationResult<RegisterForWebinarMutation>;
export type RegisterForWebinarMutationOptions = Apollo.BaseMutationOptions<
  RegisterForWebinarMutation,
  RegisterForWebinarMutationVariables
>;
export const ResendConfirmUserDocument = gql`
  mutation ResendConfirmUser(
    $captchaToken: String!
    $confirmationLink: Boolean
  ) {
    resendConfirmUser(
      captchaToken: $captchaToken
      confirmationLink: $confirmationLink
    )
  }
`;
export type ResendConfirmUserMutationFn = Apollo.MutationFunction<
  ResendConfirmUserMutation,
  ResendConfirmUserMutationVariables
>;

/**
 * __useResendConfirmUserMutation__
 *
 * To run a mutation, you first call `useResendConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmUserMutation, { data, loading, error }] = useResendConfirmUserMutation({
 *   variables: {
 *      captchaToken: // value for 'captchaToken'
 *      confirmationLink: // value for 'confirmationLink'
 *   },
 * });
 */
export function useResendConfirmUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendConfirmUserMutation,
    ResendConfirmUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendConfirmUserMutation,
    ResendConfirmUserMutationVariables
  >(ResendConfirmUserDocument, options);
}
export type ResendConfirmUserMutationHookResult = ReturnType<
  typeof useResendConfirmUserMutation
>;
export type ResendConfirmUserMutationResult =
  Apollo.MutationResult<ResendConfirmUserMutation>;
export type ResendConfirmUserMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmUserMutation,
  ResendConfirmUserMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $password: String!
    $passwordConfirmation: String!
    $token: String
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
    )
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SelfNominateAsHnwDocument = gql`
  mutation SelfNominateAsHNW($isHnw: Boolean!) {
    selfNominateAsHnw(isHnw: $isHnw)
  }
`;
export type SelfNominateAsHnwMutationFn = Apollo.MutationFunction<
  SelfNominateAsHnwMutation,
  SelfNominateAsHnwMutationVariables
>;

/**
 * __useSelfNominateAsHnwMutation__
 *
 * To run a mutation, you first call `useSelfNominateAsHnwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfNominateAsHnwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfNominateAsHnwMutation, { data, loading, error }] = useSelfNominateAsHnwMutation({
 *   variables: {
 *      isHnw: // value for 'isHnw'
 *   },
 * });
 */
export function useSelfNominateAsHnwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelfNominateAsHnwMutation,
    SelfNominateAsHnwMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelfNominateAsHnwMutation,
    SelfNominateAsHnwMutationVariables
  >(SelfNominateAsHnwDocument, options);
}
export type SelfNominateAsHnwMutationHookResult = ReturnType<
  typeof useSelfNominateAsHnwMutation
>;
export type SelfNominateAsHnwMutationResult =
  Apollo.MutationResult<SelfNominateAsHnwMutation>;
export type SelfNominateAsHnwMutationOptions = Apollo.BaseMutationOptions<
  SelfNominateAsHnwMutation,
  SelfNominateAsHnwMutationVariables
>;
export const SelfNominateAsShareholderOrHnwDocument = gql`
  mutation SelfNominateAsShareholderOrHnw(
    $isHnw: Boolean
    $isShareholder: Boolean
  ) {
    selfNominateAsShareholderOrHnw(isHnw: $isHnw, isShareholder: $isShareholder)
  }
`;
export type SelfNominateAsShareholderOrHnwMutationFn = Apollo.MutationFunction<
  SelfNominateAsShareholderOrHnwMutation,
  SelfNominateAsShareholderOrHnwMutationVariables
>;

/**
 * __useSelfNominateAsShareholderOrHnwMutation__
 *
 * To run a mutation, you first call `useSelfNominateAsShareholderOrHnwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfNominateAsShareholderOrHnwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfNominateAsShareholderOrHnwMutation, { data, loading, error }] = useSelfNominateAsShareholderOrHnwMutation({
 *   variables: {
 *      isHnw: // value for 'isHnw'
 *      isShareholder: // value for 'isShareholder'
 *   },
 * });
 */
export function useSelfNominateAsShareholderOrHnwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelfNominateAsShareholderOrHnwMutation,
    SelfNominateAsShareholderOrHnwMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelfNominateAsShareholderOrHnwMutation,
    SelfNominateAsShareholderOrHnwMutationVariables
  >(SelfNominateAsShareholderOrHnwDocument, options);
}
export type SelfNominateAsShareholderOrHnwMutationHookResult = ReturnType<
  typeof useSelfNominateAsShareholderOrHnwMutation
>;
export type SelfNominateAsShareholderOrHnwMutationResult =
  Apollo.MutationResult<SelfNominateAsShareholderOrHnwMutation>;
export type SelfNominateAsShareholderOrHnwMutationOptions =
  Apollo.BaseMutationOptions<
    SelfNominateAsShareholderOrHnwMutation,
    SelfNominateAsShareholderOrHnwMutationVariables
  >;
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($companyProfileId: String!, $email: String!) {
    sendResetPassword(companyProfileId: $companyProfileId, email: $email)
  }
`;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      companyProfileId: // value for 'companyProfileId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options);
}
export type SendResetPasswordMutationHookResult = ReturnType<
  typeof useSendResetPasswordMutation
>;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const SetUsernameDocument = gql`
  mutation SetUsername($username: String!) {
    setUsername(username: $username) {
      id
      username
    }
  }
`;
export type SetUsernameMutationFn = Apollo.MutationFunction<
  SetUsernameMutation,
  SetUsernameMutationVariables
>;

/**
 * __useSetUsernameMutation__
 *
 * To run a mutation, you first call `useSetUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUsernameMutation, { data, loading, error }] = useSetUsernameMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSetUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUsernameMutation,
    SetUsernameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUsernameMutation, SetUsernameMutationVariables>(
    SetUsernameDocument,
    options
  );
}
export type SetUsernameMutationHookResult = ReturnType<
  typeof useSetUsernameMutation
>;
export type SetUsernameMutationResult =
  Apollo.MutationResult<SetUsernameMutation>;
export type SetUsernameMutationOptions = Apollo.BaseMutationOptions<
  SetUsernameMutation,
  SetUsernameMutationVariables
>;
export const SubscribeContactGloballyDocument = gql`
  mutation SubscribeContactGlobally($contactHashId: String) {
    subscribeContactGlobally(contactHashId: $contactHashId) {
      id
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
    }
  }
`;
export type SubscribeContactGloballyMutationFn = Apollo.MutationFunction<
  SubscribeContactGloballyMutation,
  SubscribeContactGloballyMutationVariables
>;

/**
 * __useSubscribeContactGloballyMutation__
 *
 * To run a mutation, you first call `useSubscribeContactGloballyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeContactGloballyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeContactGloballyMutation, { data, loading, error }] = useSubscribeContactGloballyMutation({
 *   variables: {
 *      contactHashId: // value for 'contactHashId'
 *   },
 * });
 */
export function useSubscribeContactGloballyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeContactGloballyMutation,
    SubscribeContactGloballyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeContactGloballyMutation,
    SubscribeContactGloballyMutationVariables
  >(SubscribeContactGloballyDocument, options);
}
export type SubscribeContactGloballyMutationHookResult = ReturnType<
  typeof useSubscribeContactGloballyMutation
>;
export type SubscribeContactGloballyMutationResult =
  Apollo.MutationResult<SubscribeContactGloballyMutation>;
export type SubscribeContactGloballyMutationOptions =
  Apollo.BaseMutationOptions<
    SubscribeContactGloballyMutation,
    SubscribeContactGloballyMutationVariables
  >;
export const SubscribeContactDocument = gql`
  mutation SubscribeContact(
    $contactHashId: String
    $scope: ContactUnsubscribeScopeType!
  ) {
    subscribeContact(contactHashId: $contactHashId, scope: $scope) {
      id
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
    }
  }
`;
export type SubscribeContactMutationFn = Apollo.MutationFunction<
  SubscribeContactMutation,
  SubscribeContactMutationVariables
>;

/**
 * __useSubscribeContactMutation__
 *
 * To run a mutation, you first call `useSubscribeContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeContactMutation, { data, loading, error }] = useSubscribeContactMutation({
 *   variables: {
 *      contactHashId: // value for 'contactHashId'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useSubscribeContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeContactMutation,
    SubscribeContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeContactMutation,
    SubscribeContactMutationVariables
  >(SubscribeContactDocument, options);
}
export type SubscribeContactMutationHookResult = ReturnType<
  typeof useSubscribeContactMutation
>;
export type SubscribeContactMutationResult =
  Apollo.MutationResult<SubscribeContactMutation>;
export type SubscribeContactMutationOptions = Apollo.BaseMutationOptions<
  SubscribeContactMutation,
  SubscribeContactMutationVariables
>;
export const SubscribeDocument = gql`
  mutation Subscribe($input: SubscribeInput!) {
    subscribe(input: $input)
  }
`;
export type SubscribeMutationFn = Apollo.MutationFunction<
  SubscribeMutation,
  SubscribeMutationVariables
>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeMutation,
    SubscribeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
    options
  );
}
export type SubscribeMutationHookResult = ReturnType<
  typeof useSubscribeMutation
>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<
  SubscribeMutation,
  SubscribeMutationVariables
>;
export const UnsubscribeContactGloballyDocument = gql`
  mutation UnsubscribeContactGlobally(
    $campaignHashId: String
    $contactHashId: String
    $distributedEmailHashId: String
    $emailHashId: String
  ) {
    unsubscribeContactGlobally(
      campaignHashId: $campaignHashId
      contactHashId: $contactHashId
      distributedEmailHashId: $distributedEmailHashId
      emailHashId: $emailHashId
    ) {
      id
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
    }
  }
`;
export type UnsubscribeContactGloballyMutationFn = Apollo.MutationFunction<
  UnsubscribeContactGloballyMutation,
  UnsubscribeContactGloballyMutationVariables
>;

/**
 * __useUnsubscribeContactGloballyMutation__
 *
 * To run a mutation, you first call `useUnsubscribeContactGloballyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeContactGloballyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeContactGloballyMutation, { data, loading, error }] = useUnsubscribeContactGloballyMutation({
 *   variables: {
 *      campaignHashId: // value for 'campaignHashId'
 *      contactHashId: // value for 'contactHashId'
 *      distributedEmailHashId: // value for 'distributedEmailHashId'
 *      emailHashId: // value for 'emailHashId'
 *   },
 * });
 */
export function useUnsubscribeContactGloballyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeContactGloballyMutation,
    UnsubscribeContactGloballyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeContactGloballyMutation,
    UnsubscribeContactGloballyMutationVariables
  >(UnsubscribeContactGloballyDocument, options);
}
export type UnsubscribeContactGloballyMutationHookResult = ReturnType<
  typeof useUnsubscribeContactGloballyMutation
>;
export type UnsubscribeContactGloballyMutationResult =
  Apollo.MutationResult<UnsubscribeContactGloballyMutation>;
export type UnsubscribeContactGloballyMutationOptions =
  Apollo.BaseMutationOptions<
    UnsubscribeContactGloballyMutation,
    UnsubscribeContactGloballyMutationVariables
  >;
export const UnsubscribeContactDocument = gql`
  mutation UnsubscribeContact(
    $campaignHashId: String
    $contactHashId: String
    $distributedEmailHashId: String
    $emailHashId: String
    $scope: ContactUnsubscribeScopeType!
  ) {
    unsubscribeContact(
      campaignHashId: $campaignHashId
      contactHashId: $contactHashId
      distributedEmailHashId: $distributedEmailHashId
      emailHashId: $emailHashId
      scope: $scope
    ) {
      id
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
    }
  }
`;
export type UnsubscribeContactMutationFn = Apollo.MutationFunction<
  UnsubscribeContactMutation,
  UnsubscribeContactMutationVariables
>;

/**
 * __useUnsubscribeContactMutation__
 *
 * To run a mutation, you first call `useUnsubscribeContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeContactMutation, { data, loading, error }] = useUnsubscribeContactMutation({
 *   variables: {
 *      campaignHashId: // value for 'campaignHashId'
 *      contactHashId: // value for 'contactHashId'
 *      distributedEmailHashId: // value for 'distributedEmailHashId'
 *      emailHashId: // value for 'emailHashId'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useUnsubscribeContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeContactMutation,
    UnsubscribeContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeContactMutation,
    UnsubscribeContactMutationVariables
  >(UnsubscribeContactDocument, options);
}
export type UnsubscribeContactMutationHookResult = ReturnType<
  typeof useUnsubscribeContactMutation
>;
export type UnsubscribeContactMutationResult =
  Apollo.MutationResult<UnsubscribeContactMutation>;
export type UnsubscribeContactMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeContactMutation,
  UnsubscribeContactMutationVariables
>;
export const UpdateMediaCommentDocument = gql`
  mutation UpdateMediaComment($content: String!, $mediaCommentId: ID!) {
    updateMediaComment(content: $content, mediaCommentId: $mediaCommentId) {
      id
    }
  }
`;
export type UpdateMediaCommentMutationFn = Apollo.MutationFunction<
  UpdateMediaCommentMutation,
  UpdateMediaCommentMutationVariables
>;

/**
 * __useUpdateMediaCommentMutation__
 *
 * To run a mutation, you first call `useUpdateMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaCommentMutation, { data, loading, error }] = useUpdateMediaCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      mediaCommentId: // value for 'mediaCommentId'
 *   },
 * });
 */
export function useUpdateMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaCommentMutation,
    UpdateMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaCommentMutation,
    UpdateMediaCommentMutationVariables
  >(UpdateMediaCommentDocument, options);
}
export type UpdateMediaCommentMutationHookResult = ReturnType<
  typeof useUpdateMediaCommentMutation
>;
export type UpdateMediaCommentMutationResult =
  Apollo.MutationResult<UpdateMediaCommentMutation>;
export type UpdateMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaCommentMutation,
  UpdateMediaCommentMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      showVerifiedBadge
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UploadCertificateDocument = gql`
  mutation UploadCertificate($certificate: Upload!) {
    uploadCertificate(certificate: $certificate) {
      id
      certificateUrl
      expiresAt
      insertedAt
      statDecAcceptedAt
      status
    }
  }
`;
export type UploadCertificateMutationFn = Apollo.MutationFunction<
  UploadCertificateMutation,
  UploadCertificateMutationVariables
>;

/**
 * __useUploadCertificateMutation__
 *
 * To run a mutation, you first call `useUploadCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCertificateMutation, { data, loading, error }] = useUploadCertificateMutation({
 *   variables: {
 *      certificate: // value for 'certificate'
 *   },
 * });
 */
export function useUploadCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCertificateMutation,
    UploadCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCertificateMutation,
    UploadCertificateMutationVariables
  >(UploadCertificateDocument, options);
}
export type UploadCertificateMutationHookResult = ReturnType<
  typeof useUploadCertificateMutation
>;
export type UploadCertificateMutationResult =
  Apollo.MutationResult<UploadCertificateMutation>;
export type UploadCertificateMutationOptions = Apollo.BaseMutationOptions<
  UploadCertificateMutation,
  UploadCertificateMutationVariables
>;
export const UpsertFollowDocument = gql`
  mutation UpsertFollow($follow: FollowInput!) {
    upsertFollow(follow: $follow) {
      id
      followingInvestorUser {
        id
        firstName
        lastName
        username
      }
      followingMedia {
        id
        mediaAnnouncement {
          id
          header
        }
        mediaUpdate {
          id
          title
        }
      }
      followingMediaComment {
        id
      }
      invalidated
      investorUser {
        id
        firstName
        lastName
        username
      }
    }
  }
`;
export type UpsertFollowMutationFn = Apollo.MutationFunction<
  UpsertFollowMutation,
  UpsertFollowMutationVariables
>;

/**
 * __useUpsertFollowMutation__
 *
 * To run a mutation, you first call `useUpsertFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFollowMutation, { data, loading, error }] = useUpsertFollowMutation({
 *   variables: {
 *      follow: // value for 'follow'
 *   },
 * });
 */
export function useUpsertFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertFollowMutation,
    UpsertFollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertFollowMutation,
    UpsertFollowMutationVariables
  >(UpsertFollowDocument, options);
}
export type UpsertFollowMutationHookResult = ReturnType<
  typeof useUpsertFollowMutation
>;
export type UpsertFollowMutationResult =
  Apollo.MutationResult<UpsertFollowMutation>;
export type UpsertFollowMutationOptions = Apollo.BaseMutationOptions<
  UpsertFollowMutation,
  UpsertFollowMutationVariables
>;
export const UpsertNotificationPreferenceEoiDocument = gql`
  mutation UpsertNotificationPreferenceEoi(
    $notificationPreference: UpsertNotificationPreferenceEoiInput!
  ) {
    upsertNotificationPreferenceEoi(
      notificationPreference: $notificationPreference
    ) {
      id
    }
  }
`;
export type UpsertNotificationPreferenceEoiMutationFn = Apollo.MutationFunction<
  UpsertNotificationPreferenceEoiMutation,
  UpsertNotificationPreferenceEoiMutationVariables
>;

/**
 * __useUpsertNotificationPreferenceEoiMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationPreferenceEoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationPreferenceEoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationPreferenceEoiMutation, { data, loading, error }] = useUpsertNotificationPreferenceEoiMutation({
 *   variables: {
 *      notificationPreference: // value for 'notificationPreference'
 *   },
 * });
 */
export function useUpsertNotificationPreferenceEoiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertNotificationPreferenceEoiMutation,
    UpsertNotificationPreferenceEoiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertNotificationPreferenceEoiMutation,
    UpsertNotificationPreferenceEoiMutationVariables
  >(UpsertNotificationPreferenceEoiDocument, options);
}
export type UpsertNotificationPreferenceEoiMutationHookResult = ReturnType<
  typeof useUpsertNotificationPreferenceEoiMutation
>;
export type UpsertNotificationPreferenceEoiMutationResult =
  Apollo.MutationResult<UpsertNotificationPreferenceEoiMutation>;
export type UpsertNotificationPreferenceEoiMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertNotificationPreferenceEoiMutation,
    UpsertNotificationPreferenceEoiMutationVariables
  >;
export const VerifyHoldingDetailsDocument = gql`
  mutation VerifyHoldingDetails(
    $postcode: String
    $country: String!
    $partialHin: String!
  ) {
    verifyHoldingDetails(
      postcode: $postcode
      country: $country
      partialHin: $partialHin
    )
  }
`;
export type VerifyHoldingDetailsMutationFn = Apollo.MutationFunction<
  VerifyHoldingDetailsMutation,
  VerifyHoldingDetailsMutationVariables
>;

/**
 * __useVerifyHoldingDetailsMutation__
 *
 * To run a mutation, you first call `useVerifyHoldingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyHoldingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyHoldingDetailsMutation, { data, loading, error }] = useVerifyHoldingDetailsMutation({
 *   variables: {
 *      postcode: // value for 'postcode'
 *      country: // value for 'country'
 *      partialHin: // value for 'partialHin'
 *   },
 * });
 */
export function useVerifyHoldingDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyHoldingDetailsMutation,
    VerifyHoldingDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyHoldingDetailsMutation,
    VerifyHoldingDetailsMutationVariables
  >(VerifyHoldingDetailsDocument, options);
}
export type VerifyHoldingDetailsMutationHookResult = ReturnType<
  typeof useVerifyHoldingDetailsMutation
>;
export type VerifyHoldingDetailsMutationResult =
  Apollo.MutationResult<VerifyHoldingDetailsMutation>;
export type VerifyHoldingDetailsMutationOptions = Apollo.BaseMutationOptions<
  VerifyHoldingDetailsMutation,
  VerifyHoldingDetailsMutationVariables
>;
export const VerifyHoldingUkDocument = gql`
  mutation VerifyHoldingUk(
    $accountName: String!
    $broker: String!
    $postcode: String
    $sharesOwned: Int
  ) {
    verifyHoldingUk(
      accountName: $accountName
      broker: $broker
      postcode: $postcode
      sharesOwned: $sharesOwned
    ) {
      id
      accountName
      broker
      postcode
      sharesOwned
    }
  }
`;
export type VerifyHoldingUkMutationFn = Apollo.MutationFunction<
  VerifyHoldingUkMutation,
  VerifyHoldingUkMutationVariables
>;

/**
 * __useVerifyHoldingUkMutation__
 *
 * To run a mutation, you first call `useVerifyHoldingUkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyHoldingUkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyHoldingUkMutation, { data, loading, error }] = useVerifyHoldingUkMutation({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      broker: // value for 'broker'
 *      postcode: // value for 'postcode'
 *      sharesOwned: // value for 'sharesOwned'
 *   },
 * });
 */
export function useVerifyHoldingUkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyHoldingUkMutation,
    VerifyHoldingUkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyHoldingUkMutation,
    VerifyHoldingUkMutationVariables
  >(VerifyHoldingUkDocument, options);
}
export type VerifyHoldingUkMutationHookResult = ReturnType<
  typeof useVerifyHoldingUkMutation
>;
export type VerifyHoldingUkMutationResult =
  Apollo.MutationResult<VerifyHoldingUkMutation>;
export type VerifyHoldingUkMutationOptions = Apollo.BaseMutationOptions<
  VerifyHoldingUkMutation,
  VerifyHoldingUkMutationVariables
>;
export const VerifyMultipleHoldingsAuDocument = gql`
  mutation VerifyMultipleHoldingsAu($holdings: [HoldingInputAu!]!) {
    verifyMultipleHoldingsAu(holdings: $holdings)
  }
`;
export type VerifyMultipleHoldingsAuMutationFn = Apollo.MutationFunction<
  VerifyMultipleHoldingsAuMutation,
  VerifyMultipleHoldingsAuMutationVariables
>;

/**
 * __useVerifyMultipleHoldingsAuMutation__
 *
 * To run a mutation, you first call `useVerifyMultipleHoldingsAuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMultipleHoldingsAuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMultipleHoldingsAuMutation, { data, loading, error }] = useVerifyMultipleHoldingsAuMutation({
 *   variables: {
 *      holdings: // value for 'holdings'
 *   },
 * });
 */
export function useVerifyMultipleHoldingsAuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMultipleHoldingsAuMutation,
    VerifyMultipleHoldingsAuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyMultipleHoldingsAuMutation,
    VerifyMultipleHoldingsAuMutationVariables
  >(VerifyMultipleHoldingsAuDocument, options);
}
export type VerifyMultipleHoldingsAuMutationHookResult = ReturnType<
  typeof useVerifyMultipleHoldingsAuMutation
>;
export type VerifyMultipleHoldingsAuMutationResult =
  Apollo.MutationResult<VerifyMultipleHoldingsAuMutation>;
export type VerifyMultipleHoldingsAuMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyMultipleHoldingsAuMutation,
    VerifyMultipleHoldingsAuMutationVariables
  >;
export const VerifyMultipleHoldingsUkDocument = gql`
  mutation VerifyMultipleHoldingsUk($holdings: [HoldingInputUk!]!) {
    verifyMultipleHoldingsUk(holdings: $holdings) {
      id
      accountName
      broker
      postcode
      sharesOwned
    }
  }
`;
export type VerifyMultipleHoldingsUkMutationFn = Apollo.MutationFunction<
  VerifyMultipleHoldingsUkMutation,
  VerifyMultipleHoldingsUkMutationVariables
>;

/**
 * __useVerifyMultipleHoldingsUkMutation__
 *
 * To run a mutation, you first call `useVerifyMultipleHoldingsUkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMultipleHoldingsUkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMultipleHoldingsUkMutation, { data, loading, error }] = useVerifyMultipleHoldingsUkMutation({
 *   variables: {
 *      holdings: // value for 'holdings'
 *   },
 * });
 */
export function useVerifyMultipleHoldingsUkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMultipleHoldingsUkMutation,
    VerifyMultipleHoldingsUkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyMultipleHoldingsUkMutation,
    VerifyMultipleHoldingsUkMutationVariables
  >(VerifyMultipleHoldingsUkDocument, options);
}
export type VerifyMultipleHoldingsUkMutationHookResult = ReturnType<
  typeof useVerifyMultipleHoldingsUkMutation
>;
export type VerifyMultipleHoldingsUkMutationResult =
  Apollo.MutationResult<VerifyMultipleHoldingsUkMutation>;
export type VerifyMultipleHoldingsUkMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyMultipleHoldingsUkMutation,
    VerifyMultipleHoldingsUkMutationVariables
  >;
export const VerifyUserEmailByTokenDocument = gql`
  mutation VerifyUserEmailByToken($token: String!) {
    verifyUserEmailByToken(token: $token) {
      id
      confirmedAt
    }
  }
`;
export type VerifyUserEmailByTokenMutationFn = Apollo.MutationFunction<
  VerifyUserEmailByTokenMutation,
  VerifyUserEmailByTokenMutationVariables
>;

/**
 * __useVerifyUserEmailByTokenMutation__
 *
 * To run a mutation, you first call `useVerifyUserEmailByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserEmailByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserEmailByTokenMutation, { data, loading, error }] = useVerifyUserEmailByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserEmailByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyUserEmailByTokenMutation,
    VerifyUserEmailByTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyUserEmailByTokenMutation,
    VerifyUserEmailByTokenMutationVariables
  >(VerifyUserEmailByTokenDocument, options);
}
export type VerifyUserEmailByTokenMutationHookResult = ReturnType<
  typeof useVerifyUserEmailByTokenMutation
>;
export type VerifyUserEmailByTokenMutationResult =
  Apollo.MutationResult<VerifyUserEmailByTokenMutation>;
export type VerifyUserEmailByTokenMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserEmailByTokenMutation,
  VerifyUserEmailByTokenMutationVariables
>;
export const ViewWebinarRecordingDocument = gql`
  mutation ViewWebinarRecording($webinarId: ID!) {
    viewWebinarRecording(webinarId: $webinarId) {
      id
      insertedAt
    }
  }
`;
export type ViewWebinarRecordingMutationFn = Apollo.MutationFunction<
  ViewWebinarRecordingMutation,
  ViewWebinarRecordingMutationVariables
>;

/**
 * __useViewWebinarRecordingMutation__
 *
 * To run a mutation, you first call `useViewWebinarRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewWebinarRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewWebinarRecordingMutation, { data, loading, error }] = useViewWebinarRecordingMutation({
 *   variables: {
 *      webinarId: // value for 'webinarId'
 *   },
 * });
 */
export function useViewWebinarRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewWebinarRecordingMutation,
    ViewWebinarRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewWebinarRecordingMutation,
    ViewWebinarRecordingMutationVariables
  >(ViewWebinarRecordingDocument, options);
}
export type ViewWebinarRecordingMutationHookResult = ReturnType<
  typeof useViewWebinarRecordingMutation
>;
export type ViewWebinarRecordingMutationResult =
  Apollo.MutationResult<ViewWebinarRecordingMutation>;
export type ViewWebinarRecordingMutationOptions = Apollo.BaseMutationOptions<
  ViewWebinarRecordingMutation,
  ViewWebinarRecordingMutationVariables
>;
export const WebinarAskQuestionDocument = gql`
  mutation WebinarAskQuestion($webinarId: ID!, $content: String!) {
    webinarAskQuestion(webinarId: $webinarId, content: $content) {
      id
      content
    }
  }
`;
export type WebinarAskQuestionMutationFn = Apollo.MutationFunction<
  WebinarAskQuestionMutation,
  WebinarAskQuestionMutationVariables
>;

/**
 * __useWebinarAskQuestionMutation__
 *
 * To run a mutation, you first call `useWebinarAskQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebinarAskQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webinarAskQuestionMutation, { data, loading, error }] = useWebinarAskQuestionMutation({
 *   variables: {
 *      webinarId: // value for 'webinarId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useWebinarAskQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebinarAskQuestionMutation,
    WebinarAskQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebinarAskQuestionMutation,
    WebinarAskQuestionMutationVariables
  >(WebinarAskQuestionDocument, options);
}
export type WebinarAskQuestionMutationHookResult = ReturnType<
  typeof useWebinarAskQuestionMutation
>;
export type WebinarAskQuestionMutationResult =
  Apollo.MutationResult<WebinarAskQuestionMutation>;
export type WebinarAskQuestionMutationOptions = Apollo.BaseMutationOptions<
  WebinarAskQuestionMutation,
  WebinarAskQuestionMutationVariables
>;
export const WebinarDeleteQuestionDocument = gql`
  mutation WebinarDeleteQuestion($questionId: ID!) {
    webinarDeleteQuestion(questionId: $questionId) {
      id
      content
    }
  }
`;
export type WebinarDeleteQuestionMutationFn = Apollo.MutationFunction<
  WebinarDeleteQuestionMutation,
  WebinarDeleteQuestionMutationVariables
>;

/**
 * __useWebinarDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useWebinarDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebinarDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webinarDeleteQuestionMutation, { data, loading, error }] = useWebinarDeleteQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useWebinarDeleteQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebinarDeleteQuestionMutation,
    WebinarDeleteQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebinarDeleteQuestionMutation,
    WebinarDeleteQuestionMutationVariables
  >(WebinarDeleteQuestionDocument, options);
}
export type WebinarDeleteQuestionMutationHookResult = ReturnType<
  typeof useWebinarDeleteQuestionMutation
>;
export type WebinarDeleteQuestionMutationResult =
  Apollo.MutationResult<WebinarDeleteQuestionMutation>;
export type WebinarDeleteQuestionMutationOptions = Apollo.BaseMutationOptions<
  WebinarDeleteQuestionMutation,
  WebinarDeleteQuestionMutationVariables
>;
export const WebinarEditQuestionDocument = gql`
  mutation WebinarEditQuestion($questionId: ID!, $content: String!) {
    webinarEditQuestion(questionId: $questionId, content: $content) {
      id
      content
    }
  }
`;
export type WebinarEditQuestionMutationFn = Apollo.MutationFunction<
  WebinarEditQuestionMutation,
  WebinarEditQuestionMutationVariables
>;

/**
 * __useWebinarEditQuestionMutation__
 *
 * To run a mutation, you first call `useWebinarEditQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebinarEditQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webinarEditQuestionMutation, { data, loading, error }] = useWebinarEditQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useWebinarEditQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebinarEditQuestionMutation,
    WebinarEditQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebinarEditQuestionMutation,
    WebinarEditQuestionMutationVariables
  >(WebinarEditQuestionDocument, options);
}
export type WebinarEditQuestionMutationHookResult = ReturnType<
  typeof useWebinarEditQuestionMutation
>;
export type WebinarEditQuestionMutationResult =
  Apollo.MutationResult<WebinarEditQuestionMutation>;
export type WebinarEditQuestionMutationOptions = Apollo.BaseMutationOptions<
  WebinarEditQuestionMutation,
  WebinarEditQuestionMutationVariables
>;
export const AllCompaniesDocument = gql`
  query AllCompanies($secret: String!) {
    allCompanies(secret: $secret) {
      id
      customDomain {
        id
        customDomain
      }
      investorCentreEnabled
      ticker {
        id
        listingKey
        marketListingKey
      }
    }
  }
`;

/**
 * __useAllCompaniesQuery__
 *
 * To run a query within a React component, call `useAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesQuery({
 *   variables: {
 *      secret: // value for 'secret'
 *   },
 * });
 */
export function useAllCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  > &
    (
      | { variables: AllCompaniesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options
  );
}
export function useAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options
  );
}
export function useAllCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options
  );
}
export type AllCompaniesQueryHookResult = ReturnType<
  typeof useAllCompaniesQuery
>;
export type AllCompaniesLazyQueryHookResult = ReturnType<
  typeof useAllCompaniesLazyQuery
>;
export type AllCompaniesSuspenseQueryHookResult = ReturnType<
  typeof useAllCompaniesSuspenseQuery
>;
export type AllCompaniesQueryResult = Apollo.QueryResult<
  AllCompaniesQuery,
  AllCompaniesQueryVariables
>;
export const ContactByHashIdDocument = gql`
  query ContactByHashId($hashId: String!) {
    contactByHashId(hashId: $hashId) {
      id
      hnwStatus
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
      investor {
        id
      }
    }
  }
`;

/**
 * __useContactByHashIdQuery__
 *
 * To run a query within a React component, call `useContactByHashIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactByHashIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactByHashIdQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useContactByHashIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactByHashIdQuery,
    ContactByHashIdQueryVariables
  > &
    (
      | { variables: ContactByHashIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactByHashIdQuery, ContactByHashIdQueryVariables>(
    ContactByHashIdDocument,
    options
  );
}
export function useContactByHashIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactByHashIdQuery,
    ContactByHashIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactByHashIdQuery,
    ContactByHashIdQueryVariables
  >(ContactByHashIdDocument, options);
}
export function useContactByHashIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactByHashIdQuery,
    ContactByHashIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContactByHashIdQuery,
    ContactByHashIdQueryVariables
  >(ContactByHashIdDocument, options);
}
export type ContactByHashIdQueryHookResult = ReturnType<
  typeof useContactByHashIdQuery
>;
export type ContactByHashIdLazyQueryHookResult = ReturnType<
  typeof useContactByHashIdLazyQuery
>;
export type ContactByHashIdSuspenseQueryHookResult = ReturnType<
  typeof useContactByHashIdSuspenseQuery
>;
export type ContactByHashIdQueryResult = Apollo.QueryResult<
  ContactByHashIdQuery,
  ContactByHashIdQueryVariables
>;
export const CurrentCompanyMarketDataSummaryDocument = gql`
  query CurrentCompanyMarketDataSummary {
    currentCompanyMarketDataSummary {
      id
      currency
      isMarketOpen
      lastTradedPrice
      open
      dayLow
      dayHigh
      priceChange
      percentageChange
      lastClose
      volume
      turnover
      bid
      ask
      marketCap
      sharesOutstanding
      vwap
      yearLow
      yearLowDate
      yearHigh
      yearHighDate
      earningsPerShare
      priceEarningsRatio
      updatedAt
    }
  }
`;

/**
 * __useCurrentCompanyMarketDataSummaryQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyMarketDataSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyMarketDataSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyMarketDataSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyMarketDataSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >(CurrentCompanyMarketDataSummaryDocument, options);
}
export function useCurrentCompanyMarketDataSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >(CurrentCompanyMarketDataSummaryDocument, options);
}
export function useCurrentCompanyMarketDataSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyMarketDataSummaryQuery,
    CurrentCompanyMarketDataSummaryQueryVariables
  >(CurrentCompanyMarketDataSummaryDocument, options);
}
export type CurrentCompanyMarketDataSummaryQueryHookResult = ReturnType<
  typeof useCurrentCompanyMarketDataSummaryQuery
>;
export type CurrentCompanyMarketDataSummaryLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyMarketDataSummaryLazyQuery
>;
export type CurrentCompanyMarketDataSummarySuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyMarketDataSummarySuspenseQuery
>;
export type CurrentCompanyMarketDataSummaryQueryResult = Apollo.QueryResult<
  CurrentCompanyMarketDataSummaryQuery,
  CurrentCompanyMarketDataSummaryQueryVariables
>;
export const CurrentCompanyOverviewDocument = gql`
  query CurrentCompanyOverview($startDate: Date!, $endDate: Date!) {
    currentCompanyOverview(startDate: $startDate, endDate: $endDate) {
      id
      date
      announcements {
        id
        header
      }
      close
      volume
    }
  }
`;

/**
 * __useCurrentCompanyOverviewQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCurrentCompanyOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  > &
    (
      | { variables: CurrentCompanyOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export function useCurrentCompanyOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export function useCurrentCompanyOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export type CurrentCompanyOverviewQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewQuery
>;
export type CurrentCompanyOverviewLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewLazyQuery
>;
export type CurrentCompanyOverviewSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewSuspenseQuery
>;
export type CurrentCompanyOverviewQueryResult = Apollo.QueryResult<
  CurrentCompanyOverviewQuery,
  CurrentCompanyOverviewQueryVariables
>;
export const CurrentCompanySecondaryMarketDataSummaryDocument = gql`
  query CurrentCompanySecondaryMarketDataSummary {
    currentCompanySecondaryMarketDataSummary {
      id
      listingKey
      marketKey
      currency
      isMarketOpen
      lastTradedPrice
      open
      dayLow
      dayHigh
      priceChange
      percentageChange
      lastClose
      volume
      turnover
      bid
      ask
      marketCap
      sharesOutstanding
      vwap
      yearLow
      yearLowDate
      yearHigh
      yearHighDate
      earningsPerShare
      priceEarningsRatio
      updatedAt
    }
  }
`;

/**
 * __useCurrentCompanySecondaryMarketDataSummaryQuery__
 *
 * To run a query within a React component, call `useCurrentCompanySecondaryMarketDataSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanySecondaryMarketDataSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanySecondaryMarketDataSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanySecondaryMarketDataSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >(CurrentCompanySecondaryMarketDataSummaryDocument, options);
}
export function useCurrentCompanySecondaryMarketDataSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >(CurrentCompanySecondaryMarketDataSummaryDocument, options);
}
export function useCurrentCompanySecondaryMarketDataSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >(CurrentCompanySecondaryMarketDataSummaryDocument, options);
}
export type CurrentCompanySecondaryMarketDataSummaryQueryHookResult =
  ReturnType<typeof useCurrentCompanySecondaryMarketDataSummaryQuery>;
export type CurrentCompanySecondaryMarketDataSummaryLazyQueryHookResult =
  ReturnType<typeof useCurrentCompanySecondaryMarketDataSummaryLazyQuery>;
export type CurrentCompanySecondaryMarketDataSummarySuspenseQueryHookResult =
  ReturnType<typeof useCurrentCompanySecondaryMarketDataSummarySuspenseQuery>;
export type CurrentCompanySecondaryMarketDataSummaryQueryResult =
  Apollo.QueryResult<
    CurrentCompanySecondaryMarketDataSummaryQuery,
    CurrentCompanySecondaryMarketDataSummaryQueryVariables
  >;
export const CurrentCompanyDocument = gql`
  query CurrentCompany($host: String!, $currentPageSlug: String) {
    currentCompany(host: $host, currentPageSlug: $currentPageSlug) {
      id
      colorPalette {
        primary {
          hsl
        }
      }
      defaultPublicInteractionsEnabled
      hub
      investorCentreEnabled
      investorHub {
        id
        email
        facebook
        instagram
        linkedin
        phone
        registry
        twitter
        website
        colourPrimary
        colourPrimaryText
        colourAccent
        colourAccentText
      }
      logoUrl
      name
      ticker {
        id
        listingKey
        marketKey
        marketListingKey
        refinitivIdentificationCode
      }
      recentScheduledWebinar {
        id
        title
        slug
      }
      liveWebinar {
        id
        title
        slug
      }
      secondaryTickers {
        id
        listingKey
        marketKey
      }
      featuresEnabled
      hasCompanyShareholderOfferPermission
      hasLiveGovernancePage
      currentCustomCorporatePageBySlug(currentPageSlug: $currentPageSlug) {
        id
        title
        slug
        isLive
      }
      isDemo
      isPremium
      isTrial
      isUnlisted
      liveInvestmentOfferId
      marketingEmail {
        sendFromName
        sendFromEmail
        replyToEmail
      }
      sppUrl
      sppNavText
      sppBannerDescription
      sppBannerButtonText
      sppBannerMobileButtonText
      publishedWebsite {
        id
        themeColourPrimary
        themeColourPrimaryText
        themeColourAccent
        themeColourAccentText
        themeFontTitle
        themeFontBody
        logoCloudinaryUrl
        logoSquareCloudinaryUrl
        isFullWebsiteReplacement
        isDarkMode
        isEmailPopupModalEnabled
        featuresOnSignUpPage
        emailPopupModalCloudinaryUrl
        googleAnalyticsId
        title
        headTagContent
        signUpPageTermsAndConditions
        cookieBanner
        publishedAt
        showWebinarBanner
        isCustomFooterEnabled
        footerLogoCloudinaryUrl
        description
        address
        telephone
        email
        facebookUrl
        linkedinUrl
        twitterUrl
        instagramUrl
        isSitemapEnabled
        sitemapLinkGroups
        badges
        isNewsletterSubscriptionEnabled
        newsletterSubscription
        footerBackgroundColor
        footerTextColor
        footerButtonBackgroundColor
        footerButtonTextColor
        parentNavMenuItems {
          id
          label
          url
          position
          openInNewTab
          page {
            id
            title
            slug
          }
          children {
            id
            label
            url
            position
            openInNewTab
            page {
              id
              title
              slug
            }
          }
        }
        currentPage(currentPageSlug: $currentPageSlug) {
          id
          title
          slug
          description
          isDraft
          metaDescription
          socialImageCloudinaryPublicId
          blocks {
            id
            type
            position
            content
            insertedAt
            updatedAt
          }
        }
      }
      unpublishedWebsite {
        id
        themeColourPrimary
        themeColourPrimaryText
        themeColourAccent
        themeColourAccentText
        themeFontTitle
        themeFontBody
        logoCloudinaryUrl
        logoSquareCloudinaryUrl
        isFullWebsiteReplacement
        isDarkMode
        isEmailPopupModalEnabled
        featuresOnSignUpPage
        emailPopupModalCloudinaryUrl
        googleAnalyticsId
        title
        headTagContent
        signUpPageTermsAndConditions
        cookieBanner
        publishedAt
        isCustomFooterEnabled
        footerLogoCloudinaryUrl
        description
        address
        telephone
        email
        facebookUrl
        linkedinUrl
        twitterUrl
        instagramUrl
        isSitemapEnabled
        sitemapLinkGroups
        badges
        isNewsletterSubscriptionEnabled
        footerBackgroundColor
        footerTextColor
        footerButtonBackgroundColor
        footerButtonTextColor
        parentNavMenuItems {
          id
          label
          url
          position
          openInNewTab
          page {
            id
            title
            slug
          }
          children {
            id
            label
            url
            position
            openInNewTab
            page {
              id
              title
              slug
            }
          }
        }
        currentPage(currentPageSlug: $currentPageSlug) {
          id
          title
          slug
          description
          isDraft
          metaDescription
          socialImageCloudinaryPublicId
          blocks {
            id
            type
            position
            content
            insertedAt
            updatedAt
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyQuery({
 *   variables: {
 *      host: // value for 'host'
 *      currentPageSlug: // value for 'currentPageSlug'
 *   },
 * });
 */
export function useCurrentCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  > &
    (
      | { variables: CurrentCompanyQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyQuery, CurrentCompanyQueryVariables>(
    CurrentCompanyDocument,
    options
  );
}
export function useCurrentCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyQuery, CurrentCompanyQueryVariables>(
    CurrentCompanyDocument,
    options
  );
}
export function useCurrentCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >(CurrentCompanyDocument, options);
}
export type CurrentCompanyQueryHookResult = ReturnType<
  typeof useCurrentCompanyQuery
>;
export type CurrentCompanyLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyLazyQuery
>;
export type CurrentCompanySuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanySuspenseQuery
>;
export type CurrentCompanyQueryResult = Apollo.QueryResult<
  CurrentCompanyQuery,
  CurrentCompanyQueryVariables
>;
export const CurrentCustomCorporatePageBySlugDocument = gql`
  query CurrentCustomCorporatePageBySlug(
    $listingKey: String!
    $marketKey: String!
    $slug: String!
  ) {
    currentCustomCorporatePageBySlug(
      listingKey: $listingKey
      marketKey: $marketKey
      slug: $slug
    ) {
      id
      title
      type
      isLive
      insertedAt
      updatedAt
      sections {
        id
        heading
        description
        attachments {
          id
          orderId
          heading
          subheading
          description
          fileUrl
        }
      }
      slug
    }
  }
`;

/**
 * __useCurrentCustomCorporatePageBySlugQuery__
 *
 * To run a query within a React component, call `useCurrentCustomCorporatePageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCustomCorporatePageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCustomCorporatePageBySlugQuery({
 *   variables: {
 *      listingKey: // value for 'listingKey'
 *      marketKey: // value for 'marketKey'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCurrentCustomCorporatePageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  > &
    (
      | {
          variables: CurrentCustomCorporatePageBySlugQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  >(CurrentCustomCorporatePageBySlugDocument, options);
}
export function useCurrentCustomCorporatePageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  >(CurrentCustomCorporatePageBySlugDocument, options);
}
export function useCurrentCustomCorporatePageBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCustomCorporatePageBySlugQuery,
    CurrentCustomCorporatePageBySlugQueryVariables
  >(CurrentCustomCorporatePageBySlugDocument, options);
}
export type CurrentCustomCorporatePageBySlugQueryHookResult = ReturnType<
  typeof useCurrentCustomCorporatePageBySlugQuery
>;
export type CurrentCustomCorporatePageBySlugLazyQueryHookResult = ReturnType<
  typeof useCurrentCustomCorporatePageBySlugLazyQuery
>;
export type CurrentCustomCorporatePageBySlugSuspenseQueryHookResult =
  ReturnType<typeof useCurrentCustomCorporatePageBySlugSuspenseQuery>;
export type CurrentCustomCorporatePageBySlugQueryResult = Apollo.QueryResult<
  CurrentCustomCorporatePageBySlugQuery,
  CurrentCustomCorporatePageBySlugQueryVariables
>;
export const CurrentInvestorUserUsernameDocument = gql`
  query CurrentInvestorUserUsername {
    currentInvestorUser {
      id
      email
      firstName
      username
    }
  }
`;

/**
 * __useCurrentInvestorUserUsernameQuery__
 *
 * To run a query within a React component, call `useCurrentInvestorUserUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentInvestorUserUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentInvestorUserUsernameQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentInvestorUserUsernameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >(CurrentInvestorUserUsernameDocument, options);
}
export function useCurrentInvestorUserUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >(CurrentInvestorUserUsernameDocument, options);
}
export function useCurrentInvestorUserUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentInvestorUserUsernameQuery,
    CurrentInvestorUserUsernameQueryVariables
  >(CurrentInvestorUserUsernameDocument, options);
}
export type CurrentInvestorUserUsernameQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserUsernameQuery
>;
export type CurrentInvestorUserUsernameLazyQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserUsernameLazyQuery
>;
export type CurrentInvestorUserUsernameSuspenseQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserUsernameSuspenseQuery
>;
export type CurrentInvestorUserUsernameQueryResult = Apollo.QueryResult<
  CurrentInvestorUserUsernameQuery,
  CurrentInvestorUserUsernameQueryVariables
>;
export const CurrentInvestorUserDocument = gql`
  query CurrentInvestorUser {
    currentInvestorUser {
      id
      certificate {
        id
        certificateUrl
        expiresAt
        insertedAt
        statDecAcceptedAt
        status
        type
      }
      confirmedAt
      contact {
        id
        hnwStatus
        commsUnsubscribes {
          id
          scope
        }
        globalUnsubscribe {
          id
        }
        investor {
          id
        }
      }
      email
      firstName
      hnwIdentifiedAt
      hnwStatus
      insertedAt
      isSelfNominatedShareholder
      selfNominatedShareholderIdentifiedAt
      isHoldingVerified
      isCurrentShareholder
      showVerifiedBadge
      lastName
      notificationPreferences {
        id
        channel
        hasEoi
        isOn
        scope
      }
      shareholderInformations {
        id
        country
        partialHin
        postcode
      }
      shareholderInformationsUk {
        id
        accountName
        broker
        postcode
        sharesOwned
      }
      shareholding {
        id
        addressCountry
        addressPostcode
        holderId
      }
      shareholdings {
        id
        addressCountry
        addressPostcode
        holderId
      }
      username
    }
  }
`;

/**
 * __useCurrentInvestorUserQuery__
 *
 * To run a query within a React component, call `useCurrentInvestorUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentInvestorUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentInvestorUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentInvestorUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >(CurrentInvestorUserDocument, options);
}
export function useCurrentInvestorUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >(CurrentInvestorUserDocument, options);
}
export function useCurrentInvestorUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentInvestorUserQuery,
    CurrentInvestorUserQueryVariables
  >(CurrentInvestorUserDocument, options);
}
export type CurrentInvestorUserQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserQuery
>;
export type CurrentInvestorUserLazyQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserLazyQuery
>;
export type CurrentInvestorUserSuspenseQueryHookResult = ReturnType<
  typeof useCurrentInvestorUserSuspenseQuery
>;
export type CurrentInvestorUserQueryResult = Apollo.QueryResult<
  CurrentInvestorUserQuery,
  CurrentInvestorUserQueryVariables
>;
export const CurrentWelcomePageDocument = gql`
  query CurrentWelcomePage($listingKey: String!, $marketKey: String!) {
    currentWelcomePage(listingKey: $listingKey, marketKey: $marketKey) {
      id
      author
      linkedinProfileUrl
      title
      welcomeMessage
      bannerUrl
      profilePictureUrl
      signatureUrl
    }
  }
`;

/**
 * __useCurrentWelcomePageQuery__
 *
 * To run a query within a React component, call `useCurrentWelcomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWelcomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWelcomePageQuery({
 *   variables: {
 *      listingKey: // value for 'listingKey'
 *      marketKey: // value for 'marketKey'
 *   },
 * });
 */
export function useCurrentWelcomePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  > &
    (
      | { variables: CurrentWelcomePageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export function useCurrentWelcomePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export function useCurrentWelcomePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export type CurrentWelcomePageQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageQuery
>;
export type CurrentWelcomePageLazyQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageLazyQuery
>;
export type CurrentWelcomePageSuspenseQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageSuspenseQuery
>;
export type CurrentWelcomePageQueryResult = Apollo.QueryResult<
  CurrentWelcomePageQuery,
  CurrentWelcomePageQueryVariables
>;
export const DiscoverBlockAnnouncementsAndUpdatesDocument = gql`
  query DiscoverBlockAnnouncementsAndUpdates($hub: String!) {
    discoverBlockAnnouncementsAndUpdates(hub: $hub) {
      id
      latestUpdate {
        id
        postedAt
        title
        includedTypes
        slug
        mediaId
        attachments {
          thumbnailUrl
          url
          title
        }
        content {
          content
        }
      }
      latestAnnualReport {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
      latestInvestorPresentation {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
      latestQuarterlyCashflowReport {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
    }
  }
`;

/**
 * __useDiscoverBlockAnnouncementsAndUpdatesQuery__
 *
 * To run a query within a React component, call `useDiscoverBlockAnnouncementsAndUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverBlockAnnouncementsAndUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverBlockAnnouncementsAndUpdatesQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useDiscoverBlockAnnouncementsAndUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  > &
    (
      | {
          variables: DiscoverBlockAnnouncementsAndUpdatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >(DiscoverBlockAnnouncementsAndUpdatesDocument, options);
}
export function useDiscoverBlockAnnouncementsAndUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >(DiscoverBlockAnnouncementsAndUpdatesDocument, options);
}
export function useDiscoverBlockAnnouncementsAndUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >(DiscoverBlockAnnouncementsAndUpdatesDocument, options);
}
export type DiscoverBlockAnnouncementsAndUpdatesQueryHookResult = ReturnType<
  typeof useDiscoverBlockAnnouncementsAndUpdatesQuery
>;
export type DiscoverBlockAnnouncementsAndUpdatesLazyQueryHookResult =
  ReturnType<typeof useDiscoverBlockAnnouncementsAndUpdatesLazyQuery>;
export type DiscoverBlockAnnouncementsAndUpdatesSuspenseQueryHookResult =
  ReturnType<typeof useDiscoverBlockAnnouncementsAndUpdatesSuspenseQuery>;
export type DiscoverBlockAnnouncementsAndUpdatesQueryResult =
  Apollo.QueryResult<
    DiscoverBlockAnnouncementsAndUpdatesQuery,
    DiscoverBlockAnnouncementsAndUpdatesQueryVariables
  >;
export const GetWebinarDataDocument = gql`
  query GetWebinarData($slug: String!) {
    webinarData(slug: $slug) {
      webinar {
        id
        type
        hmsRoomId
        publishedRecordingUrl
        posterImageUrl
        title
        slug
        summary
        startTime
        state
        endTime
        startedBroadcastingAt
        stoppedBroadcastingAt
        timezone
        hmsViewerRoomCode
        imageCloudinaryId
        imageOverlayEnabled
        allowPreWebinarComments
        discoverableOnHub
        transcript
        transcriptSummary
        showTranscriptOnHub
        showTranscriptSummaryOnHub
        recordingNeedsLogin
        attendanceNeedsLogin
        documents {
          id
          cloudinaryId
          fileName
          fileType
          fileSize
          position
        }
      }
      attendee {
        id
        insertedAt
        viewedRecordingAt
        questions {
          id
          content
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetWebinarDataQuery__
 *
 * To run a query within a React component, call `useGetWebinarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebinarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebinarDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetWebinarDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWebinarDataQuery,
    GetWebinarDataQueryVariables
  > &
    (
      | { variables: GetWebinarDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebinarDataQuery, GetWebinarDataQueryVariables>(
    GetWebinarDataDocument,
    options
  );
}
export function useGetWebinarDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWebinarDataQuery,
    GetWebinarDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebinarDataQuery, GetWebinarDataQueryVariables>(
    GetWebinarDataDocument,
    options
  );
}
export function useGetWebinarDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWebinarDataQuery,
    GetWebinarDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWebinarDataQuery,
    GetWebinarDataQueryVariables
  >(GetWebinarDataDocument, options);
}
export type GetWebinarDataQueryHookResult = ReturnType<
  typeof useGetWebinarDataQuery
>;
export type GetWebinarDataLazyQueryHookResult = ReturnType<
  typeof useGetWebinarDataLazyQuery
>;
export type GetWebinarDataSuspenseQueryHookResult = ReturnType<
  typeof useGetWebinarDataSuspenseQuery
>;
export type GetWebinarDataQueryResult = Apollo.QueryResult<
  GetWebinarDataQuery,
  GetWebinarDataQueryVariables
>;
export const HomepageAnnouncementsAndUpdatesDocument = gql`
  query HomepageAnnouncementsAndUpdates($hub: String!) {
    homepageAnnouncementsAndUpdates(hub: $hub) {
      id
      latestTwoAnnouncements {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
      latestUpdate {
        id
        postedAt
        title
        includedTypes
        slug
        mediaId
        attachments {
          thumbnailUrl
          url
          title
        }
        content {
          content
        }
      }
      latestAnnualReport {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
      latestInvestorPresentation {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
      latestQuarterlyCashflowReport {
        id
        header
        likes
        listingKey
        marketKey
        marketSensitive
        mediaId
        postedAt
        publicCommentCount
        rectype
        socialVideoUrl
        subtypes
        summary
        thumbnailIsPortrait
        thumbnailUrl
        url
        videoUrl
      }
    }
  }
`;

/**
 * __useHomepageAnnouncementsAndUpdatesQuery__
 *
 * To run a query within a React component, call `useHomepageAnnouncementsAndUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageAnnouncementsAndUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageAnnouncementsAndUpdatesQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useHomepageAnnouncementsAndUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  > &
    (
      | {
          variables: HomepageAnnouncementsAndUpdatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  >(HomepageAnnouncementsAndUpdatesDocument, options);
}
export function useHomepageAnnouncementsAndUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  >(HomepageAnnouncementsAndUpdatesDocument, options);
}
export function useHomepageAnnouncementsAndUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomepageAnnouncementsAndUpdatesQuery,
    HomepageAnnouncementsAndUpdatesQueryVariables
  >(HomepageAnnouncementsAndUpdatesDocument, options);
}
export type HomepageAnnouncementsAndUpdatesQueryHookResult = ReturnType<
  typeof useHomepageAnnouncementsAndUpdatesQuery
>;
export type HomepageAnnouncementsAndUpdatesLazyQueryHookResult = ReturnType<
  typeof useHomepageAnnouncementsAndUpdatesLazyQuery
>;
export type HomepageAnnouncementsAndUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useHomepageAnnouncementsAndUpdatesSuspenseQuery
>;
export type HomepageAnnouncementsAndUpdatesQueryResult = Apollo.QueryResult<
  HomepageAnnouncementsAndUpdatesQuery,
  HomepageAnnouncementsAndUpdatesQueryVariables
>;
export const HubNotificationsDocument = gql`
  query HubNotifications {
    hubNotifications {
      id
      isRead
      type
      investorUser {
        id
        firstName
        lastName
        username
        isHoldingVerified
      }
      hubFollow {
        id
        invalidated
        isProfileFollowBack
        investorUser {
          id
          firstName
          lastName
          username
          isHoldingVerified
        }
        followingMedia {
          id
          mediaAnnouncement {
            id
            header
          }
          mediaUpdate {
            id
            title
            slug
          }
        }
        followingInvestorUser {
          id
        }
        followingMediaComment {
          id
          media {
            mediaAnnouncement {
              id
              header
            }
            mediaUpdate {
              id
              title
              slug
            }
          }
        }
      }
      hubFeature {
        id
        isActive
        buttonLink
        description
      }
      media {
        id
        mediaUpdate {
          id
          title
          slug
        }
        mediaAnnouncement {
          id
          header
        }
      }
      mediaComment {
        id
        media {
          mediaAnnouncement {
            id
            header
          }
          mediaUpdate {
            id
            title
            slug
          }
        }
      }
      mediaCommentLike {
        id
        investorUser {
          id
          firstName
          lastName
          username
          isHoldingVerified
        }
        comment {
          id
          media {
            mediaAnnouncement {
              id
              header
            }
            mediaUpdate {
              id
              title
              slug
            }
          }
        }
      }
      mediaCommentReply {
        id
        useCompanyAsUsername
        companyAuthor {
          id
          firstName
          lastName
        }
      }
      insertedAt
      updatedAt
    }
  }
`;

/**
 * __useHubNotificationsQuery__
 *
 * To run a query within a React component, call `useHubNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHubNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HubNotificationsQuery,
    HubNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HubNotificationsQuery, HubNotificationsQueryVariables>(
    HubNotificationsDocument,
    options
  );
}
export function useHubNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HubNotificationsQuery,
    HubNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HubNotificationsQuery,
    HubNotificationsQueryVariables
  >(HubNotificationsDocument, options);
}
export function useHubNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HubNotificationsQuery,
    HubNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HubNotificationsQuery,
    HubNotificationsQueryVariables
  >(HubNotificationsDocument, options);
}
export type HubNotificationsQueryHookResult = ReturnType<
  typeof useHubNotificationsQuery
>;
export type HubNotificationsLazyQueryHookResult = ReturnType<
  typeof useHubNotificationsLazyQuery
>;
export type HubNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useHubNotificationsSuspenseQuery
>;
export type HubNotificationsQueryResult = Apollo.QueryResult<
  HubNotificationsQuery,
  HubNotificationsQueryVariables
>;
export const InvestmentOfferDocument = gql`
  query InvestmentOffer($id: ID!) {
    investmentOffer(id: $id) {
      id
      title
      type
      isLive
      publishedAt
      scheduledAt
      insertedAt
      updatedAt
      companyShareholderOfferPage {
        id
        bannerUrl
        closingDate
        interactiveAnnouncementLink
        introductionHeading
        introductionMessage
        investorPresentationName
        investorPresentationUrl
        offerBookletName
        offerBookletUrl
        offerPrice
        raiseApplyInstructionMessage
        raiseDefinitionMessage
        raiseDefinitionTimelineDiagramUrl
        raiseReasonHeroMediaImageUrl
        raiseReasonHeroMediaVideoUrl
        raiseReasonHeroMediaSocialVideoUrl
        raiseReasonMessage
        raisingTarget
        usCitizenPageEnabled
        faqs {
          answer
          question
          orderId
        }
        instructions {
          subheading
          message
          buttonUrl
          buttonText
          orderId
        }
      }
    }
  }
`;

/**
 * __useInvestmentOfferQuery__
 *
 * To run a query within a React component, call `useInvestmentOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestmentOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestmentOfferQuery,
    InvestmentOfferQueryVariables
  > &
    (
      | { variables: InvestmentOfferQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestmentOfferQuery, InvestmentOfferQueryVariables>(
    InvestmentOfferDocument,
    options
  );
}
export function useInvestmentOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestmentOfferQuery,
    InvestmentOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestmentOfferQuery,
    InvestmentOfferQueryVariables
  >(InvestmentOfferDocument, options);
}
export function useInvestmentOfferSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestmentOfferQuery,
    InvestmentOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestmentOfferQuery,
    InvestmentOfferQueryVariables
  >(InvestmentOfferDocument, options);
}
export type InvestmentOfferQueryHookResult = ReturnType<
  typeof useInvestmentOfferQuery
>;
export type InvestmentOfferLazyQueryHookResult = ReturnType<
  typeof useInvestmentOfferLazyQuery
>;
export type InvestmentOfferSuspenseQueryHookResult = ReturnType<
  typeof useInvestmentOfferSuspenseQuery
>;
export type InvestmentOfferQueryResult = Apollo.QueryResult<
  InvestmentOfferQuery,
  InvestmentOfferQueryVariables
>;
export const InvestorMediaCommentsDocument = gql`
  query InvestorMediaComments(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
    $hub: String!
  ) {
    investorMediaComments(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
      hub: $hub
    ) {
      edges {
        node {
          id
          commentSource
          commentSourceUrl
          content
          followed
          useCompanyAsUsername
          annotationMetadata {
            left
            pageIndex
            top
          }
          investorUser {
            id
            showShareholderBadge
            username
          }
          companyAuthor {
            id
            email
            firstName
            lastName
          }
          likes
          liked
          insertedAt
          media {
            id
            mediaAnnouncement {
              id
              header
              totalViewCount
              url
              thumbnailUrl
            }
            mediaUpdate {
              id
              postedAt
              title
              includedTypes
              isPinned
              slug
              mediaId
              answeredQuestionCount
              questionCount
              likes
              totalViewCount
              thumbnailAttachment {
                thumbnailUrl
                type
                url
                orderId
                title
                thumbnail
                thumbnailIsPortrait
              }
              attachments {
                thumbnailUrl
                url
                title
                orderId
                type
                thumbnail
                thumbnailIsPortrait
              }
              content {
                content
              }
            }
          }
          children {
            id
            companyAuthor {
              id
              firstName
              lastName
            }
            likes
            liked
            insertedAt
            useCompanyAsUsername
            media {
              id
              mediaAnnouncement {
                id
                header
                totalViewCount
                url
              }
              mediaUpdate {
                id
                title
                slug
                totalViewCount
                attachments {
                  url
                  orderId
                  type
                }
              }
            }
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useInvestorMediaCommentsQuery__
 *
 * To run a query within a React component, call `useInvestorMediaCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorMediaCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorMediaCommentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useInvestorMediaCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  > &
    (
      | { variables: InvestorMediaCommentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  >(InvestorMediaCommentsDocument, options);
}
export function useInvestorMediaCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  >(InvestorMediaCommentsDocument, options);
}
export function useInvestorMediaCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorMediaCommentsQuery,
    InvestorMediaCommentsQueryVariables
  >(InvestorMediaCommentsDocument, options);
}
export type InvestorMediaCommentsQueryHookResult = ReturnType<
  typeof useInvestorMediaCommentsQuery
>;
export type InvestorMediaCommentsLazyQueryHookResult = ReturnType<
  typeof useInvestorMediaCommentsLazyQuery
>;
export type InvestorMediaCommentsSuspenseQueryHookResult = ReturnType<
  typeof useInvestorMediaCommentsSuspenseQuery
>;
export type InvestorMediaCommentsQueryResult = Apollo.QueryResult<
  InvestorMediaCommentsQuery,
  InvestorMediaCommentsQueryVariables
>;
export const IsUsernameAvailableDocument = gql`
  query IsUsernameAvailable($username: String!) {
    isUsernameAvailable(username: $username)
  }
`;

/**
 * __useIsUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useIsUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUsernameAvailableQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useIsUsernameAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  > &
    (
      | { variables: IsUsernameAvailableQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  >(IsUsernameAvailableDocument, options);
}
export function useIsUsernameAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  >(IsUsernameAvailableDocument, options);
}
export function useIsUsernameAvailableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsUsernameAvailableQuery,
    IsUsernameAvailableQueryVariables
  >(IsUsernameAvailableDocument, options);
}
export type IsUsernameAvailableQueryHookResult = ReturnType<
  typeof useIsUsernameAvailableQuery
>;
export type IsUsernameAvailableLazyQueryHookResult = ReturnType<
  typeof useIsUsernameAvailableLazyQuery
>;
export type IsUsernameAvailableSuspenseQueryHookResult = ReturnType<
  typeof useIsUsernameAvailableSuspenseQuery
>;
export type IsUsernameAvailableQueryResult = Apollo.QueryResult<
  IsUsernameAvailableQuery,
  IsUsernameAvailableQueryVariables
>;
export const LiveCustomCorporatePagesDocument = gql`
  query LiveCustomCorporatePages($listingKey: String!, $marketKey: String!) {
    liveCustomCorporatePages(listingKey: $listingKey, marketKey: $marketKey) {
      id
      title
      type
      isLive
      insertedAt
      updatedAt
      sections {
        id
        heading
        description
        attachments {
          id
          orderId
          heading
          subheading
          description
          fileUrl
        }
      }
      slug
    }
  }
`;

/**
 * __useLiveCustomCorporatePagesQuery__
 *
 * To run a query within a React component, call `useLiveCustomCorporatePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveCustomCorporatePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveCustomCorporatePagesQuery({
 *   variables: {
 *      listingKey: // value for 'listingKey'
 *      marketKey: // value for 'marketKey'
 *   },
 * });
 */
export function useLiveCustomCorporatePagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  > &
    (
      | { variables: LiveCustomCorporatePagesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  >(LiveCustomCorporatePagesDocument, options);
}
export function useLiveCustomCorporatePagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  >(LiveCustomCorporatePagesDocument, options);
}
export function useLiveCustomCorporatePagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LiveCustomCorporatePagesQuery,
    LiveCustomCorporatePagesQueryVariables
  >(LiveCustomCorporatePagesDocument, options);
}
export type LiveCustomCorporatePagesQueryHookResult = ReturnType<
  typeof useLiveCustomCorporatePagesQuery
>;
export type LiveCustomCorporatePagesLazyQueryHookResult = ReturnType<
  typeof useLiveCustomCorporatePagesLazyQuery
>;
export type LiveCustomCorporatePagesSuspenseQueryHookResult = ReturnType<
  typeof useLiveCustomCorporatePagesSuspenseQuery
>;
export type LiveCustomCorporatePagesQueryResult = Apollo.QueryResult<
  LiveCustomCorporatePagesQuery,
  LiveCustomCorporatePagesQueryVariables
>;
export const MediaAnnouncementDateRangeDocument = gql`
  query MediaAnnouncementDateRange($ticker: String!) {
    mediaAnnouncementDateRange(ticker: $ticker) {
      oldest
      newest
    }
  }
`;

/**
 * __useMediaAnnouncementDateRangeQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementDateRangeQuery({
 *   variables: {
 *      ticker: // value for 'ticker'
 *   },
 * });
 */
export function useMediaAnnouncementDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  > &
    (
      | { variables: MediaAnnouncementDateRangeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  >(MediaAnnouncementDateRangeDocument, options);
}
export function useMediaAnnouncementDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  >(MediaAnnouncementDateRangeDocument, options);
}
export function useMediaAnnouncementDateRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementDateRangeQuery,
    MediaAnnouncementDateRangeQueryVariables
  >(MediaAnnouncementDateRangeDocument, options);
}
export type MediaAnnouncementDateRangeQueryHookResult = ReturnType<
  typeof useMediaAnnouncementDateRangeQuery
>;
export type MediaAnnouncementDateRangeLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementDateRangeLazyQuery
>;
export type MediaAnnouncementDateRangeSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementDateRangeSuspenseQuery
>;
export type MediaAnnouncementDateRangeQueryResult = Apollo.QueryResult<
  MediaAnnouncementDateRangeQuery,
  MediaAnnouncementDateRangeQueryVariables
>;
export const MediaAnnouncementDocument = gql`
  query MediaAnnouncement($id: ID!) {
    mediaAnnouncement(id: $id) {
      id
      featuredOnHub
      header
      likes
      listingKey
      marketKey
      marketSensitive
      mediaId
      newsPublisher
      postedAt
      publicCommentCount
      rectype
      socialVideoUrl
      subtypes
      summary
      summaryAi
      thumbnailIsPortrait
      thumbnailUrl
      url
      videoUrl
      germanTranslatedUrl
      germanTranslatedHeader
      totalViewCount
      germanTranslatedVideoUrl
      germanTranslatedSummary
    }
  }
`;

/**
 * __useMediaAnnouncementQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  > &
    (
      | { variables: MediaAnnouncementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export function useMediaAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export function useMediaAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export type MediaAnnouncementQueryHookResult = ReturnType<
  typeof useMediaAnnouncementQuery
>;
export type MediaAnnouncementLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementLazyQuery
>;
export type MediaAnnouncementSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementSuspenseQuery
>;
export type MediaAnnouncementQueryResult = Apollo.QueryResult<
  MediaAnnouncementQuery,
  MediaAnnouncementQueryVariables
>;
export const MediaAnnouncementsDocument = gql`
  query MediaAnnouncements(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
    $hub: String
  ) {
    mediaAnnouncements(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          featuredOnHub
          header
          likes
          listingKey
          marketKey
          marketSensitive
          mediaId
          newsPublisher
          postedAt
          publicCommentCount(hub: $hub)
          totalParentComments(hub: $hub)
          totalViewCount
          rectype
          socialVideoUrl
          subtypes
          summary
          thumbnailIsPortrait
          thumbnailUrl
          url
          videoUrl
          germanTranslatedUrl
          germanTranslatedHeader
          germanTranslatedVideoUrl
          germanTranslatedSummary
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useMediaAnnouncementsQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useMediaAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export function useMediaAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export function useMediaAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export type MediaAnnouncementsQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsQuery
>;
export type MediaAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsLazyQuery
>;
export type MediaAnnouncementsSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsSuspenseQuery
>;
export type MediaAnnouncementsQueryResult = Apollo.QueryResult<
  MediaAnnouncementsQuery,
  MediaAnnouncementsQueryVariables
>;
export const MediaCommentsDocument = gql`
  query MediaComments($isAnnotation: Boolean, $mediaId: ID!, $hub: String!) {
    mediaComments(isAnnotation: $isAnnotation, mediaId: $mediaId, hub: $hub) {
      id
      insertedAt
      updatedAt
      annotationMetadata {
        left
        pageIndex
        top
      }
      commentSource
      commentSourceUrl
      children {
        id
        insertedAt
        updatedAt
        annotationMetadata {
          left
          pageIndex
          top
        }
        companyAuthor {
          id
          firstName
          lastName
        }
        content
        investorUser {
          id
          showShareholderBadge
          username
        }
        followed
        liked
        likes
        private
        useCompanyAsUsername
      }
      companyAuthor {
        id
        firstName
        lastName
      }
      content
      investorUser {
        id
        showShareholderBadge
        username
      }
      followed
      liked
      likes
      private
      useCompanyAsUsername
    }
  }
`;

/**
 * __useMediaCommentsQuery__
 *
 * To run a query within a React component, call `useMediaCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCommentsQuery({
 *   variables: {
 *      isAnnotation: // value for 'isAnnotation'
 *      mediaId: // value for 'mediaId'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useMediaCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  > &
    (
      | { variables: MediaCommentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaCommentsQuery, MediaCommentsQueryVariables>(
    MediaCommentsDocument,
    options
  );
}
export function useMediaCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaCommentsQuery, MediaCommentsQueryVariables>(
    MediaCommentsDocument,
    options
  );
}
export function useMediaCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >(MediaCommentsDocument, options);
}
export type MediaCommentsQueryHookResult = ReturnType<
  typeof useMediaCommentsQuery
>;
export type MediaCommentsLazyQueryHookResult = ReturnType<
  typeof useMediaCommentsLazyQuery
>;
export type MediaCommentsSuspenseQueryHookResult = ReturnType<
  typeof useMediaCommentsSuspenseQuery
>;
export type MediaCommentsQueryResult = Apollo.QueryResult<
  MediaCommentsQuery,
  MediaCommentsQueryVariables
>;
export const MediaFollowDocument = gql`
  query MediaFollow($mediaId: ID!) {
    mediaFollow(mediaId: $mediaId)
  }
`;

/**
 * __useMediaFollowQuery__
 *
 * To run a query within a React component, call `useMediaFollowQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaFollowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaFollowQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaFollowQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaFollowQuery,
    MediaFollowQueryVariables
  > &
    (
      | { variables: MediaFollowQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaFollowQuery, MediaFollowQueryVariables>(
    MediaFollowDocument,
    options
  );
}
export function useMediaFollowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaFollowQuery,
    MediaFollowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaFollowQuery, MediaFollowQueryVariables>(
    MediaFollowDocument,
    options
  );
}
export function useMediaFollowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaFollowQuery,
    MediaFollowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaFollowQuery, MediaFollowQueryVariables>(
    MediaFollowDocument,
    options
  );
}
export type MediaFollowQueryHookResult = ReturnType<typeof useMediaFollowQuery>;
export type MediaFollowLazyQueryHookResult = ReturnType<
  typeof useMediaFollowLazyQuery
>;
export type MediaFollowSuspenseQueryHookResult = ReturnType<
  typeof useMediaFollowSuspenseQuery
>;
export type MediaFollowQueryResult = Apollo.QueryResult<
  MediaFollowQuery,
  MediaFollowQueryVariables
>;
export const MediaLikeDocument = gql`
  query MediaLike($mediaId: ID!) {
    mediaLike(mediaId: $mediaId)
  }
`;

/**
 * __useMediaLikeQuery__
 *
 * To run a query within a React component, call `useMediaLikeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaLikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaLikeQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaLikeQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaLikeQuery,
    MediaLikeQueryVariables
  > &
    ({ variables: MediaLikeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaLikeQuery, MediaLikeQueryVariables>(
    MediaLikeDocument,
    options
  );
}
export function useMediaLikeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaLikeQuery,
    MediaLikeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaLikeQuery, MediaLikeQueryVariables>(
    MediaLikeDocument,
    options
  );
}
export function useMediaLikeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaLikeQuery,
    MediaLikeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaLikeQuery, MediaLikeQueryVariables>(
    MediaLikeDocument,
    options
  );
}
export type MediaLikeQueryHookResult = ReturnType<typeof useMediaLikeQuery>;
export type MediaLikeLazyQueryHookResult = ReturnType<
  typeof useMediaLikeLazyQuery
>;
export type MediaLikeSuspenseQueryHookResult = ReturnType<
  typeof useMediaLikeSuspenseQuery
>;
export type MediaLikeQueryResult = Apollo.QueryResult<
  MediaLikeQuery,
  MediaLikeQueryVariables
>;
export const MediaSurveyResultsDocument = gql`
  query MediaSurveyResults($mediaId: ID!) {
    mediaSurveyResults(mediaId: $mediaId) {
      individualAnswers {
        answer
        count
      }
      investorAnswer
      question
      totalResponses
    }
  }
`;

/**
 * __useMediaSurveyResultsQuery__
 *
 * To run a query within a React component, call `useMediaSurveyResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaSurveyResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaSurveyResultsQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaSurveyResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  > &
    (
      | { variables: MediaSurveyResultsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  >(MediaSurveyResultsDocument, options);
}
export function useMediaSurveyResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  >(MediaSurveyResultsDocument, options);
}
export function useMediaSurveyResultsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaSurveyResultsQuery,
    MediaSurveyResultsQueryVariables
  >(MediaSurveyResultsDocument, options);
}
export type MediaSurveyResultsQueryHookResult = ReturnType<
  typeof useMediaSurveyResultsQuery
>;
export type MediaSurveyResultsLazyQueryHookResult = ReturnType<
  typeof useMediaSurveyResultsLazyQuery
>;
export type MediaSurveyResultsSuspenseQueryHookResult = ReturnType<
  typeof useMediaSurveyResultsSuspenseQuery
>;
export type MediaSurveyResultsQueryResult = Apollo.QueryResult<
  MediaSurveyResultsQuery,
  MediaSurveyResultsQueryVariables
>;
export const MediaUpdateBySlugDocument = gql`
  query MediaUpdateBySlug($hub: String!, $slug: String!) {
    mediaUpdateBySlug(hub: $hub, slug: $slug) {
      id
      postedAt
      title
      includedTypes
      slug
      mediaId
      likes
      answeredQuestionCount
      questionCount
      totalViewCount
      isDraft
      attachments {
        id
        thumbnailUrl
        url
        title
        description
        orderId
        type
      }
      content {
        content
      }
    }
  }
`;

/**
 * __useMediaUpdateBySlugQuery__
 *
 * To run a query within a React component, call `useMediaUpdateBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdateBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUpdateBySlugQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useMediaUpdateBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  > &
    (
      | { variables: MediaUpdateBySlugQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  >(MediaUpdateBySlugDocument, options);
}
export function useMediaUpdateBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  >(MediaUpdateBySlugDocument, options);
}
export function useMediaUpdateBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaUpdateBySlugQuery,
    MediaUpdateBySlugQueryVariables
  >(MediaUpdateBySlugDocument, options);
}
export type MediaUpdateBySlugQueryHookResult = ReturnType<
  typeof useMediaUpdateBySlugQuery
>;
export type MediaUpdateBySlugLazyQueryHookResult = ReturnType<
  typeof useMediaUpdateBySlugLazyQuery
>;
export type MediaUpdateBySlugSuspenseQueryHookResult = ReturnType<
  typeof useMediaUpdateBySlugSuspenseQuery
>;
export type MediaUpdateBySlugQueryResult = Apollo.QueryResult<
  MediaUpdateBySlugQuery,
  MediaUpdateBySlugQueryVariables
>;
export const MediaUpdateDateRangeDocument = gql`
  query MediaUpdateDateRange($hub: String!) {
    mediaUpdateDateRange(hub: $hub) {
      oldest
      newest
    }
  }
`;

/**
 * __useMediaUpdateDateRangeQuery__
 *
 * To run a query within a React component, call `useMediaUpdateDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdateDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUpdateDateRangeQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useMediaUpdateDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  > &
    (
      | { variables: MediaUpdateDateRangeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  >(MediaUpdateDateRangeDocument, options);
}
export function useMediaUpdateDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  >(MediaUpdateDateRangeDocument, options);
}
export function useMediaUpdateDateRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaUpdateDateRangeQuery,
    MediaUpdateDateRangeQueryVariables
  >(MediaUpdateDateRangeDocument, options);
}
export type MediaUpdateDateRangeQueryHookResult = ReturnType<
  typeof useMediaUpdateDateRangeQuery
>;
export type MediaUpdateDateRangeLazyQueryHookResult = ReturnType<
  typeof useMediaUpdateDateRangeLazyQuery
>;
export type MediaUpdateDateRangeSuspenseQueryHookResult = ReturnType<
  typeof useMediaUpdateDateRangeSuspenseQuery
>;
export type MediaUpdateDateRangeQueryResult = Apollo.QueryResult<
  MediaUpdateDateRangeQuery,
  MediaUpdateDateRangeQueryVariables
>;
export const MediaUpdatesDocument = gql`
  query MediaUpdates(
    $hub: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaUpdates(
      hub: $hub
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          postedAt
          title
          includedTypes
          isPinned
          slug
          mediaId
          answeredQuestionCount
          questionCount
          likes
          totalViewCount
          thumbnailAttachment {
            thumbnailUrl
            type
            url
            orderId
            title
            thumbnail
            thumbnailIsPortrait
          }
          attachments {
            thumbnailUrl
            url
            title
            orderId
            type
            thumbnail
            thumbnailIsPortrait
          }
          content {
            content
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options, hub: $hub)
    }
  }
`;

/**
 * __useMediaUpdatesQuery__
 *
 * To run a query within a React component, call `useMediaUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUpdatesQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMediaUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  > &
    (
      | { variables: MediaUpdatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export function useMediaUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export function useMediaUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export type MediaUpdatesQueryHookResult = ReturnType<
  typeof useMediaUpdatesQuery
>;
export type MediaUpdatesLazyQueryHookResult = ReturnType<
  typeof useMediaUpdatesLazyQuery
>;
export type MediaUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useMediaUpdatesSuspenseQuery
>;
export type MediaUpdatesQueryResult = Apollo.QueryResult<
  MediaUpdatesQuery,
  MediaUpdatesQueryVariables
>;
export const PageBySlugDocument = gql`
  query PageBySlug($hub: String!, $slug: String!) {
    pageBySlug(hub: $hub, slug: $slug) {
      id
      title
      slug
      description
      isDraft
      metaDescription
      socialImageCloudinaryPublicId
      blocks {
        id
        type
        position
        content
        insertedAt
        updatedAt
      }
    }
  }
`;

/**
 * __usePageBySlugQuery__
 *
 * To run a query within a React component, call `usePageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageBySlugQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PageBySlugQuery,
    PageBySlugQueryVariables
  > &
    (
      | { variables: PageBySlugQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageBySlugQuery, PageBySlugQueryVariables>(
    PageBySlugDocument,
    options
  );
}
export function usePageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageBySlugQuery,
    PageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageBySlugQuery, PageBySlugQueryVariables>(
    PageBySlugDocument,
    options
  );
}
export function usePageBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PageBySlugQuery,
    PageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PageBySlugQuery, PageBySlugQueryVariables>(
    PageBySlugDocument,
    options
  );
}
export type PageBySlugQueryHookResult = ReturnType<typeof usePageBySlugQuery>;
export type PageBySlugLazyQueryHookResult = ReturnType<
  typeof usePageBySlugLazyQuery
>;
export type PageBySlugSuspenseQueryHookResult = ReturnType<
  typeof usePageBySlugSuspenseQuery
>;
export type PageBySlugQueryResult = Apollo.QueryResult<
  PageBySlugQuery,
  PageBySlugQueryVariables
>;
export const PreparedAnnouncementDocument = gql`
  query PreparedAnnouncement($host: String!, $hashid: String!) {
    preparedAnnouncement(host: $host, hashid: $hashid) {
      mediaAnnouncement {
        id
      }
    }
  }
`;

/**
 * __usePreparedAnnouncementQuery__
 *
 * To run a query within a React component, call `usePreparedAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreparedAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreparedAnnouncementQuery({
 *   variables: {
 *      host: // value for 'host'
 *      hashid: // value for 'hashid'
 *   },
 * });
 */
export function usePreparedAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  > &
    (
      | { variables: PreparedAnnouncementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export function usePreparedAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export function usePreparedAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export type PreparedAnnouncementQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementQuery
>;
export type PreparedAnnouncementLazyQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementLazyQuery
>;
export type PreparedAnnouncementSuspenseQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementSuspenseQuery
>;
export type PreparedAnnouncementQueryResult = Apollo.QueryResult<
  PreparedAnnouncementQuery,
  PreparedAnnouncementQueryVariables
>;
export const RefinitivTokenDocument = gql`
  query RefinitivToken {
    token {
      value
    }
  }
`;

/**
 * __useRefinitivTokenQuery__
 *
 * To run a query within a React component, call `useRefinitivTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefinitivTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefinitivTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefinitivTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RefinitivTokenQuery, RefinitivTokenQueryVariables>(
    RefinitivTokenDocument,
    options
  );
}
export function useRefinitivTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RefinitivTokenQuery, RefinitivTokenQueryVariables>(
    RefinitivTokenDocument,
    options
  );
}
export function useRefinitivTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >(RefinitivTokenDocument, options);
}
export type RefinitivTokenQueryHookResult = ReturnType<
  typeof useRefinitivTokenQuery
>;
export type RefinitivTokenLazyQueryHookResult = ReturnType<
  typeof useRefinitivTokenLazyQuery
>;
export type RefinitivTokenSuspenseQueryHookResult = ReturnType<
  typeof useRefinitivTokenSuspenseQuery
>;
export type RefinitivTokenQueryResult = Apollo.QueryResult<
  RefinitivTokenQuery,
  RefinitivTokenQueryVariables
>;
export const SuggestedUsernameDocument = gql`
  query SuggestedUsername {
    suggestedUsername
  }
`;

/**
 * __useSuggestedUsernameQuery__
 *
 * To run a query within a React component, call `useSuggestedUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedUsernameQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedUsernameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >(SuggestedUsernameDocument, options);
}
export function useSuggestedUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >(SuggestedUsernameDocument, options);
}
export function useSuggestedUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SuggestedUsernameQuery,
    SuggestedUsernameQueryVariables
  >(SuggestedUsernameDocument, options);
}
export type SuggestedUsernameQueryHookResult = ReturnType<
  typeof useSuggestedUsernameQuery
>;
export type SuggestedUsernameLazyQueryHookResult = ReturnType<
  typeof useSuggestedUsernameLazyQuery
>;
export type SuggestedUsernameSuspenseQueryHookResult = ReturnType<
  typeof useSuggestedUsernameSuspenseQuery
>;
export type SuggestedUsernameQueryResult = Apollo.QueryResult<
  SuggestedUsernameQuery,
  SuggestedUsernameQueryVariables
>;
export const TotalFeaturedAnnouncementsDocument = gql`
  query TotalFeaturedAnnouncements($hub: String) {
    totalFeaturedAnnouncements(hub: $hub)
  }
`;

/**
 * __useTotalFeaturedAnnouncementsQuery__
 *
 * To run a query within a React component, call `useTotalFeaturedAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalFeaturedAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalFeaturedAnnouncementsQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useTotalFeaturedAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >(TotalFeaturedAnnouncementsDocument, options);
}
export function useTotalFeaturedAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >(TotalFeaturedAnnouncementsDocument, options);
}
export function useTotalFeaturedAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TotalFeaturedAnnouncementsQuery,
    TotalFeaturedAnnouncementsQueryVariables
  >(TotalFeaturedAnnouncementsDocument, options);
}
export type TotalFeaturedAnnouncementsQueryHookResult = ReturnType<
  typeof useTotalFeaturedAnnouncementsQuery
>;
export type TotalFeaturedAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useTotalFeaturedAnnouncementsLazyQuery
>;
export type TotalFeaturedAnnouncementsSuspenseQueryHookResult = ReturnType<
  typeof useTotalFeaturedAnnouncementsSuspenseQuery
>;
export type TotalFeaturedAnnouncementsQueryResult = Apollo.QueryResult<
  TotalFeaturedAnnouncementsQuery,
  TotalFeaturedAnnouncementsQueryVariables
>;
export const TotalMediaLikesDocument = gql`
  query TotalMediaLikes($mediaId: ID!, $hub: String) {
    totalMediaLikes(mediaId: $mediaId, hub: $hub)
  }
`;

/**
 * __useTotalMediaLikesQuery__
 *
 * To run a query within a React component, call `useTotalMediaLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalMediaLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalMediaLikesQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useTotalMediaLikesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TotalMediaLikesQuery,
    TotalMediaLikesQueryVariables
  > &
    (
      | { variables: TotalMediaLikesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalMediaLikesQuery, TotalMediaLikesQueryVariables>(
    TotalMediaLikesDocument,
    options
  );
}
export function useTotalMediaLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalMediaLikesQuery,
    TotalMediaLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TotalMediaLikesQuery,
    TotalMediaLikesQueryVariables
  >(TotalMediaLikesDocument, options);
}
export function useTotalMediaLikesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TotalMediaLikesQuery,
    TotalMediaLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TotalMediaLikesQuery,
    TotalMediaLikesQueryVariables
  >(TotalMediaLikesDocument, options);
}
export type TotalMediaLikesQueryHookResult = ReturnType<
  typeof useTotalMediaLikesQuery
>;
export type TotalMediaLikesLazyQueryHookResult = ReturnType<
  typeof useTotalMediaLikesLazyQuery
>;
export type TotalMediaLikesSuspenseQueryHookResult = ReturnType<
  typeof useTotalMediaLikesSuspenseQuery
>;
export type TotalMediaLikesQueryResult = Apollo.QueryResult<
  TotalMediaLikesQuery,
  TotalMediaLikesQueryVariables
>;
export const UserCommentsDocument = gql`
  query UserComments($hub: String!, $username: String!) {
    userComments(hub: $hub, username: $username) {
      id
      insertedAt
      updatedAt
      media {
        mediaAnnouncement {
          id
          header
          summary
          socialVideoUrl
          videoUrl
          marketSensitive
        }
        mediaUpdate {
          id
          title
          slug
          includedTypes
        }
      }
      annotationMetadata {
        left
        pageIndex
        top
      }
      commentSource
      commentSourceUrl
      children {
        id
        insertedAt
        updatedAt
        annotationMetadata {
          left
          pageIndex
          top
        }
        commentSource
        commentSourceUrl
        companyAuthor {
          id
          firstName
          lastName
        }
        content
        investorUser {
          id
          showShareholderBadge
          username
        }
        followed
        liked
        likes
        private
        useCompanyAsUsername
      }
      companyAuthor {
        id
        firstName
        lastName
      }
      content
      investorUser {
        id
        showShareholderBadge
        username
      }
      followed
      liked
      likes
      private
      useCompanyAsUsername
      parent {
        id
      }
    }
  }
`;

/**
 * __useUserCommentsQuery__
 *
 * To run a query within a React component, call `useUserCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCommentsQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCommentsQuery,
    UserCommentsQueryVariables
  > &
    (
      | { variables: UserCommentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCommentsQuery, UserCommentsQueryVariables>(
    UserCommentsDocument,
    options
  );
}
export function useUserCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCommentsQuery,
    UserCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCommentsQuery, UserCommentsQueryVariables>(
    UserCommentsDocument,
    options
  );
}
export function useUserCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserCommentsQuery,
    UserCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserCommentsQuery, UserCommentsQueryVariables>(
    UserCommentsDocument,
    options
  );
}
export type UserCommentsQueryHookResult = ReturnType<
  typeof useUserCommentsQuery
>;
export type UserCommentsLazyQueryHookResult = ReturnType<
  typeof useUserCommentsLazyQuery
>;
export type UserCommentsSuspenseQueryHookResult = ReturnType<
  typeof useUserCommentsSuspenseQuery
>;
export type UserCommentsQueryResult = Apollo.QueryResult<
  UserCommentsQuery,
  UserCommentsQueryVariables
>;
export const UserLikesDocument = gql`
  query UserLikes($hub: String!, $username: String!) {
    userLikes(hub: $hub, username: $username) {
      id
      commentLike {
        id
        comment {
          id
          insertedAt
          updatedAt
          annotationMetadata {
            left
            pageIndex
            top
          }
          commentSource
          commentSourceUrl
          media {
            mediaAnnouncement {
              id
              header
              summary
              socialVideoUrl
              videoUrl
              marketSensitive
            }
            mediaUpdate {
              id
              title
              slug
              includedTypes
            }
          }
          companyAuthor {
            id
            firstName
            lastName
          }
          content
          investorUser {
            id
            showShareholderBadge
            username
          }
          liked
          likes
          private
          parent {
            id
          }
        }
      }
      mediaLike {
        id
        media {
          id
          liked
          likesCount
          mediaAnnouncement {
            id
            header
            summary
            videoUrl
            socialVideoUrl
            postedAt
          }
          mediaUpdate {
            id
            slug
            title
            includedTypes
            postedAt
          }
        }
      }
    }
  }
`;

/**
 * __useUserLikesQuery__
 *
 * To run a query within a React component, call `useUserLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLikesQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserLikesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserLikesQuery,
    UserLikesQueryVariables
  > &
    ({ variables: UserLikesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserLikesQuery, UserLikesQueryVariables>(
    UserLikesDocument,
    options
  );
}
export function useUserLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserLikesQuery,
    UserLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserLikesQuery, UserLikesQueryVariables>(
    UserLikesDocument,
    options
  );
}
export function useUserLikesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserLikesQuery,
    UserLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserLikesQuery, UserLikesQueryVariables>(
    UserLikesDocument,
    options
  );
}
export type UserLikesQueryHookResult = ReturnType<typeof useUserLikesQuery>;
export type UserLikesLazyQueryHookResult = ReturnType<
  typeof useUserLikesLazyQuery
>;
export type UserLikesSuspenseQueryHookResult = ReturnType<
  typeof useUserLikesSuspenseQuery
>;
export type UserLikesQueryResult = Apollo.QueryResult<
  UserLikesQuery,
  UserLikesQueryVariables
>;
export const UserProfileFollowDocument = gql`
  query UserProfileFollow($investorUserId: ID!) {
    userProfileFollow(investorUserId: $investorUserId)
  }
`;

/**
 * __useUserProfileFollowQuery__
 *
 * To run a query within a React component, call `useUserProfileFollowQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileFollowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileFollowQuery({
 *   variables: {
 *      investorUserId: // value for 'investorUserId'
 *   },
 * });
 */
export function useUserProfileFollowQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  > &
    (
      | { variables: UserProfileFollowQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  >(UserProfileFollowDocument, options);
}
export function useUserProfileFollowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  >(UserProfileFollowDocument, options);
}
export function useUserProfileFollowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserProfileFollowQuery,
    UserProfileFollowQueryVariables
  >(UserProfileFollowDocument, options);
}
export type UserProfileFollowQueryHookResult = ReturnType<
  typeof useUserProfileFollowQuery
>;
export type UserProfileFollowLazyQueryHookResult = ReturnType<
  typeof useUserProfileFollowLazyQuery
>;
export type UserProfileFollowSuspenseQueryHookResult = ReturnType<
  typeof useUserProfileFollowSuspenseQuery
>;
export type UserProfileFollowQueryResult = Apollo.QueryResult<
  UserProfileFollowQuery,
  UserProfileFollowQueryVariables
>;
export const UserProfileStatsDocument = gql`
  query UserProfileStats($hub: String!, $username: String!) {
    userProfileStats(hub: $hub, username: $username) {
      id
      replies
      showShareholderBadge
      joined
      likes
      questionsAsked
      surveyResponses
      username
    }
  }
`;

/**
 * __useUserProfileStatsQuery__
 *
 * To run a query within a React component, call `useUserProfileStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileStatsQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserProfileStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProfileStatsQuery,
    UserProfileStatsQueryVariables
  > &
    (
      | { variables: UserProfileStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProfileStatsQuery, UserProfileStatsQueryVariables>(
    UserProfileStatsDocument,
    options
  );
}
export function useUserProfileStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileStatsQuery,
    UserProfileStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileStatsQuery,
    UserProfileStatsQueryVariables
  >(UserProfileStatsDocument, options);
}
export function useUserProfileStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserProfileStatsQuery,
    UserProfileStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserProfileStatsQuery,
    UserProfileStatsQueryVariables
  >(UserProfileStatsDocument, options);
}
export type UserProfileStatsQueryHookResult = ReturnType<
  typeof useUserProfileStatsQuery
>;
export type UserProfileStatsLazyQueryHookResult = ReturnType<
  typeof useUserProfileStatsLazyQuery
>;
export type UserProfileStatsSuspenseQueryHookResult = ReturnType<
  typeof useUserProfileStatsSuspenseQuery
>;
export type UserProfileStatsQueryResult = Apollo.QueryResult<
  UserProfileStatsQuery,
  UserProfileStatsQueryVariables
>;
export const UserSurveysDocument = gql`
  query UserSurveys($hub: String!, $username: String!) {
    userSurveys(hub: $hub, username: $username) {
      mediaId
      media {
        id
        liked
        likesCount
        mediaAnnouncement {
          id
          header
          summary
          videoUrl
          socialVideoUrl
          postedAt
        }
        mediaUpdate {
          id
          slug
          title
          includedTypes
          postedAt
        }
      }
      userResponses {
        answer
        question
        totalRespondents
      }
    }
  }
`;

/**
 * __useUserSurveysQuery__
 *
 * To run a query within a React component, call `useUserSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSurveysQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserSurveysQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSurveysQuery,
    UserSurveysQueryVariables
  > &
    (
      | { variables: UserSurveysQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSurveysQuery, UserSurveysQueryVariables>(
    UserSurveysDocument,
    options
  );
}
export function useUserSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSurveysQuery,
    UserSurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSurveysQuery, UserSurveysQueryVariables>(
    UserSurveysDocument,
    options
  );
}
export function useUserSurveysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserSurveysQuery,
    UserSurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserSurveysQuery, UserSurveysQueryVariables>(
    UserSurveysDocument,
    options
  );
}
export type UserSurveysQueryHookResult = ReturnType<typeof useUserSurveysQuery>;
export type UserSurveysLazyQueryHookResult = ReturnType<
  typeof useUserSurveysLazyQuery
>;
export type UserSurveysSuspenseQueryHookResult = ReturnType<
  typeof useUserSurveysSuspenseQuery
>;
export type UserSurveysQueryResult = Apollo.QueryResult<
  UserSurveysQuery,
  UserSurveysQueryVariables
>;
export const UtmLinkFetchByHashDocument = gql`
  query UtmLinkFetchByHash($hash: String!) {
    utmLinkFetchByHash(hash: $hash) {
      utmUrl
    }
  }
`;

/**
 * __useUtmLinkFetchByHashQuery__
 *
 * To run a query within a React component, call `useUtmLinkFetchByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmLinkFetchByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmLinkFetchByHashQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useUtmLinkFetchByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  > &
    (
      | { variables: UtmLinkFetchByHashQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  >(UtmLinkFetchByHashDocument, options);
}
export function useUtmLinkFetchByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  >(UtmLinkFetchByHashDocument, options);
}
export function useUtmLinkFetchByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmLinkFetchByHashQuery,
    UtmLinkFetchByHashQueryVariables
  >(UtmLinkFetchByHashDocument, options);
}
export type UtmLinkFetchByHashQueryHookResult = ReturnType<
  typeof useUtmLinkFetchByHashQuery
>;
export type UtmLinkFetchByHashLazyQueryHookResult = ReturnType<
  typeof useUtmLinkFetchByHashLazyQuery
>;
export type UtmLinkFetchByHashSuspenseQueryHookResult = ReturnType<
  typeof useUtmLinkFetchByHashSuspenseQuery
>;
export type UtmLinkFetchByHashQueryResult = Apollo.QueryResult<
  UtmLinkFetchByHashQuery,
  UtmLinkFetchByHashQueryVariables
>;
export const WebinarsDocument = gql`
  query Webinars(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $options: OptionsInput
  ) {
    webinars(
      first: $first
      last: $last
      after: $after
      before: $before
      options: $options
    ) {
      edges {
        node {
          id
          hmsRoomId
          title
          slug
          summary
          startTime
          endTime
          startedBroadcastingAt
          stoppedBroadcastingAt
          timezone
          imageCloudinaryId
          imageOverlayEnabled
          posterImageUrl
          publishedRecordingUrl
          mediaUpdate {
            id
            title
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useWebinarsQuery__
 *
 * To run a query within a React component, call `useWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebinarsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useWebinarsQuery(
  baseOptions?: Apollo.QueryHookOptions<WebinarsQuery, WebinarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebinarsQuery, WebinarsQueryVariables>(
    WebinarsDocument,
    options
  );
}
export function useWebinarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebinarsQuery,
    WebinarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebinarsQuery, WebinarsQueryVariables>(
    WebinarsDocument,
    options
  );
}
export function useWebinarsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WebinarsQuery,
    WebinarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WebinarsQuery, WebinarsQueryVariables>(
    WebinarsDocument,
    options
  );
}
export type WebinarsQueryHookResult = ReturnType<typeof useWebinarsQuery>;
export type WebinarsLazyQueryHookResult = ReturnType<
  typeof useWebinarsLazyQuery
>;
export type WebinarsSuspenseQueryHookResult = ReturnType<
  typeof useWebinarsSuspenseQuery
>;
export type WebinarsQueryResult = Apollo.QueryResult<
  WebinarsQuery,
  WebinarsQueryVariables
>;
