'use client';

import React, { useMemo, useCallback } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { type Block } from '@blocknote/core';

import { CheckIcon } from '@heroicons/react/solid';
import 'next-cloudinary/dist/cld-video-player.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import {
  GetWebinarDataQuery,
  useRegisterForWebinarMutation,
  CurrentInvestorUserQuery,
} from '../../../apollo/generated';
import {
  BlockHeading,
  HubButton,
  Typography,
  Badge,
  RenderBlockNote,
  WebinarDocuments,
} from '../../../index';
import { useAlert } from '../../context/alert-context';
import { setReturnToCookie } from '../utils';
import { LiveWebinar } from './live-webinar';
import { WebinarBackgroundContainer } from './webinar-background-container';
import { WebinarQuestions } from './webinar-questions';
import { WebinarRecordingPlayer } from './webinar-recording-player';
import {
  getTimeRemaining,
  useTimeRemaining,
  formatDuration,
  formatTimeRemaining,
  getLocalDate,
  getWebinarBroadcastDuration,
} from './webinar-util';

dayjs.extend(utc);
dayjs.extend(timezone);

interface WebinarViewerProps {
  attendee?: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
  > | null;
  client?: ApolloClient<NormalizedCacheObject>;
  currentUser?: CurrentInvestorUserQuery['currentInvestorUser'];
  name?: string;
  roomCodeOverride?: string | undefined;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}

const formatWebinarTime = (
  date: string | null | undefined,
  timezone: string | null | undefined,
  format: string
) => {
  return getLocalDate(date, timezone).format(format);
};

const getWebinarDuration = (
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  timezone: string | null | undefined
) => {
  const start = getLocalDate(startTime, timezone);
  const end = getLocalDate(endTime, timezone);
  return {
    hours: end.diff(start, 'hour'),
    minutes: end.diff(start, 'minute') % 60,
  };
};

export const WebinarViewer: React.FC<WebinarViewerProps> = ({
  attendee,
  client,
  currentUser,
  roomCodeOverride,
  webinar,
}) => {
  const isLoggedIn = !!currentUser;
  const [cookies] = useCookies(['_hermes_web_visitor']);

  // A room code means it's a URL shared to a speaker or moderator.
  // People with this link aren't expected to login to enter the webinar room.
  const canEnterRoomBecauseOfRoomcode =
    roomCodeOverride &&
    (webinar.state === 'scheduled' ||
      webinar.state === 'draft' ||
      webinar.state === 'live');

  const canAttendWebinar =
    webinar.type !== 'public' ||
    (webinar.attendanceNeedsLogin ? isLoggedIn : true);

  const webinarIsLive =
    (webinar.state === 'live' &&
      webinar.type !== 'recording_only' &&
      (isLoggedIn ? !!attendee : canAttendWebinar)) ||
    canEnterRoomBecauseOfRoomcode;

  const recordingIsAvailable =
    webinar.state === 'completed' && webinar.publishedRecordingUrl;

  const canWatchVideo = webinar.recordingNeedsLogin ? isLoggedIn : true;

  const timeRemaining = useTimeRemaining(
    webinar.startTime || '',
    webinar.timezone
  );

  const [registerMutation] = useRegisterForWebinarMutation({
    refetchQueries: ['GetWebinarData'],
  });

  const { formatAndShowError, showAlert } = useAlert();

  const handleRegister = useCallback(async () => {
    try {
      await registerMutation({ variables: { webinarId: webinar.id } });
      showAlert({
        description: 'Successfully registered for the webinar',
        variant: 'success',
      });
    } catch (error) {
      formatAndShowError(error);
    }
  }, [registerMutation, webinar.id, showAlert, formatAndShowError]);

  const buildUrl = useMemo(() => {
    const baseUrl = `https://${
      process.env.NEXT_PUBLIC_HMS_SUBDOMAIN
    }.app.100ms.live/meeting/${roomCodeOverride || webinar.hmsViewerRoomCode}`;
    const params = new URLSearchParams();

    if (attendee && currentUser) {
      params.append('userId', attendee.id);
      params.append(
        'name',
        currentUser?.firstName + ' ' + currentUser?.lastName
      );
    } else if (cookies._hermes_web_visitor) {
      params.append('userId', cookies._hermes_web_visitor);
    }

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }, [
    attendee,
    roomCodeOverride,
    webinar.hmsViewerRoomCode,
    currentUser,
    cookies._hermes_web_visitor,
  ]);

  const formattedTimes = useMemo(() => {
    const startTime = getLocalDate(
      webinar.startedBroadcastingAt || webinar.startTime,
      webinar.timezone
    ).local();
    const endTime = getLocalDate(
      webinar.stoppedBroadcastingAt || webinar.endTime,
      webinar.timezone
    ).local();
    const { hours: durationHours, minutes: durationMinutes } =
      getWebinarDuration(
        webinar.startedBroadcastingAt || webinar.startTime,
        webinar.stoppedBroadcastingAt || webinar.endTime,
        webinar.timezone
      );

    return {
      durationHours,
      durationMinutes,
      endTime,
      startTime,
    };
  }, [
    webinar.startTime,
    webinar.endTime,
    webinar.timezone,
    webinar.startedBroadcastingAt,
    webinar.stoppedBroadcastingAt,
  ]);

  const renderBanner = () => {
    if (webinarIsLive) {
      return (
        <LiveWebinar
          buildUrl={buildUrl}
          duration={getWebinarBroadcastDuration(
            new Date(),
            formattedTimes.startTime.toDate()
          )}
          isLive={webinar.state === 'live'}
          isSpeakerLink={!!roomCodeOverride}
          shouldShowStatus={webinar.type !== 'recording_only'}
          title={webinar.title || 'Webinar'}
        />
      );
    }

    if (recordingIsAvailable && canWatchVideo) {
      return (
        <WebinarRecordingPlayer
          attendeeId={attendee?.id}
          posterImageUrl={webinar.posterImageUrl}
          publishedRecordingUrl={webinar.publishedRecordingUrl || ''}
          visitorCookieId={cookies._hermes_web_visitor}
          webinarId={webinar.id}
        />
      );
    }

    return (
      <WebinarPlaceholderPanel
        attendee={attendee}
        handleRegister={handleRegister}
        isLoggedIn={isLoggedIn}
        timeRemaining={timeRemaining}
        webinar={webinar}
      />
    );
  };

  return (
    <div className="flex flex-col">
      {renderBanner()}
      <div className="mx-auto w-full max-w-screen-xl px-4 py-8 sm:px-6">
        <div className="mb-4 mt-2 flex items-center gap-2 text-gray-500">
          {webinar.state === 'live' && (
            <Badge state="positive" title="Live" variant="primary" />
          )}
          {webinar.state === 'completed' && (
            <Badge state="neutral" title="Ended" variant="secondary" />
          )}
          {webinar.state !== 'completed' && (
            <>
              <Typography className="flex items-center" variant="body-small">
                {formattedTimes.startTime.format('DD/MM/YYYY')}
              </Typography>
              <Typography className="flex items-center" variant="body-small">
                {formattedTimes.startTime.format('h:mma')} -{' '}
                {formattedTimes.endTime.format('h:mma')}{' '}
                {formatDuration(
                  formattedTimes.durationHours,
                  formattedTimes.durationMinutes
                )}
              </Typography>
            </>
          )}
        </div>
        <BlockHeading>{webinar.title}</BlockHeading>
        {!!webinar.transcriptSummary && webinar.showTranscriptSummaryOnHub ? (
          <RenderBlockNote
            fullWidth
            content={
              (webinar.transcriptSummary as { content: Block[] }).content
            }
          />
        ) : (
          !!webinar.summary && (
            <RenderBlockNote
              fullWidth
              content={(webinar.summary as { content: Block[] }).content}
            />
          )
        )}
        <WebinarDocuments canDownload documents={webinar.documents} />
      </div>
      {webinar.allowPreWebinarComments ? (
        <WebinarQuestions
          client={client}
          hasStarted={['live', 'completed'].includes(webinar.state || '')}
          isLoggedIn={isLoggedIn}
          questions={attendee?.questions}
          webinar={webinar}
        />
      ) : null}
    </div>
  );
};

const getWebinarStatusText = (
  webinar: WebinarViewerProps['webinar']
): string => {
  if (webinar.type === 'recording_only') {
    return webinar.publishedRecordingUrl
      ? 'Recording available'
      : webinar.allowPreWebinarComments
      ? 'Submit your questions below'
      : 'Coming soon';
  }
  if (webinar.state === 'live') {
    return 'Webinar is live now';
  }

  const now = getLocalDate(dayjs().toISOString(), webinar.timezone);
  const startTime = getLocalDate(webinar.startTime, webinar.timezone);

  return now.isAfter(startTime)
    ? 'Webinar will start soon'
    : 'Webinar starting in';
};

const WebinarPlaceholderPanel: React.FC<{
  attendee?: WebinarViewerProps['attendee'];
  handleRegister: () => Promise<void>;
  isLoggedIn: boolean;
  timeRemaining: ReturnType<typeof getTimeRemaining>;
  webinar: WebinarViewerProps['webinar'];
}> = ({ attendee, handleRegister, isLoggedIn, timeRemaining, webinar }) => {
  const router = useRouter();

  if (webinar.state === 'completed' && webinar.type !== 'recording_only') {
    const finishedTime = getLocalDate(
      webinar.stoppedBroadcastingAt || webinar.endTime,
      webinar.timezone
    );

    return (
      <WebinarBackgroundContainer webinar={webinar}>
        <div className="z-10 flex flex-col items-center justify-center rounded-lg bg-company-primary px-16 py-10">
          <Typography
            className="text-company-primary-text"
            variant="display-medium"
          >
            {webinar.publishedRecordingUrl
              ? 'Watch recording'
              : 'Webinar finished'}
          </Typography>
          {webinar.publishedRecordingUrl &&
          webinar.recordingNeedsLogin &&
          !isLoggedIn ? (
            <>
              <Typography
                className="mt-4 text-company-primary-text"
                variant="body-large"
              >
                To view the recording of this webinar please either log in or
                sign up.
              </Typography>
              <div className="mt-10 flex space-x-4">
                <HubButton
                  isAccent
                  label="Log in"
                  url="/auth/signin"
                  onClick={(e) => {
                    e.preventDefault();
                    setReturnToCookie(router.asPath);
                    router.push('/auth/signin');
                  }}
                />
                <HubButton
                  isAccent
                  label="Sign up"
                  url="/auth/signup"
                  onClick={(e) => {
                    e.preventDefault();
                    setReturnToCookie(router.asPath);
                    router.push('/auth/signup');
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <Typography className="mt-4" variant="body-large">
                This webinar ended {finishedTime.fromNow()}.
              </Typography>
              <Typography className="mt-2" variant="body-large">
                Recording coming soon
              </Typography>
            </>
          )}
        </div>
      </WebinarBackgroundContainer>
    );
  }

  const remainingTime =
    webinar.state === 'live'
      ? getLocalDate(webinar.endTime, webinar.timezone).diff(
          getLocalDate(dayjs().toISOString(), webinar.timezone),
          'second'
        )
      : timeRemaining.total / 1000;

  return (
    <WebinarBackgroundContainer webinar={webinar}>
      <div className="z-10 flex flex-col items-center justify-center space-y-16 rounded-none bg-company-primary px-20 py-10 sm:rounded-lg">
        <div className="space-y-8">
          <Typography
            className="whitespace-normal text-center font-normal"
            variant="display-medium"
          >
            {webinar.title}
          </Typography>
          <div className="flex flex-col items-center justify-center">
            {webinar.type === 'recording_only' && (
              <div className="mt-4 flex flex-col items-center gap-2 pb-4 sm:flex-row">
                <Typography
                  className="flex items-center font-normal"
                  variant="display-small"
                >
                  {formatWebinarTime(
                    webinar.startTime,
                    webinar.timezone,
                    'DD/MM/YYYY'
                  )}
                </Typography>
                <Typography
                  className="flex items-center font-normal"
                  variant="display-small"
                >
                  {formatWebinarTime(
                    webinar.startTime,
                    webinar.timezone,
                    'h:mma'
                  )}{' '}
                  -{' '}
                  {formatWebinarTime(
                    webinar.endTime,
                    webinar.timezone,
                    'h:mma'
                  )}{' '}
                  {formatDuration(
                    getWebinarDuration(
                      webinar.startTime,
                      webinar.endTime,
                      webinar.timezone
                    ).hours,
                    getWebinarDuration(
                      webinar.startTime,
                      webinar.endTime,
                      webinar.timezone
                    ).minutes
                  )}
                </Typography>
              </div>
            )}
            <Typography className="font-normal" variant="display-small">
              {getWebinarStatusText(webinar)}
            </Typography>

            {webinar.type !== 'recording_only' && (
              <>
                <Typography className="font-normal" variant="body-large">
                  {(webinar.state === 'scheduled' ||
                    webinar.state === 'draft') &&
                    `${formatTimeRemaining(remainingTime, 'Hold tight!')}`}
                  {webinar.state === 'live' &&
                    `${formatTimeRemaining(remainingTime)} remaining`}
                </Typography>
                <div className="mt-4 flex flex-col items-center gap-2 sm:flex-row">
                  <Typography
                    className="flex items-center"
                    variant="body-regular"
                  >
                    {formatWebinarTime(
                      webinar.startTime,
                      webinar.timezone,
                      'DD/MM/YYYY'
                    )}
                  </Typography>
                  <Typography
                    className="flex items-center"
                    variant="body-regular"
                  >
                    {formatWebinarTime(
                      webinar.startTime,
                      webinar.timezone,
                      'h:mma'
                    )}{' '}
                    -{' '}
                    {formatWebinarTime(
                      webinar.endTime,
                      webinar.timezone,
                      'h:mma'
                    )}{' '}
                    {formatDuration(
                      getWebinarDuration(
                        webinar.startTime,
                        webinar.endTime,
                        webinar.timezone
                      ).hours,
                      getWebinarDuration(
                        webinar.startTime,
                        webinar.endTime,
                        webinar.timezone
                      ).minutes
                    )}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>

        {isLoggedIn ? (
          webinar.type === 'recording_only' ? (
            webinar.publishedRecordingUrl ? (
              !attendee?.viewedRecordingAt && (
                <HubButton
                  isAccent
                  label="Watch recording"
                  onClick={handleRegister}
                />
              )
            ) : (
              <Typography className="mt-4" variant="body-large">
                This webinar will have no live attendees.{' '}
                {webinar.allowPreWebinarComments
                  ? "Submit any questions you'd like answered in the recording below."
                  : ''}
              </Typography>
            )
          ) : attendee ? (
            <div className="flex flex-col items-center gap-3">
              <div className="mb-4 flex items-center">
                <div className="mr-2 rounded-full bg-white p-1">
                  <CheckIcon className="h-5 w-5 text-company-primary" />
                </div>
                <Typography variant="body-large">
                  You are registered to attend
                </Typography>
              </div>
              <AddToCalendarButton
                description={`Join the webinar at: ${window.location.origin}${router.asPath}`}
                endDate={formatWebinarTime(
                  webinar.endTime,
                  webinar.timezone,
                  'YYYY-MM-DD'
                )}
                endTime={formatWebinarTime(
                  webinar.endTime,
                  webinar.timezone,
                  'HH:mm'
                )}
                location="Online"
                name={webinar.title || 'Webinar'}
                options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                startDate={formatWebinarTime(
                  webinar.startTime,
                  webinar.timezone,
                  'YYYY-MM-DD'
                )}
                startTime={formatWebinarTime(
                  webinar.startTime,
                  webinar.timezone,
                  'HH:mm'
                )}
                styleDark="--font: inherit;"
                styleLight=" --font: inherit;"
                timeZone={webinar.timezone || 'UTC'}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <HubButton
                isAccent
                label={
                  webinar.state === 'live'
                    ? 'Join webinar'
                    : 'Register to attend'
                }
                onClick={handleRegister}
              />
            </div>
          )
        ) : (webinar.type === 'recording_only' &&
            !webinar.recordingNeedsLogin) ||
          !webinar.attendanceNeedsLogin ? null : (
          <div className="flex flex-col items-center gap-5">
            <Typography className="text-center" variant="body-large">
              {`Log in or sign up to ${
                webinar.state === 'scheduled'
                  ? 'register for'
                  : webinar.state === 'live'
                  ? 'join'
                  : 'view'
              } this webinar`}
            </Typography>
            <div className="flex space-x-4">
              <HubButton
                isAccent
                label="Log in"
                url="/auth/signin"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signin');
                }}
              />
              <HubButton
                isAccent
                label="Sign up"
                url="/auth/signup"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signup');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </WebinarBackgroundContainer>
  );
};
